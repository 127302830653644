<template>
  <div>
    <b-modal
      id="attendance-review-modal"
      hide-header
      hide-footer
      centered
      no-stacking
      @show="onShow"
    >
        <div class="header d-flex flex-row justify-content-between">
          <p class="title">Impressão e assinatura digital</p>
          <Close
            class="close"
            @click="$bvModal.hide('attendance-review-modal')"
          />
        </div>
        <div class="body">
          <div v-if="canSave">
            <h6 class="title title1">
              O atendimento é presencial ou online?
            </h6>
            <div class="form-check-inline form-check-inline1">
              <label class="form-check-label" @click="attendanceType = 1">
                <input
                  style="
                    background: #ffffff;
                    border: 1px solid #c6ceeb;
                    box-sizing: border-box;
                    border-radius: 100px;
                  "
                  type="radio"
                  class="form-check-input"
                  name="type_attendance"
                  :checked="attendanceType === 1"
                />Presencial
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label" @click="attendanceType = 2">
                <input
                  style="
                    background: #ffffff;
                    border: 1px solid #c6ceeb;
                    box-sizing: border-box;
                    border-radius: 100px;
                  "
                  type="radio"
                  class="form-check-input"
                  name="type_attendance"
                /><span
                  style="
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    align-items: center;
                    color: #525c7a;
                  "
                  >Online</span
                >
              </label>
            </div>
            <div v-if="attendanceType > 0">
              <hr />

              <p class="text" style="margin-top: 15px; margin-bottom: 15px">
                Selecione na lista abaixo quais documentos deseja imprimir e quais
                deseja <strong>assinar digitalmente</strong> para compartilhar com
                seu paciente.
              </p>
              <table class="attendance-review-table" style="width: -webkit-fill-available">
                <tr>
                  <th
                    style="
                      font-family: Nunito Sans;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 150%;
                      color: #525c7a;
                    "
                  >
                    Documentos
                  </th>

                  <th
                    style="
                      font-family: Nunito Sans;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 150%;
                      color: #525c7a;
                    "
                    v-if="attendanceType == 1"
                  >
                    Imprimir
                  </th>

                  <th
                    style="
                      font-family: Nunito Sans;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 150%;
                      color: #525c7a;
                    "
                  >
                    Assinar
                  </th>
                </tr>

                <v-attendance-review
                  v-for="(review, index) in reviews"
                  :key="index"
                  :label="review.label"
                  :identifier="review.identifier"
                  :attendanceType="attendanceType"
                  :attendanceReview="attendanceReview"
                  :attendanceReviewCopy="attendanceReviewCopy"
                  :attendanceSignatureItem="attendanceSignatureItem"
                  :attendanceSignatureItemCopy="attendanceSignatureItemCopy"
                />
              </table>
            </div>
          </div>

          <div v-else>
            <p class="message-box warning">
              <InfoCircle class="icon" />
              <span>
                Alguns campos precisam ser <b>preenchidos</b> da sua consulta.
              </span>
            </p>

            <ul class="required-fields">  <!--Campos considerados obrigatórios "CID" e "Retorno" -->
              <li v-if="getForm('Diagnóstico', 'cid') && getForm('Diagnóstico', 'cid').value.length === 0">CID</li>
            </ul>
          </div>

          <b-row v-if="attendanceType > 0" style="margin-top: 25px">
            <b-col class="text-right">
              <label
                for="save-model"
                class="checkbox mb-5"
                v-if="isDifferent"
              >
                <input type="checkbox" id="save-model" v-model="saveCustomForm" />

                <span class="checkmark">
                  <Check />
                </span>

                Salvar modelo personalizado
              </label>

              <b-button
                variant="link"
                @click="$bvModal.hide('attendance-review-modal')"
                >Continuar editando</b-button
              >

              <b-button
                variant="primary"
                :disabled="!canSave"
                @click="saveAttendance"
                >{{
                  checkAttendanceReviewPrint(true) === 0
                    ? 'Salvar'
                    : attendanceType == 1
                    ? 'Salvar e Imprimir'
                    : 'Salvar e assinar'
                }}</b-button
              >
            </b-col>
          </b-row>
        </div>
    </b-modal>
    <v-files-prepare-modal
      :attendanceId="attendanceId"
      :toPrint="toPrint"
    />
  </div>
</template>

<script>
import InfoCircle from '@/assets/icons/info-circle.svg'
import Check from '@/assets/icons/check.svg'
import Close from '@/assets/icons/close.svg'
import FilesPrepareModal from './FilesPrepareModal'
import { init, preAuthorizeSignatures, readCertificate } from '@/utils/lacuna/lacunaHelper'
import { getToPrint, getToSign } from '@/utils/documentHelper'
import { isEqual, cloneDeep} from 'lodash'
import AttendanceReview from '../../components/Appointment/AttendanceReview.vue'

export default {
  name: 'attendance-review-modal',
  components: {
    'v-files-prepare-modal': FilesPrepareModal,
    'v-attendance-review': AttendanceReview,
    InfoCircle,
    Check,
    Close,
  },
  props: {
    formGroups: Object,
    canSave: Boolean,
    getForm: Function,
    selectedForm: String,
    attendanceReview: Object,
    attendanceReviewCopy: Object,
    attendanceSignatureItem: Object,
    attendanceSignatureItemCopy: Object,
    checkAttendanceReviewPrint: Function,
    medicines: Object,
    medicinesAntimicrobianos: Object,
    patientData: Object,
    professionalData: Object,
    professionalCrm: Array,
    clinicsData: Object,
  },
  mounted() {
    this.clinic = JSON.parse(localStorage.getItem('clinic'));
    this.patientId = this.$route.params.id;
    this.attendanceId = this.$route.params.idConsulta;
    this.startLocalCertificate();
  },
  data () {
    return {
      reviews: [
        {
          label: 'Solicitação de exames',
          identifier: 'solicitacaoDeExames'
        },
        {
          label: 'Solicitação de cirurgia',
          identifier: 'solicitacaoDeCirurgia'
        },
        {
          label: 'Solicitação de APA (Avaliação Pré Anestésica)',
          identifier: 'solicitacaoDeAPA'
        },
        {
          label: 'Encaminhamento',
          identifier: 'encaminhamento'
        },
        {
          label: 'Prescrição Medicamentosa',
          identifier: 'prescricaoMedicamentosa'
        },
        {
          label: 'Prescrição do óculos',
          identifier: 'prescricaoDeOculos'
        },
        {
          label: 'Prescrição de lentes de contato',
          identifier: 'prescricaoDeLentes'
        },
        {
          label: 'Orientação ao paciente',
          identifier: 'posOperatorio'
        },
        {
          label: 'Declaração de comparecimento',
          identifier: 'declaracaoDeComparecimento'
        },
        {
          label: 'Declaração de comparecimento (acompanhante)',
          identifier: 'declaracaoDeComparecimentoAcompanhante'
        },
        {
          label: 'Atestado',
          identifier: 'atestado'
        },
        {
          label: 'Laudo médico',
          identifier: 'laudo'
        },
        {
          label: 'Mapeamento de retina/fundoscopia',
          identifier: 'mapeamentoRetina'
        }
      ],
      clinic: null,
      patientId: null,
      attendanceId: null,
      attendanceType: 1,
      toPrint: [],
      toSign:[],
      // toPrintDiff: [],
      saveCustomForm: false,
      isDifferent: null,
      newFormGroup: [],
    }
  },
  methods: {
    async onShow() {
      await this.isDifferentForms()

    },
    async isDifferentForms() {
      if (this.selectedForm === 'completa') {
        this.isDifferent = false
        return;
      }

      const response = await this.api.getForm(this.selectedForm)
      const newFormGroup = cloneDeep(this.formGroups, {}, true)
      Object.keys(newFormGroup).forEach((key, index) => {
        Object.values(newFormGroup[key].forms).forEach(f => {
          delete f.value
          delete f.fields
          delete f.editingIndex
          f.active = false
          this.newFormGroup.push(f)
        })
      })
      this.isDifferent = !isEqual(response.data.forms, this.newFormGroup)
    },
    startLocalCertificate() {
      var auxCert = JSON.parse(localStorage.getItem('singItemAutentic'))
      if (!auxCert) {
        auxCert = { active: 'inative' }
        this.certAuth = auxCert;
        return;
      }
      this.certAuth = auxCert
    },
    async saveAttendance() {
      try {
        if (this.saveCustomForm) {
          await this.api.updateForm(this.selectedForm, {
            forms: this.newFormGroup
          });
        }
      } catch(err) {
        console.log('Err saveCustomForm => ', err.message)
      }

      this.toPrint = getToPrint(this.attendanceReviewCopy, this.medicines, this.medicinesAntimicrobianos);
      this.toSign = getToSign(this.attendanceSignatureItemCopy, this.medicines, this.medicinesAntimicrobianos);

      const data = {
        attendanceId: this.attendanceId,
        toPrint: this.toPrint,
        toSign: this.toSign,
        quickPrint: false,
        certContent: null,
        service: null,
      }
      const loading = this.$loading.show();
      try {
        if(this.certAuth.active && this.certAuth.type === 1){
          await preAuthorizeSignatures(this.certAuth.cert.thumbprint, data.toSign.length);
          data.certContent = await readCertificate(this.certAuth.cert.thumbprint);
        } else if (this.certAuth.active && this.certAuth.type === 2) {
          data.service = this.certAuth.cert.name;
        }

        const res = await this.api.finishAttendance(data);

        if (res.data.authentication === 'oauth') {
        } else if (res.data.authentication === 'rest') {
          for (const signature of res.data.signatures) {
            await init(signature.token, this.certAuth.cert.thumbprint);
          }
          await this.api.restFinishAttendance(data.attendanceId);
        }

        await this.api.simpleFinishAttendance(data.attendanceId);
        loading.hide();
        this.$bvModal.hide('attendance-review-modal');
        this.$emit('onFinished');
        this.$toast.success('Consulta finalizada com sucesso')
      } catch (err) {
        this.$toast.error(err.message);
        loading.hide();
      }
    },
    onWebPkiError(message, error, origin) {
      console.log('Lacuna error ', message);
    },
  }
}
</script>

<style lang="scss">
#attendance-review-modal {
  font-family: 'Nunito Sans';

  .modal-body {
    //max-width: 532px;
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-weight: 600;
        font-size: 18px;
        color: var(--type-active);
        margin: 0;
      }

      .title1 {
        color: #0c1d59;
        font-family: Red Hat Display;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 134%;
      }

      .form-check-inline1 {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: #525c7a;
      }

      .close {
        width: 24px;
        height: 24px;
        fill: black;
        cursor: pointer;
      }
    }

    .body {
      padding: 24px;

      .attendance-review-table {

        tr {
          border-bottom: 1px solid #D9DFF2;
        }
      }

      .message-box {
        background-color: #e6f9ff;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0 0 24px 0;
        align-items: center;
        color: var(--type-active);

        b {
          font-weight: 700;
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: #0088b3;
        }

        &.warning {
          justify-content: start;
          background-color: #fff0e6;

          .icon {
            stroke: #b34b00;
          }
        }
      }

      .required-fields {
        list-style-type: none;
        margin: 0 0 24px 0;
        padding: 0;

        li {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: var(--type-active);
          padding: 8px 0;
          border-bottom: 1px solid var(--neutral-300);
        }
      }

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 8px;
      }

      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 32px;
      }

      .options {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
      }
      .checkbox {
        margin-top: -12px;
        display: initial !important;

        .disabled {
          background-color: #D9DFF2;
          border: none;
        }
      }
      .checkbox.bold {
        font-weight: 700;
        margin-bottom: 16px;
      }
      .tdLabel {
        font-size: 16px;
        color: var(--type-active);
        font-weight: 400;
        /* display: block; */
        padding: 10px 0px;
      }
    }
  }

  .tooltip-inner {
    width: 256px;
  }
}


</style>



