<template>
  <div class="ds-container">

    <div class="card">
      <div class="card-subtitle">
        Orçamentos
        <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Crie e Gerencie Propostas de Orçamento.
        </b-tooltip>
      </div>

      <div class="card-body px-0">
        <div class="row">
          <div class="col-4">
            <SearchPatient v-model="filter.patient" />
          </div>
          <div class="col-2">
            <b-button class="mr-3 filter__container d-flex d-inline" @click="openAdvancedSearchModal">
              <Icon tooltip="Aplique filtros avançados para Pesquisar por orçamentos">
                <FilterBar />
              </Icon>
              <span class="text-filter ml-1">
                Filtro
                <b-badge v-show="advancedFilterActivated" id="filter-badge" pill variant="danger" class="text-danger advanced-filter-activated">.</b-badge>
              </span>
              <b-tooltip target="filter-badge" placement="right" variant="success">
                O Filtro avançado está ativo
              </b-tooltip>
            </b-button>
          </div>
          <div class="col-6 d-flex justify-content-end pl-2">
            <b-button variant="outline-primary" @click="downloadBudgetFile">
              Baixar Orçamento
            </b-button>
            <b-button variant="primary" class="ml-2" @click="create">
              Novo Orçamento
            </b-button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b-table-simple class="p-table">
              <thead>
              <tr>
                <td class="td-header">
                  <b-form-checkbox
                    size="lg"
                    @change="toggleSelected"
                  />
                </td>
                <td class="td-header">Criado Em</td>
                <td class="td-header">Válido até</td>
                <td class="td-header">Paciente</td>
                <td class="td-header">Solicitação</td>
                <td class="td-header">Situação</td>
                <td class="td-header">Médico Solic.</td>
                <td class="td-header">Responsável</td>
                <td class="td-header">Valor Total</td>
                <td class="td-header">Orçamento</td>
                <td class="td-header"></td>
              </tr>
              </thead>

              <tbody v-if="budgets">
              <tr v-for="budget in budgets" :key="budget.id">
                <td class="td-body">
                  <b-form-checkbox
                    v-model="selectedBudgets"
                    :value="budget.id"
                    size="lg"
                  />
                </td>
                <td class="td-body td-simple">
                  {{ budget.created_at | formatExpiresAt }}
                </td>
                <td class="td-body td-simple">
                  {{ budget.expires_at | formatExpiresAt }}
                </td>

                <td class="td-body td-simple">
                  <div class="d-flex justify-content-start">
                    <a class="whatsapp-link d-flex align-content-middle" :id="`wa-${budget.id}`" @click="openWhatsApp(budget.patient_id.cellphone)" v-if="budget.patient_id.cellphone">
                      <WhatsAppIcon :id="`wa-${budget.id}`" />
                    </a>
                    <div>
                      {{ budget.patient_id.name }}
                    </div>
                  </div>

                  <b-tooltip :target="`wa-${budget.id}`" placement="bottom">
                    {{ budget.patient_id.cellphone }}
                  </b-tooltip>
                </td>

                <td class="td-body td-simple">
                  <span>{{ budget.request_types | formatRequestTypes }}</span>
                  <b-badge pill class="items-badge" :id="`bdg-${budget.id}`">+{{budget.items.length}}</b-badge>
                  <b-tooltip :target="`bdg-${budget.id}`" placement="bottom">
                    {{ budget.items | formatTooltipItems }}
                  </b-tooltip>
                </td>
                <td class="td-body td-simple">
                  <BudgetStatusSelect :budget="budget" />
                </td>
                <td class="td-body td-simple">
                  {{ budget.requester_doctor_id | formatPersonalName }}
                </td>

                <td class="td-body td-simple">
                  {{ budget.responsible_clinic_person_id | formatPersonalName }}
                </td>
                <td class="td-body td-simple">
                  {{ budget.items | calculateBudgetAmount }}
                </td>
                <td class="td-body td-simple">
                  <b-button variant="link" @click="edit(budget)">
                    Visualizar
                  </b-button>
                </td>

                <td class="td-body">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button class="text-left" variant="link mb-0" @click="edit(budget)">Editar/Ver Detalhes</b-button>
                      <b-button class="text-left" variant="link mb-0" @click="openBudgetAttachmentsAndNotesModal(budget)">Anexar Arquivo</b-button>
                      <b-button class="text-left" variant="link remove mb-0" @click="remove(budget)">Excluir</b-button>

                    </div>
                  </div>
                </td>

              </tr>
              </tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-pagination
              v-model="pagination.page"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              size="sm"
              align="right"
              @change="pag"
            />
          </div>
        </div>
      </div>
    </div>
    <BudgetUpsertModalForm :budget="budget" :editMode="form.editMode" @created="addBudget" />
    <BudgetAttachmentsAndNotes :budget="budget" />
    <AdvancedSearch v-model="filter" :clinic_id="clinic.id" @onAdvancedFilter="advancedFilter" />
  </div>
</template>
<script>
import {defaultFormData, requestTypes} from "@/modules/SellingPanel/config/budget"
import {budgetFilters} from "@/modules/SellingPanel/config/selling-panel"
import moment from "moment";
import {parseNumberToMoney} from "@/utils/moneyHelper"
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
import SearchPatient from "@sp/components/SearchPatient.vue"
import BudgetStatusSelect from "@sp/components/BudgetStatusSelect.vue"
import BudgetUpsertModalForm from "@sp/components/BudgetUpsertModalForm.vue"
import {isArray, isObject} from "lodash"
import BudgetAttachmentsAndNotes from "@/modules/SellingPanel/components/BudgetAttachmentsAndNotes.vue"

import { saveAs } from 'file-saver';
export default {
  props: {
    title: {type: String}
  },
  components: {
    WhatsAppIcon: () => import('@/assets/icons/whatsapp-icon.svg'),
    BudgetAttachmentsAndNotes,
    BudgetUpsertModalForm,
    BudgetStatusSelect,
    SearchPatient,
    AdvancedSearch: () => import('@sp/components/AdvancedSearch'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    FilterBar: () => import('@/assets/icons/filter-bar.svg'),
    Icon: () => import('@/components/General/Icon'),
  },
  data() {
    return {
      canEditModule: userHasPermission('SPCfg'),
      clinic: getCurrentClinic(),
      procedures: null,
      status: {
        status: {value: 'IN_NEGOCIATION', label: 'Em Negociação'}
      },
      health_plan_id: null,
      form: {
        editMode: false
      },
      advancedFilterActivated: false,
      filter: JSON.parse(JSON.stringify(budgetFilters)),
      pagination: {
        page: 1,
        totalRows: 0,
        perPage: 5
      },
      budgets: null,
      selectedBudgets: [],
      budget: {...defaultFormData}
    }
  },

  mounted() {
    this.getBudgets(this.clinic.id)
  },

  methods: {

    pag(page) {
      this.pagination.page = page
      this.getBudgets(this.clinic.id)
    },
    /**
     * Filterable, paginated method to retrieve budgets
     * @param clinicId
     */
    getBudgets(clinicId) {
      const l = this.$loading.show()
      this.api.getBudgets(clinicId, this.pagination.page, this.pagination.perPage, this.filter).then(res => {
        this.pagination.totalRows = res.data.total
        this.pagination.page = res.data.current_page

        this.budgets = res.data.data.map(budget => {
          return this.translateData(budget)
        })
      })
      .catch(error => {
        console.error(error)
        this.$toast.error(`Não foi possível completar a Busca por Orçamentos. \nPor favor, tente novamente. \nSe o problema persistir, contate o suporte`)
      })
      .finally(() => {
        l.hide()
      })
    },

    edit(budget) {
      this.budget = budget
      this.form.editMode = true
      this.openBudgetModal()
    },

    create() {
      this.budget = {...defaultFormData}
      this.budget.items = []
      this.budget.notes = []
      this.form.editMode = false
      this.openBudgetModal()
    },

    remove(budget) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
                Você tem certeza que deseja excluir o Orçamento?
                <p>Não será possível reverter essa ação.</p>
               </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar Exclusão',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          const l = this.$loading.show()
          this.api.deleteBudget(budget.id).then(res => {
            this.budgets = this.budgets.filter(b => b.id !== budget.id)
            this.$toast.success(`Orçamento Excluído.`)
          })
          .catch(error => {
            console.error(error)
            this.$toast.error(`Não foi possível excluir o Orçamento.`)
          })
          .finally(() => {
            l.hide()
          })
        }
      })
    },

    addBudget(budget) {
      this.budgets.unshift(this.translateData(budget))
    },

    advancedFilter(params) {
      this.advancedFilterActivated = params.activated
      this.getBudgets(this.clinic.id)
    },

    downloadBudgetFile() {
      if(this.selectedBudgets.length) {
        const l = this.$loading.show()
        this.api.getBudgetFileStream(this.selectedBudgets, this.clinic.id).then(res => {
          saveAs(
            new Blob([res.data], {
              type: 'application/octet-stream',
            }),
            `budgets.pdf`)
        })
            .catch(error => {
              this.$toast.error(`Não foi possível completar o Download da Proposta, por favor tente novamente`)
              console.error(error)
            })
            .finally(() => {
              l.hide()
              this.$toast.success(`Proposta baixada com sucesso`)
            })
      }
      else {
        this.$toast.warning(`Por favor, selecione pelo menos 1 Orçamento para baixar`)
      }
    },

    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')
      if(whatsappNumber.length < 10){
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
      window.open(`https://api.whatsapp.com/send?phone=55${whatsappNumber}`, '_blank')
    },

    openBudgetModal() {
      this.$bvModal.show('budget_upsert_form_modal')
    },

    openAdvancedSearchModal() {
      console.log('opening advanced search modal')
      this.$bvModal.show('budget_advanced_search')
    },

    openBudgetAttachmentsAndNotesModal(budget) {
      this.budget = budget
      this.$bvModal.show('attaches_and_notes')
    },

    // helpers methods
    translateData(budget) {
      return {
        ... budget,
        id: budget.id,
        patient_id: budget.patient,
        clinic_id: budget.clinic,
        responsible_clinic_person_id: budget.responsible_clinic_person,
        requester_doctor_id: budget.requester_doctor,
        request_types: budget.request_types.map(id => {
          return {id, label: requestTypes.get(id).label}
        }),
        health_plan_id: budget.health_plan
      }
    },

    toggleSelected(checked) {
      checked ?
          this.selectedBudgets = this.budgets.map(budget => budget.id) :
          this.selectedBudgets = []
    }
  },

  filters: {
    formatExpiresAt(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    formatRequestTypes(types) {
      if(isArray(types)) {
        const reqTypes = types.map(type => type.label)
        return reqTypes.join(', ')
      }
      return ''
    },

    calculateBudgetAmount(items) {
      return parseNumberToMoney(items.reduce((acc, item) => acc + parseFloat( item.final_price), 0.0))
    },

    formatPersonalName(entity) {
      return isObject(entity) ?
            entity.name :
            ''
    },

    formatTooltipItems(items) {
      const names = items.map(item => item.name)
      return names.join(' | ')
    },
  },
  watch: {
    'filter.patient.name'() {
      this.getBudgets(this.clinic.id)
    }
  }
}
</script>
<style lang="scss" scoped>

.filter__container {
  position: relative;
  background: transparent;
  border: 1px solid var(--neutral-300);

  .text-filter {
    color: var(--neutral-600)
  }
  .advanced-filter-activated {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
.whatsapp-link {
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  text-decoration: underline;
  cursor: pointer;

  div {
    font-weight: 600;

    div {
      color: var(--type-active);
      font-family: 'Nunito Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      text-decoration: none;
    }
  }
  span {
    margin-right: 5px;
  }
  svg {
    height: 28px;
    width: 28px;
  }
}
.ds-container {
  padding: 30px 10px;
  .ds-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 30px 0;
  }
  .ds-title {
    display: inline-flex;
    align-items: center;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 18px;
  }
  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }
  .small-icon {
    width: 20px;
    margin-left: 5px;
  }
}
.card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  margin: 20px 0;
}
.card-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--dark-blue);
}
.option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.togle-button {
  margin-right: 15px;
}

.p-table {
  width: 100%;
  text-align: left;
  margin: 20px 0;

  .td-header {
    font-weight: 700;
    font-size: 14px;
    color: var(--dark-blue);
    background-color: var(--neutral-100);
    border: none;
    border-bottom: 1px dashed var(--neutral-300);
    padding: 20px 0 7px 12px;
  }
  .td-body {
    padding: 5px 5px 5px 11px;
    border-bottom: 1px dashed var(--neutral-300);
    font-weight: 600;
    font-size: 16px;
    color: var(--type-active);

    .items-badge {
      background-color: var(--neutral-200) !important;
      color: var(--type-active);
      padding: 4px 7px;
      margin-left: 4px;
      font-size: 12px;
    }
  }

  .td-simple {
    font-weight: normal !important;
  }
  .procedure-text {
    max-width: 150px;
    margin-left: 10px;
  }
  .number {
    font-family: 'Red Hat Display';
    font-weight: 700;
    color: var(--neutral-500);
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .blue-icon {
    fill: #0C1D59;
  }
  .use-type {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
  }
  .product-name {
    cursor: pointer;
  }

  .more-with-menu {
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;
      min-width: 200px;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          padding: 12px 10px !important;
          &:hover {
            background-color: var(--neutral-100);
          }
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }
}
</style>
