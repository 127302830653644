<template>
  <div>
  <div class="d-flex">
    <nav class="doctors">
      <header class="doctors-title">Agendas:</header>
      <div
        class="doctor-list"
        v-for="(doctor, i) in doctors"
        :key="i"
        @click="select(doctor)"
        v-bind:class="{ active: doctor.value === selected.value }"
      >
        <Avatar
          size="40"
          class="mr-3"
          :src="doctor?.picture"
        />
        <p class="name">{{ doctor.name }}</p>
      </div>
    </nav>

    <main class="schedules-content">

      <div class="doctor-selected-title">{{ selected.name }}</div>

      <div class="tabs">
          <span class="tab" :class="{ 'active': currentTab == 'funcionamento' }" @click="currentTab = 'funcionamento'">Horários semanais</span>
          <span class="tab" :class="{ 'active': currentTab == 'excecoes' }" @click="currentTab = 'excecoes'">Exceções</span>
      </div>

      <div class="tabs-content">
        <div v-if="currentTab == 'funcionamento'">
          <div class="subtitle">Definir os horários semanais</div>
            <div class="times">
              <div class="times-default">
                <div class="times-list"
                  v-for="(date, key) in dates"
                  :key="date.title"
                >
                  <div
                    class="times-tag"
                    :class="{ active: isClinicDayActive(date.value) }"
                  >
                    {{ date.title }}
                  </div>
                  <div class="times-data">
                      <div class="invalid-hour" v-if="invalidHours[key]">
                        Adicione intervalos entre {{ moment(configDays[key].schedule_config_day_intervals[0].start_time, 'HH:mm:ss').format('HH:mm') }} e
                        {{ moment(configDays[key].schedule_config_day_intervals[0].end_time, 'HH:mm:ss').format('HH:mm') }} e
                        uma duração da consulta em minutos, que não conflite com um intervalo já existente
                      </div>
                    <div
                      class="times-data-inputs"
                      v-for="day in filterByDay(date.value)"
                      :key="day.id"
                    >
                      <div class="intervals"
                        v-for="interval in day.schedule_config_day_intervals"
                        :key="interval.id">
                        <the-mask
                          :mask="['##:##']"
                          v-model="interval.start_time"
                          masked
                          class="form-control"
                          :class="isInvalidInterval(interval, date.value) && 'error'"
                          @blur.native="updateConfig(date.value, key, interval)"
                          :disabled="!canEdit || isUpdating"
                        />
                        <div class="div-space">-</div>
                        <the-mask
                          :mask="['##:##']"
                          v-model="interval.end_time"
                          masked
                          class="form-control"
                          :class="isInvalidInterval(interval, date.value) && 'error'"
                          @blur.native="updateConfig(date.value, key, interval)"
                          :disabled="!canEdit || isUpdating"
                        />
                        <multiselect
                          v-model="interval.duration_time"
                          :ref="`durationRef`"
                          :options="durationOptions"
                          :showLabels="false"
                          :searchable="true"
                          :allowEmpty="false"
                          placeholder=""
                          class="with-border"
                          @input="updateConfig(date.value, key, interval)"
                          @search-change="setCustomOption"
                          :showNoResults="false"
                          :disabled="!canEdit || isUpdating"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown />
                            </div>
                          </template>

                          <template slot="option" slot-scope="props">
                            {{ getDurationLabel(props.option) }}
                          </template>

                          <template slot="singleLabel" slot-scope="props">
                            {{ getDurationLabel(props.option) }}
                          </template>

                          <template slot="afterList" v-if="searchQuery && !durationOptions.includes(parseInt(searchQuery))">
                            <li @click="addCustomDuration(date.value, key, interval)">
                              <div class="multiselect__option">
                                {{ searchQuery }} minutos
                              </div>
                            </li>
                          </template>
                        </multiselect>
                        <Trash
                          class="trash"
                          :disabled="!canEdit"
                          @click="destroyInterval(interval.id)"/>
                      </div>
                    </div>

                    <div class="times-data-inputs" v-if="creatingIn == date.value">
                      <div class="intervals">
                        <input
                          v-mask="'##:##'"
                          id="start-form-field"
                          class="form-control"
                          v-model="intervalForm.start_time"
                          @blur="createInterval(date.value, key)"
                          :disabled="!canEdit || isUpdating"
                        />
                        <div class="div-space">-</div>
                        <input
                          v-mask="'##:##'"
                          class="form-control"
                          @blur="createInterval(date.value, key)"
                          v-model="intervalForm.end_time"
                          :disabled="!canEdit || isUpdating"
                        />
                        <multiselect
                          v-model="intervalForm.duration_time"
                          :ref="`durationRef`"
                          :options="durationOptions"
                          :showLabels="false"
                          :searchable="true"
                          :allowEmpty="false"
                          placeholder=""
                          class="with-border"
                          @input="createInterval(date.value, key)"
                          @search-change="setCustomOption"
                          :showNoResults="false"
                          :disabled="!canEdit || isUpdating"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown />
                            </div>
                          </template>

                          <template slot="option" slot-scope="props">
                            {{ getDurationLabel(props.option) }}
                          </template>

                          <template slot="singleLabel" slot-scope="props">
                            {{ getDurationLabel(props.option) }}
                          </template>

                          <template slot="afterList" v-if="searchQuery && !durationOptions.includes(parseInt(searchQuery))">
                            <li @click="addCustomDuration(date.value, key, intervalForm)">
                              <div class="multiselect__option">
                                {{ searchQuery }} minutos
                              </div>
                            </li>
                          </template>
                        </multiselect>
                        <div class="empty-space"></div>
                      </div>
                    </div>
                    <div class="invalid-hour">
                      <b-button
                        class="btn-add"
                        variant="link"
                        @click="setCreator(date.value)"
                            :disabled="!canEdit || (configDays[key] && !configDays[key].status) || creatingIn == date.value"
                      >
                        <span class="add-text">Adicionar intervalo</span>
                      </b-button>
                    </div>
                </div>
                <!-- <div class="toggle">
                  <toggle-button
                    class="statusBtn"
                    :sync="true"
                    :height="32"
                    :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    v-model="configDays[key].status"
                    :disabled="!canEdit"
                  />
                </div> -->
              </div>
              </div>
            </div>
            <div class="consultation-duration">
              <div class="subtitle">Duração padrão da consulta em geral</div>
              <multiselect
                id="durations"
                :ref="`durationRef`"
                v-model="consultationDuration"
                :options="durationOptions"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                placeholder=""
                class="with-border custom-duration"
                :disabled="!canEdit || isUpdating"
                @search-change="setCustomOption"
                :showNoResults="false"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="option" slot-scope="props">
                  {{ getDurationLabel(props.option) }}
                </template>
                <template slot="singleLabel" slot-scope="props">
                  {{ getDurationLabel(props.option) }}
                </template>

                <template slot="afterList" v-if="searchQuery && !durationOptions.includes(parseInt(searchQuery))">
                  <li @click="addCustomDuration(null, null, null)">
                    <div class="multiselect__option">
                      {{ searchQuery }} minutos
                    </div>
                  </li>
                </template>
              </multiselect>
            </div>
        </div>

        <div class="mt-5">
          <toggle-button
              :sync="true"
              :height="24"
              class="mr-2"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              v-model="includedDoctorInvited"
            />
            <span>Exibir médicos convidados</span>
        </div>

        <div v-if="currentTab == 'excecoes'">
          <ScheduleExceptionView 
            :scheduleConfigId="this.scheduleConfig?.id"
            :exceptions="doctorExceptions"
          />
        </div>
      </div>
    </main>
  </div>

    <UpdateDurationModal
      :oldValue="oldDuration"
      :newValue="newDuration"
      :updateDuration="updateDefaultDuration"
      :getDurationLabel="getDurationLabel"/>
  </div>
</template>

<script>
import { getCurrentClinic, userHasPermission } from '@/utils/localStorageManager'
import moment from 'moment'

export default {
  name: 'AgendaSettings',
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    UpdateDurationModal: () => import('@/components/Schedule/UpdateDurationModal'),
    ScheduleExceptionView: () => import('@/modules/scheduleConfig/components/ScheduleExceptionView'),
  },
  data() {
    return {
      currentTab: 'funcionamento',


      clinic: getCurrentClinic(),
      canEdit: userHasPermission('CaProf2'),
      user: null,
      searchQuery: null,
      isUpdating: false,

      consultationDuration: 30,
      oldDuration: null,
      newDuration: null,
      scheduleConfig: null,
      scheduleConfigId: null,

      creatingIn: '',
      intervalForm: this.getDefaultIntervalForm(),

      selected: { name: 'Carregando...', value: '1' },
      doctors: [],

      durationOptions: [15,30,45,60],

      dates: [
        {
          title: 'Domingo',
          value: 'DOMINGO'
        },
        {
          title: 'Segunda',
          value: 'SEGUNDA-FEIRA'
        },
        {
          title: 'Terça',
          value: 'TERCA-FEIRA'
        },
        {
          title: 'Quarta',
          value: 'QUARTA-FEIRA'
        },
        {
          title: 'Quinta',
          value: 'QUINTA-FEIRA'
        },
        {
          title: 'Sexta',
          value: 'SEXTA-FEIRA'
        },
        {
          title: 'Sábado',
          value: 'SABADO'
        }
      ],

      doctorDates: [],
      doctorExceptions: [],

      exceptionDays: [],
      exceptionTimes: {
        days: '',
        start: '',
        end: ''
      },
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },

      modalShow: false,
      configDays: [],
      invalidHours: [],

      dayOfTheWeek: {
        DOMINGO: 0,
        'SEGUNDA-FEIRA': 1,
        'TERCA-FEIRA': 2,
        'QUARTA-FEIRA': 3,
        'QUINTA-FEIRA': 4,
        'SEXTA-FEIRA': 5,
        SABADO: 6
      },

      includedDoctorInvited: false

    }
  },
  watch: {
    "consultationDuration"(newValue, oldValue) {
      if(!this.scheduleConfig || newValue * 1 === this.scheduleConfig.duration_time * 1)
        return;
      this.oldDuration = oldValue * 1;
      this.newDuration = newValue * 1;
      this.$bvModal.show('update-duration-modal')
    },
    "includedDoctorInvited"() {
      this.getClinicDoctors();
    }
  },
  async mounted() {
    const response = await this.api.getClinicScheduleConfig(this.clinic.id)
    this.orderDaysClinic(response.data.configs.days)

    this.getClinicDoctors();
  },
  computed: {
    exceptionDates() {
      return this.exceptionDays.map(day => day.date)
    },
    attributes() {
      return this.exceptionDates.map(date => ({
        highlight: true,
        dates: date
      }))
    }
  },
  methods: {
    getClinicDoctors() {
      const isLoading = this.$loading.show()

      this.api
      .getClinicDoctors(this.clinic.id, this.includedDoctorInvited)
      .then(({ data }) => {
        const doctors = data.doctors.map(doctor => ({
          value: doctor.id,
          name: doctor.name,
          picture: doctor.picture
        }))

        this.doctors = doctors
        this.selected = doctors[0]

        this.getConfig(doctors[0].value);
      })
      .catch(console.log)
      .finally(() => {
        isLoading.hide()
      })
    },
    setCustomOption(query) {
      if(isNaN(parseInt(query))) return;
      this.searchQuery = query;
    },
    addCustomDuration(day, index, interval) {
      if(interval) {
        interval.duration_time = parseInt(this.searchQuery)
        this.updateConfig(day, index, interval)
      } else {
        this.consultationDuration = parseInt(this.searchQuery);
      }
      this.durationOptions.push(parseInt(this.searchQuery));
      this.durationOptions = [...new Set(this.durationOptions)];
      this.durationOptions.sort((a,b) => a - b);
      this.searchQuery = null;
      this.$refs.durationRef.forEach(el => el.deactivate());
    },
    getDurationLabel(durationTime) {
      const horas = Math.floor(durationTime / 60);
      const minutos = durationTime % 60;
      let label = '';
      label += horas > 0 ? horas + ( horas > 1 ? ' horas' : ' hora' ): '';
      label += label && minutos > 0 ? ' e ' : '';
      label += minutos > 0 ? minutos + ( minutos > 1 ? ' minutos' : ' minuto' ): '';
      return label;
    },
    orderDaysClinic(days) {
      days.map(day => {
        this.configDays[this.dayOfTheWeek[day.day]] = day
      })
    },
    getConfig(professionalId) {
      const isLoading = this.$loading.show()

      this.api
        .getDoctorScheduleConfig(this.clinic.id, professionalId)
        .then(res => {
          this.scheduleConfig = res.data.configs;
            const {
              id: scheduleConfigId,
              duration_time: durationTime,
              days,
              exceptions
            } = res.data.configs

            this.scheduleConfigId = scheduleConfigId
            this.doctorDates = days
            this.doctorExceptions = exceptions

            this.consultationDuration = durationTime || 30;
            this.durationOptions.push(this.consultationDuration * 1);
            days.forEach(day => {
              day.schedule_config_day_intervals.forEach(interval => {
                this.durationOptions.push(interval.duration_time);
              })
              day.schedule_config_day_intervals.sort((a, b) => moment(a.start_time, 'HH:mm') - moment(b.start_time, 'HH:mm'));
            });
            this.durationOptions = [...new Set(this.durationOptions)];
            this.durationOptions.sort((a,b) => a - b);
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    select(doctor) {
      this.selected = doctor
      this.creatingIn = '';
      this.clearIntervalForm();
      this.getConfig(doctor.value);
    },
    destroyInterval(id) {
      const isLoading = this.$loading.show()

      this.api
        .deleteScheduleConfigDayInterval(id)
        .then(() => {
          this.select(this.selected)
        })
        .catch(err => {
          console.log('Err', err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    setCreator(date) {
      const day = this.filterByDay(date);
      this.creatingIn = date;
      this.intervalForm = this.getDefaultIntervalForm();
      this.intervalForm.schedule_config_day_id = (day[0] && day[0].id) || '';
      this.intervalForm.day = date;
      setTimeout(() => {
        document.querySelector('#start-form-field').focus()
      }, 300)
    },
    clearIntervalForm() {
      this.intervalForm = this.getDefaultIntervalForm();
    },
    getDefaultIntervalForm() {
      return {
        id: '',
        start_time: '',
        end_time: '',
        duration_time: this.consultationDuration,
        schedule_config_id: this.scheduleConfigId,
        schedule_config_day_id: '',
        day: '',
      }
    },
    async createInterval(day, index) {
      if(!this.intervalForm.start_time || !this.intervalForm.end_time || !this.intervalForm.duration_time)
        return;
      const isInvalid = this.isInvalidInterval(this.intervalForm, day);
      if(isInvalid) {
        this.$set(this.invalidHours, index, true);
        return this.$toast.error(isInvalid);
      }
      this.isUpdating = true;
      try {
        await this.api.createScheduleConfigDayInterval(this.intervalForm);
        this.select(this.selected)
        this.$toast.success('Intervalo salvo com sucesso!')
      } catch (err) {
        console.log(err)
      } finally {
        this.isUpdating = false;
      }
    },
    async updateConfig(day, index, interval) {
      if(!interval.start_time || !interval.end_time || !interval.duration_time)
        return;
      const isInvalid = this.isInvalidInterval(interval, day);
      if(isInvalid) {
        this.$set(this.invalidHours, index, true);
        return this.$toast.error(isInvalid);
      }
      this.$set(this.invalidHours, index, false);

      await this.updateScheduleConfigDayInterval(interval);
    },
    async updateScheduleConfigDayInterval(interval) {
      try {
        this.isUpdating = true;
        await this.api.updateScheduleConfigDayInterval(interval.id, { ...interval });
      } catch (err) {
        console.log(err)
      } finally {
        this.isUpdating = false;
      }
    },
    isInvalidInterval(interval, dayOfWeekStr) {
      const clinicDayConfig = this.configDays.find(day => day.day === dayOfWeekStr);
      if (!clinicDayConfig.status) return false;
      const clinicDayInterval = clinicDayConfig.schedule_config_day_intervals[0];
      const clinicStartTime = moment(clinicDayInterval.start_time, 'HH:mm:ss');
      const clinicEndTime = moment(clinicDayInterval.end_time, 'HH:mm:ss');
      const newStartTime = moment(interval.start_time, 'HH:mm');
      const newEndTime = moment(interval.end_time, 'HH:mm');

      const todayIntervals = this.filterByDay(dayOfWeekStr)[0]?.schedule_config_day_intervals.filter(int => int !== interval)
      let conflictedDate = false
      if(todayIntervals?.length){        
        todayIntervals.forEach(todayInterval => {
          if(
            (moment(todayInterval.start_time, 'HH:mm') <= newStartTime && moment(todayInterval.end_time, 'HH:mm') > newStartTime) ||
            (moment(todayInterval.start_time, 'HH:mm') < newEndTime && moment(todayInterval.end_time, 'HH:mm') >= newEndTime )
          ) conflictedDate = true
        });
      }

      if(conflictedDate) return 'O intervalo adicionado é conflitante com um intervalo já adicionado ao profissional'

      if (!newStartTime.isValid() || newStartTime > moment().endOf('day'))
        return 'Horário inicial inválido'

      if(!newEndTime.isValid() || newEndTime > moment().endOf('day'))
        return 'Horário final inválido';

      if(newEndTime <= newStartTime)
        return 'Horário final deve ser maior que o inicial';

      if(newStartTime < clinicStartTime || newEndTime > clinicEndTime)
        return 'Intervalo deve respeitar intervalo da clínica';

      if(!interval.duration_time)
        return 'Duração não selecionada';
    },
    isClinicDayActive(dayOfWeekStr) {
      return this.configDays.find(day => day.day === dayOfWeekStr)?.status;
    },
    filterByDay(day) {
      const scheduleDay = this.isClinicDayActive(day);
      const result = this.doctorDates.filter(date => date.day === day)
      return result && (scheduleDay && scheduleDay.status !== 0) ? result : []
    },
    updateDuration() {
      const isLoading = this.$loading.show()
      this.api
        .updateDurationTime(
          this.clinic.id,
          this.scheduleConfigId,
          this.consultationDuration
        )
        .then(res => {
          const resValue = res.data.scheduleConfig.scheduleConfig.duration_time
          this.scheduleConfig.duration_time = resValue
          this.consultationDuration = resValue
          this.$toast.success('Atualizado com sucesso!')
        })
        .catch(err => {
          console.log('Error', err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    updateDefaultDuration(option) {
      this.$bvModal.hide('update-duration-modal');
      if (!option) {
        this.consultationDuration = this.scheduleConfig.duration_time * 1;
        return;
      }
      this.updateDuration(); // only-default
      this.doctorDates.forEach(date => {
        date.schedule_config_day_intervals.forEach(async interval => {
          if(option === 'all-intervals' || (option === 'only-equals' && interval.duration_time === this.oldDuration)) {
            interval.duration_time = this.consultationDuration;
            await this.updateScheduleConfigDayInterval(interval);
          }
        })
      })
    },
    resetModalFields() {
      this.exceptionDays = []
      this.exceptionTimes = []
    },
    onDayClick(day) {
      if (day.isDisabled) return

      const idx = this.exceptionDays.findIndex(d => d.id === day.id)

      if (idx >= 0) {
        this.exceptionDays.splice(idx, 1)
      } else {
        this.exceptionDays.push({
          id: day.id,
          date: day.date
        })
      }
    },
    addTmpTime() {
      this.exceptionTimes.push({
        start: '',
        end: ''
      })
    },
    removeTmpTime(idx) {
      this.exceptionTimes.splice(idx, 1)
    },
    toggleModal() {
      this.modalShow = !this.modalShow
      this.resetModalFields()
    },
    parseTimes(obj) {
      return obj
        .map(el => {
          const start = el.start.split(':')
          const end = el.end.split(':')

          start.pop()
          end.pop()

          return {
            id: el.id,
            start: start.join(':'),
            end: end.join(':')
          }
        })
        .sort((a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0))
    },
    groupBy(collection, property) {
      var i = 0
      var val
      var index
      var values = []
      var result = []
      for (; i < collection.length; i++) {
        val = collection[i][property]
        index = values.indexOf(val)
        if (index > -1) result[index].push(collection[i])
        else {
          values.push(val)
          result.push([collection[i]])
        }
      }
      return result
    },
    async deleteExceptions(idx, date) {
      this.select(this.selected)

      const isLoading = this.$loading.show()

      await this.api
        .destroyClinicException(this.clinic.id, date.id)
        .then(res => {
          if (res.status === 200) {
            this.doctorExceptions.splice(idx, 1)
          }
        })
        .catch(err => console.log('Err', err))

      isLoading.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.error, .error:focus {
  border-color: var(--states-error) !important;
}

.intervals {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  gap: 2px;

  .div-space {
    margin-inline: 4px;
  }
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.title {
  font-family: 'Red Hat Display';
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-blue);
  margin-bottom: 27px;
}

.with-border {
  width: 138px;
  height: 38px;
  margin-left: 16px;
  margin-bottom: 0;

  &.custom-duration {
    padding-left: 0;
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }
}

.col-md-6 {
  padding-left: 0;
}

.doctors {
  border-right: 1px solid #d9dff2;
  padding-right: 0;
  width: 20%;

  .doctors-title {
    padding: 24px;
    font-size: 18px;
    font-weight: 700;
    color: var(--dark-blue);
    border-bottom: 1px solid #d9dff2;
  }

  .doctor-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;

    padding: 16px 20px;

    border-bottom: 1px solid #d9dff2;
    border-left: 3px solid transparent;

    transition: all ease 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      border-left: 3px solid #ffb27a;
    }

    &.active {
      border-left: 3px solid #ff6b00;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 16px;
    }

    .name {
      font-family: 'Nunito Sans';
      font-size: 16px;
      line-height: 150%;
      color: #525c7a;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.row {
  margin-left: 0px;
}

.trash {
  aspect-ratio: 1;
  width: 24px;
  margin-left: 8px;
  stroke: var(--neutral-500);

  &:hover {
    cursor: pointer;
}
}

.empty-space {
  width: 24px;
  margin-left: 8px;
}

.schedules-content {
  padding: 20px;
  width: 100% !important;

  .doctor-selected-title {
    font-family: 'Red Hat Display';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 30px;
    color: #525c7a;
  }

  .subtitle {
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #525c7a;
    margin-bottom: 16px;
  }

  .times {
    display: flex;
    border: 1px solid #d9dff2;
    border-radius: 8px;

    .invalid-hour {
      display: flex;
      align-items: center;
      max-width: 35ch;
      margin-bottom: 16px;
    }

    .times-data-inputs{
      display: block;
      color: #8696ac;
      margin-bottom: 0px;

      > div {
        display: flex;
      }

      .form-control {
        height: 38px;
        width: 80px;
        padding-left: 15px;
      }
    }

    .times-default {
      margin: auto 0;
      width: 100%;

      .times-list {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin: auto 0;
        align-items: stretch;

        padding: 24px;
        border-bottom: solid 1px #d9dff2;

        .times-tag {
          padding: 8px 16px;
          border-radius: 50px;
          background-color: #d9dff2;
          color: #000;
          width: 100px;
          text-align: center;
          height: 40px;

          &.active {
            background: #ffebcc;
          }
        }

        .times-data {
          margin-left: 24px;
        }
      }
    }

    .times-exceptions {
      .times-modal {
        position: absolute;
        top: 0;
        left: -5px;
        width: calc(100% + 10px);
        background-color: #fff;
        box-shadow: 0px 4px 28px rgba(12, 29, 89, 0.1),
        0px 24px 48px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;

        .times-modal-content {
          padding: 24px;

          &.colored {
            padding: 16px 24px;
            background-color: #f4f5fb;
            border-top: solid 1px #d9dff2;
            border-bottom: solid 1px #d9dff2;
          }

          .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 16px;
            color: #525c7a;
          }

          .subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 16px;
            color: #525c7a;
          }
        }

        .times-modal-footer {
          padding: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          button {
            background-color: #305bf2;
            height: 38px;
            min-width: 20%;
            border-radius: 8px;
            border: none;
            margin-left: 8px;
            font-weight: bold;
            font-size: 16px;
            color: #fff;

            transition: all ease 0.3s;

            &:nth-child(1) {
              border: solid 2px #305bf2;
              color: #305bf2;
              background-color: #fff;
              min-width: 20%;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .times-exceptions-header {
        padding: 24px;

        border-bottom: solid 1px #d9dff2;

        h5 {
          font-family: 'Red Hat Display';
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.01em;
          margin-bottom: 16px;
          color: #525c7a;
        }

        p {
          font-family: 'Nunito Sans';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 16px;
        }

        .add-btn {
          background-color: #305bf2;
          width: 100%;
          height: 38px;
          border-radius: 8px;
          border: none;
          color: #fff;

          font-family: 'Nunito Sans';
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;

          cursor: pointer;
          transition: all ease 0.3s;

          &:hover {
            background-color: #6184ff;
          }
        }
      }

      .times-table {

        .times-header-title {
          display: grid;
          grid-template-columns: 60% 40%;

          background-color: #f4f5fb;
          padding: 12px 24px;

          font-size: 16px;
          font-weight: 600;

          border-bottom: 1px solid #d9dff2;
        }

        .times-table-list {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            display: grid;
            grid-template-columns: 60% 40%;
            align-items: center;

            padding: 24px;

            border-bottom: 1px solid #d9dff2;

            div {
              &.hours {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                button {
                  padding: 0;
                  background: none;
                  border: none;

                  color: #7f91d2;

                  svg {
                    width: 16px;
                    stroke: #7f91d2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .consultation-duration {
    width: 440px;
  }

  .update-duration-btn {
    width: 220px;
    height: 52px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .add-text {
    white-space: nowrap;
  }

  .toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>

<style lang="scss" scoped>
  @import '@/modules/scheduleConfig/styles/tabs.scss';
</style>
