<template>
  <section>
    <section v-if="batch" class="container-header">
      <div class="text-container">
        <h3 class="title">{{ title }}</h3>

        <div class="btn-title-container">
          <b-button
            class="btn-import-xml"
            v-if="showImportXml"
            variant="link"
            @click="showImportXmlModal"
          >
          <UploadIcon class="upload-icon m-auto" />
            Importar XML
          </b-button>
          <b-button variant="outline-primary" class="btn-edit" @click="openInfoSidebar">
            <Edit class="icon edit" />
          </b-button>
          <b-button variant="outline-primary" class="btn-upload" @click="openModalBatchFiles">
            <FileIcon class="icon" />
          </b-button>
          <b-button
            v-if="showManagaGuideButton"
            variant="primary"
            @click="openManageGuides"
          >
            Gerenciar guias
          </b-button>
          <b-button
            v-if="showFinishBatchButton"
            variant="success"
            @click="$emit('finishBatch', batch)"
          >
            {{ !this.batch.bill ? 'Finalizar lote' : 'Inserir pagamento' }}
          </b-button>

          <StatusButton
            prefix="Situação:"
            v-model="batch.status"
            :disabled="!userHasPermission('FatTissLotEnv2')"
            :statuses="tissBatchStatusOptions"
            @change="handleInvoiceSituation" />
        </div>
        
      </div>
      <div class="subtitle">
        <p><span>Lote Nº:</span> {{ batch?.number }}</p>
        <p><span>Fatura Nº:</span> {{ batch?.tiss_invoice.number }}</p>
        <p><span>Tipo de guia:</span> {{ getTypes(batch?.type) }}</p>
        <p><span>Versão TISS:</span> {{ batch?.xml_version }}</p>
        <p><span>NF:</span> {{ batch?.nf ?? '-' }}</p>
        <p><span>Nº protocolo::</span> {{ batch?.protocol_number ?? '-' }}</p>
        <p><span>Pagamento:</span> {{ batch?.payment_date ? this.moment(batch.payment_date).format('DD/MM/YYYY') : '-' }}</p>
      </div>
    </section>
    <hr />
  </section>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import { getTypes } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { tissBatchStatusOptions, TissBatch } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'BatchHeaderContainer',
  components: {
    StatusButton: () => import('@/modules/tiss/manageGuides/components/StatusButton'),
    Edit: () => import('@/assets/icons/edit.svg'),
    FileIcon: () => import('@/assets/icons/file.svg'),
    UploadIcon: () => import('@/assets/icons/upload-cloud.svg'),
  },
  props: {
    title: String,
    batch: Object,
    openManageGuides: {
      type: Function,
      required: true
    },
    handleInvoiceSituation: {
      type: Function,
      required: true
    },
    openModalBatchFilesUpload: {
      type: Function,
      required: true
    },
    openImportXmlModal: {
      type: Function,
      required: false
    }
  },
  computed: {
    showImportXml() {
      return this.batch.tiss_guides[0].clinic_id === '10c32724-8534-4bd6-8540-03bd553f5149';
    },
    showManagaGuideButton() {
      if (!userHasPermission('FatTissLotEnv3')) return false
      return this.batch.status === TissBatch.BATCH_STATUS_RECEIVED || 
        this.batch.status === TissBatch.BATCH_STATUS_TO_SENT ||
        this.batch.status === TissBatch.BATCH_STATUS_INVOICED_PARTIAL ||
        this.batch.status === TissBatch.BATCH_STATUS_INVOICED ||
        (this.batch.status === TissBatch.BATCH_STATUS_FINISHED && this.batch.items_opened > 0)
    },
    showFinishBatchButton() {
      if (!userHasPermission('FatTissLotEnv2')) return false
      return (!this.batch.bill && [TissBatch.BATCH_STATUS_INVOICED, TissBatch.BATCH_STATUS_INVOICED_PARTIAL].includes(this.batch.status)) ||
          (this.batch.status === TissBatch.BATCH_STATUS_FINISHED && this.batch.items_opened === 0)
    },
  },
  data() {
    return {
      TissBatch,
      tissBatchStatusOptions,
      selection: false,
      selectedBatch: null
    }
  },
  methods: {
    getTypes,
    userHasPermission,
    openInfoSidebar() {
      this.$emit('openInfo', this.batch.id)
    },
    openModalBatchFiles() {
      this.openModalBatchFilesUpload(this.batch.id);
    },
    showImportXmlModal(){
      this.openImportXmlModal(this.batch);
    },
  },
}
</script>

<style lang="scss" scoped>
.container-header {
  .text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    color: var(--dark-blue);
    letter-spacing: -0.01em;
    font-weight: 700;
    font-size: 18px;
  }

  .subtitle {
    display: flex;
    flex-direction: row;

    p {
      font-weight: 700;
      font-size: 0.8rem;
      margin-right: 20px;

      @media (max-width: 1368px) {
        font-size: 14px;
      }

      span {
        color: var(--blue-600);
        margin-right: 5px;
      }
    }
  }
}

.delete {
  padding: 4px;
  width: 56px;
  height: 36px;
  border: 2px solid var(--states-red-soft);
  border-radius: 8px;
  margin-top: 1px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn-title-container {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  div {
    margin-left: 5px;
  }

  .btn-edit {
    padding: 5px 8px;

    svg {
      width: 24px;
      height: 24px;
      stroke: var(--blue-600);
    }
  }

  .btn-upload {
    padding: 5px 8px;

    svg {
      height: 24px;
      width: 24px;
      overflow: inherit;
      fill: var(--neutral-000);
      stroke: var(--blue-500);
    }
  }
}

hr {
  width: 100%;
  color: var(--neutral-200);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 1%;

  &.trash {
    fill: none;
    stroke: var(--states-red-soft);
  }
}

.btn-import-xml{
  display: flex;
  align-items: center;
  border: 1px solid #305BF2;
  padding: 7px 10px !important;
  border-radius: 10px;
  transition: all 0.5s;

    &:hover{
      background-color: #305BF2;
      color: #fff;

      & svg{
        fill: #fff;
        stroke: #fff;
      }
    }

   & svg{
    height: 14px !important;
    transition: all 0.5s;
   }
}
</style>
