<template>
  <b-modal
      :id="component.modal_id"
      size="xl"
      centered
      :title="`${editModeText} Orçamento`"
  >
    <section>
      <b-row>
        <b-col class="col-7">
          <b-form-group class="form-group">
            <label for="patient_id">Paciente</label>
            <SimplePatientDataSelect
                v-model="budget"
            />
            <div v-if="validated && !budget.patient_id" class="custom-invalid-feedback">
              Por favor, é Obrigatório informar um Paciente
            </div>
          </b-form-group>
        </b-col>

        <b-col class="col-5">
          <b-form-group class="form-group">
            <label for="health_plan">
              Convênio
            </label>
            <template v-if="isObject(budget.patient_id)">
              <SimpleHealthPlanSelect :clinic_id="clinic.id" v-model="budget" />
            </template>
            <template v-else>
              <div class="health-plan-placeholder">Para selecionar um Plano, selecione um Paciente</div>
            </template>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group class="form-group">
              <label for="description">
                Solicitação
              </label>
              <BudgetRequestTypesSelect v-model="budget" />
            <div v-if="validated && budget.request_types.length < 1" class="custom-invalid-feedback">
              Por favor, informe o(s) tipo(s) de Solicitação(ões).
            </div>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group class="form-group">
            <label for="expires_at">Validade</label>
            <BudgetExpiresAt v-model="budget" />
            <div v-if="validated && !budget.expires_at" class="custom-invalid-feedback">
              Por favor, informe a data de expiração para o Orçamento.
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="form-group">
            <label for="requester_doctor_id">
              Médico Solic. <span class="help">(opcional)</span>
            </label>
            <SimpleDoctorSelect
              :clinic_id="clinic.id"
              v-model="budget"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="form-group">
            <label for="responsible_clinic_person_id">Responsável</label>
            <SimpleClinicPersonSelect :clinic_id="clinic.id" v-model="budget" />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col class="">
          <b-form-group>
            <label for="observation">
              Observação <span>(opcional)</span>
            </label>
            <TextArea
                id="observation"
                v-model="budget.observation"
                class="form-control"
                rows="3"
                placeholder="Descrever"
                :maxLength="2000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="divider" />
      <!--      Procedure Items Form -->
      <b-row>
        <b-col class="procedure-item-form-container" v-if="budget.health_plan_id">
          <ProcedureItemForm v-model="budget" :clinic="clinic" />
        </b-col>
      </b-row>
      <!-- Procedure Items Table -->
      <b-row v-if="budget.items.length > 0">
        <b-col class="procedure-item-table-container">
          <ProcedureItemTable v-model="budget" />
        </b-col>
      </b-row>

      <BudgetNotes v-model="budget" />

    </section>
    <template #modal-footer>
      <b-button
          variant="primary"
          @click="cancel"
      >
        Cancelar
      </b-button>
      <b-button
          variant="primary"
          @click="save"
          :disabled="! canModify"
      >
        {{ persistButtonText }} Orçamento
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { isObject } from "@/utils/dataHelper"
import { defaultFormData, requestTypes, canModifyByStatus } from "@sp/config/budget"

export default {
  components: {
    BudgetNotes: () => import('@sp/components/BudgetNotes.vue'),
    SimpleClinicPersonSelect: () => import('@sp/components/SimpleClinicPersonSelect.vue'),
    ProcedureItemTable: () => import('@sp/components/ProcedureItemTable.vue'),
    SimpleDoctorSelect: () => import('@sp/components/SimpleDoctorSelect.vue'),
    SimpleHealthPlanSelect: () => import('@sp/components/SimpleHealthPlanSelect.vue'),
    BudgetExpiresAt: () => import('@sp/components/BudgetExpiresAt.vue'),
    BudgetRequestTypesSelect: () => import('@sp/components/BudgetRequestTypesSelect.vue'),
    SimplePatientDataSelect: () => import('@sp/components/SimplePatientDataSelect.vue'),
    ProcedureItemForm: () => import('@sp/components/ProcedureItemForm.vue'),
    TextArea: () => import('@/components/General/TextArea')
  },
  props: {
    budget: {
      type: Object,
      required: true,
      default: () => {
        return {...defaultFormData}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    editModeText() {
      return this.editMode ? 'Atualizar' : 'Novo'
    },

    persistButtonText() {
      return this.editMode ? 'Atualizar': 'Salvar'
    },

    persistedMessageText() {
      return this.editMode ? 'Atualizado' : 'Criado'
    },

    canModify() {
      return canModifyByStatus(this.budget.status)
    }
  },

  data(){
    return {
      clinic: getCurrentClinic(),
      user: JSON.parse(localStorage.getItem('user')),
      validated: false,
      component: {
        modal_id: 'budget_upsert_form_modal'
      },
      requestTypes
    }
  },

  methods: {
    getDefaultForm(){
      return {...defaultFormData}
    },

    isObject,

    isValidForm() {
      let isValidForm = true

      if(! this.budget.clinic_id) {
        isValidForm = false
      }

      if(this.budget.items.length === 0) {
        isValidForm = false
        this.$toast.error('Por favor, adicione pelo menos 1 item no Orçamento')
      }

      if(this.budget.request_types.length === 0) {
        isValidForm = false
        this.$toast.error('Por favor, escolha pelo menos 1 tipo de Solicitação')
      }

      this.validated = true
      return isValidForm
    },

    cancel() {
      this.$bvModal.hide(this.component.modal_id)
    },
    successMessage() {
      this.$toast.success(`Orçamento ${this.persistedMessageText} com Sucesso`)
    },
    errorMessage(error) {
      this.$toast.error('Um erro ocorreu. Por favor, tente novamente. Se o erro persistir, contate o Suporte.')
      console.error(error)
    },
    prepareToUpsert() {
      const data = {...this.budget}

      data.health_plan_id = data.health_plan_id.id
      data.patient_id = data.patient_id.id
      data.requester_doctor_id = data.requester_doctor_id?.id
      data.responsible_clinic_person_id = data.responsible_clinic_person_id?.id
      data.request_types = data.request_types.map(type => type.id)

      return data
    },

    save() {
      this.budget.clinic_id = this.clinic.id

      if(! this.isValidForm()) {
        this.$toast.error('Por favor, revise os dados antes de salvar.')
        return
      }
      const isLoading = this.$loading.show()

      const data = this.prepareToUpsert()
      if(! this.editMode) {
        this.api.createBudget(data).then( res => {
          this.$emit('created', res.data.budget)
          this.successMessage()
        })
        .catch(error => {
          this.errorMessage(error)
        })
        .finally(() => {
          isLoading.hide()
          this.validated = false
          this.cancel()
        });
      }
      else {
        this.api.updateBudget(data).then( res => {
          this.successMessage()
          this.budget.items = res.data.budget.items
        })
        .catch(error => {
          this.errorMessage(error)
        })
        .finally(() => {
          isLoading.hide()
          this.validated = false
          this.cancel()
        });
      }
    },
  }
}
</script>
<style lang="scss">
.modal-footer{
  border-top: 0 !important;
}
</style>
<style lang="scss" scoped>
.modal-footer {
  border-top: none;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: var(--neutral-200);
  margin-bottom: 20px;
}
.health-plan-placeholder {
  color: #8696AC;
  font-size: 18px;
}
</style>