<template>
    <b-table-simple class="table" responsive borderless fixed>
        <thead>
            <tr>
                <th><Ellipsis>Tipo</Ellipsis></th>
                <th><Ellipsis>Tabela</Ellipsis></th>
                <th><Ellipsis>Código</Ellipsis></th>
                <th><Ellipsis>Descrição</Ellipsis></th>
                <th><Ellipsis>Qtde.</Ellipsis></th>
                <th><Ellipsis>Unid. de medida</Ellipsis></th>
                <th><Ellipsis>Valor</Ellipsis></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) of items" :key="index">
                <td><Ellipsis>{{ cdsOptions.find(cd => cd.value === item.cd)?.label }}</Ellipsis></td>
                <td>{{ item.table_name.value ?? item.table_name }}</td>
                <td>{{ item?.item?.code }}</td>
                <td><Ellipsis>{{ item.item.name }}</Ellipsis></td>
                <td>
                    <b-form-input
                        v-model="item.quantity" 
                        type="number"
                        @input="item.total_value = item.quantity * item.unitary_value"
                    />
                </td>
                <td><Ellipsis>{{ unitMeasures.find(unit => unit.value === item.unit_measurement)?.label || '' }}</Ellipsis></td>
                <td>{{ parseNumberToMoney(item.total_value || item.unitary_value) }}</td>
                <td>
                    <div class="actions">
                        <Copy 
                            v-if="copyExpense && !readonly" 
                            class="icon copy"
                            v-b-tooltip.hover title="Duplicar despesa"
                            @click="copyExpense(item)" 
                        />
                        <Edit 
                            v-if="editExpense" 
                            class="icon edit" 
                            v-b-tooltip.hover title="Ver e editar detalhes da despesa"
                            @click="editExpense(item, index)" 
                        />
                        <Delete 
                            v-if="deleteExpense && !readonly" 
                            v-b-tooltip.hover title="Excluir despesa"
                            @click="confirmDelete(item, index)" 
                            class="icon delete" 
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import cdsOptions from '@/components/Tiss/Data/cds'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        copyExpense: Function,
        editExpense: Function,
        deleteExpense: Function,
        editable: Boolean,
        readonly: Boolean
    },
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),
        Delete: () => import("@/assets/icons/delete.svg"),
        Edit: () => import("@/assets/icons/edit.svg"),
        Copy: () => import('@/assets/icons/copy.svg'),
    },
    data() {
        return {
            cdsOptions,
            unitMeasures,
        }
    },
    methods: {
        parseNumberToMoney,
        async confirmDelete(item, index) {
            const { isConfirmed } = await this.$swal.fire({
                icon: 'question',
                html: `<div class="body-alert">Tem certeza que deseja excluir esta despesa?<br />
                    <span class="mt-1">${item?.item?.name}</span>?<br /><br />
                    Esta ação não poderá ser desfeita.
                </div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            })

            if (isConfirmed) this.deleteExpense(item, index)
        }
    },
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/table.scss';
    .actions {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        gap: .5rem;
    }
    .icon {
        width: 24px;
        height: 24px;
        transform: scale(0.8);
        cursor: pointer;

        &.copy {
            fill: var(--neutral-700);
        }
        &.edit {
            stroke: var(--blue-500);
        }

        &.delete {
            stroke: var(--red-500);
        }
    }
</style>