<template>
  <div id="forms-container" class="attendance-forms-container">
    <Header
      v-if="!customFormEdit"
      :loadingDocs="loadingDocs"
      :docs="docsArray"
      :exams="examsArray"
      :getAttendanceFiles="getAttendanceFiles"
    />
    <FormHeader v-else />

    <AttendanceForms />

    <Certificate
      v-if="attendance?.type === 'ATTENDANCE'"
    />

    <BvAttendance
      v-if="attendance?.type === 'ATTENDANCE' && clinic.type === 'BV'"
      :attendanceId="attendance.id"
    />
    
    <ForwardingDocsEmail
      v-show-feature="'forwarding-email'"
      v-if="attendance?.type === 'ATTENDANCE'"
      :attendance="attendance"
    />

    <ForwardingDocsSms
      v-show-feature="'forwarding-email'"
      v-if="attendance?.type === 'ATTENDANCE'"
      :attendance="attendance"
    />
    
    <FinishActions :noCid="noCid.includes(attendance?.type)"/>
    
    <div v-if="!customFormEdit">
      <AttachmentFilesModal @uploadedFiles="getAllExams" />
      <AppointmentDocsModal :docs="docsArray" />
      <FloatButton
        :loadingDocs="loadingDocs"
        :docs="docsArray"
        :exams="examsArray"
        :getAttendanceFiles="getAttendanceFiles"
      />
    </div>

  </div>
</template>
<script>
import { computed } from 'vue'
import { mapState } from 'vuex';
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'


export default {
  components: {
    AppointmentDocsModal: () => import('@/components/General/AppointmentDocsModal'),
    AttachmentFilesModal: () => import('@/components/Attendance/Forms/Components/AttachmentFilesModal'),
    AttendanceForms: () => import('./AttendanceForms.vue'),
    BvAttendance: () => import('@/components/Attendance/Forms/Components/BvAttendance'),
    Certificate: () => import('@/components/Attendance/Forms/Components/Certificate'),
    FinishActions: () => import('@/components/Attendance/Forms/Components/FinishActions'),
    FloatButton: () => import('@/components/Attendance/Forms/Components/FloatButton'),
    ForwardingDocsEmail: () => import('@/components/Attendance/Forms/Components/ForwardingDocsEmail'),
    ForwardingDocsSms: () => import('@/components/Attendance/Forms/Components/ForwardingDocsSms'),
    FormHeader: () => import('@/components/CustomForm/formHeader'),
    Header: () => import('@/components/Attendance/Forms/Header'),
  },
  metaInfo() {
    return {
      title: `Eyecare - ${this.attendance?.type === 'ATTENDANCE' ? 'Consulta' : 'Exame'}`,
    }
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance,
      customFormEdit: state => state.attendance.customFormEdit,
      selectedForm: state => state.attendance.form.selected,
      anexarArquivos: state => state.attendance.form.anexarArquivos,
    }),
    examsArray() {
      if (this.exams) {
        return Object.values(this.exams)
      }
      return []
    },
    docsArray() {
      if (this.docs) {
        return Object.values(this.docs)
      }
      return []
    },
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      loadingDocs: false,
      docs: [],
      exams: null,
      noCid: ['PRE_CONSULTATION', 'ANESTHETIST_EVOLUTION', 'TEAM_EVOLUTION', 'TEAM_ANNOTATIONS', 'EXAM'],
      antecedentes: [],
      cirurgiasOftalmologicas: [],
      standardRecords: []
    }
  },
  provide() {
    return {
      antecedentes: computed(() => this.antecedentes),
      cirurgiasOftalmologicas: computed(() => this.cirurgiasOftalmologicas),
      standardRecords: computed(() => this.standardRecords)
    }
  },
  async mounted() {
    await this.getAntecedentes()
    await this.getCirurgiasOftalmologicas()
    await this.getStandards()
  },
  methods: {
    async getStandards() {
      const { data } = await this.api.getStandards(this.user.id)
      this.standardRecords = data
    },
    async getAntecedentes() {
      const { data } = await this.api.getAntecedentes()
      this.antecedentes = data.map(antecedente => antecedente.value)
    },
    async getCirurgiasOftalmologicas() {
      const { data } = await this.api.getCirurgiasOftalmologicas()
      this.cirurgiasOftalmologicas = data.map(cirurgias => cirurgias.value)
    },
    getAttendanceFiles() {
      if (!this.attendance?.id) return
      this.loadingDocs = true
      this.api.getAttendanceDocs(this.attendance.id)
      .then(res => {
        this.docs = res.data
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => { this.loadingDocs = false })
    },
    getAllExams() {
      const id = this.attendance?.patient?.id

      if (!id) return

      this.api.getAllExams(id)
        .then(res => {
          this.exams = res.data
        })
        .catch(err => { console.log('ERR getAllExams => ', err)})
    },
  },
  watch: {
    attendance(att) {
      att && this.getAttendanceFiles()
      att && this.getAllExams()
    },
    anexarArquivos: {
      handler (val) {
        val && this.getAttendanceFiles()
        val && this.getAllExams()
      },
      deep: true,
    }
  }
}
</script>
<style lang="scss" scoped>
  .attendance-forms-container {
    width: 100%;
    padding: 16px 16px 150px 16px;
    background: white;
    height: 100%;
    overflow-y: auto;
  }
</style>
