<template>
  <b-modal
    id="add_realized_expenses_modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
  >
    <div class="modal-header">
    <div class="modal-title">Adicionar dados da despesa realizada</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal"/>
      </span>
    </div>

    <div class="around-content">
      <div class="row">
        <div class="col-12">
          <p class="form-title mb-3">Dados da despesa realizada</p>
        </div>

        <div class="col-5">
          <b-form-group>
            <label>Item</label>
            <ItemHealthPlanSimpleSelector 
              :clinicHealthPlanId="clinicHealthPlan.id"
              :planId="null"
              :allowedTypes="['OTHER_EXPENSES']"
              v-model="expenseForm.item"
              @select="setOtherExpenseItem"
            />
          </b-form-group>
          <!-- <SelectOtherExpense 
            required
            label="Cód. do item"
            v-model="expenseForm.item"
            :clinicHealthPlanId="clinicHealthPlan.id"
            :readonly="readonly"
          /> -->
        </div>

        <div class="col-3">
          <b-form-group>
            <label for="cd" class="title-styles">CD </label>
            <multiselect
              v-model="expenseForm.cd"
              placeholder="Selecionar"
              :options="cds"
              track-by="value"
              @select="selectCd"
              label="label"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="true"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown/>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions">
                Nenhuma opção
              </template>
              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div
              v-if="validated && !expenseForm.cd"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group>
            <label for="table_name" class="title-styles">Tabela</label>
            <multiselect
              track-by="value"
              label="label"
              v-model="expenseForm.table_name"
              placeholder="Selecionar"
              :options="tableOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="true"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown/>
                </div>
              </template>
              <template slot="noOptions">Nenhuma opção</template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label for="date" class="title-styles">Data</label>
            <date-picker
              format="DD/MM/YYYY"
              :clearable="false"
              :lang="langDatePicker"
              placeholder="Selecionar"
              v-model="expenseForm.execution_date"
              class="full"
              :disabled="readonly"
            />
          </b-form-group>
        </div>

        <div class="col-2">
          <b-form-group>
            <label for="start_time" class="title-styles">Hora inicial</label>
            <b-input
              id="start_time"
              type="time"
              placeholder="Descrever"
              autocomplete="off"
              v-model="expenseForm.start_time"
              :readonly="readonly"
            />
          </b-form-group>
        </div>

        <div class="col-2">
          <b-form-group>
            <label for="end_time" class="title-styles">Hora final</label>
            <b-input
              id="end_time"
              type="time"
              placeholder="Descrever"
              autocomplete="off"
              v-model="expenseForm.end_time"
              :readonly="readonly"
            />
          </b-form-group>
        </div>

        <div class="col-5">
          <b-form-group>
            <label for="unit_measurement" class="title-styles">
              Unidade de medida
            </label>
            <multiselect
              id="unit_measurement"
              v-model="expenseForm.unit_measurement"
              placeholder="Selecionar"
              :options="unitMeasurementOptions"
              track-by="value"
              label="label"
              :allow-empty="false"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown/>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-90">
                  {{ option.label }}
                </span>
              </template>
              <template slot="noOptions">
                Nenhuma opção
              </template>
              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div
              v-if="validated && !expenseForm.unit_measurement"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label for="quantity" class="title-styles">Quantidade</label>
            <b-form-input 
              v-model="expenseForm.quantity"
              type="number"
              :disabled="readonly"
            />
            <!-- <multiselect
              v-model="expenseForm.quantity"
              :disabled="readonly"
              placeholder="Selecionar"
              :options="quantityOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown/>
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect> -->
            <div
              v-if="validated && !expenseForm.quantity"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label for="decrease_or_increase_factor" class="title-styles">Red/Acresc.</label>
            <b-form-input
              type="number"
              step="0.01"
              min="0"
              placeholder="Descrever"
              autocomplete="off"
              :value="expenseForm.decrease_or_increase_factor.toFixed(2)"
              @change="value => expenseForm.decrease_or_increase_factor = parseFloat(value)"
              debounce="500"
              :readonly="readonly"
            />
            <div
              v-if="validated && !expenseForm.decrease_or_increase_factor"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div v-if="validated && invalidDecreaseOrIncrease" class="custom-invalid-feedback">
              Insira um valor maior que 0
            </div>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label for="unitary_value" class="title-styles">
              Valor Unitário
            </label>
            <money 
              v-model="expenseForm.unitary_value" 
              class="form-control" 
              :readonly="readonly"
            />
            <div
              v-if="validated && !expenseForm.unitary_value"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label for="total_value" class="title-styles">Valor Total</label>
            <money 
              v-model="expenseForm.total_value" 
              class="form-control" 
              readonly
            />
            <div
              v-if="validated && !expenseForm.total_value"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group>
            <label for="anvisa_reg" class="title-styles">
              Registro ANVISA do Material
              <span class="optional">(opcional)</span>
            </label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              v-model="expenseForm.anvisa_registration"
              :readonly="readonly"
            />
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group>
            <label for="maker_reference" class="title-styles">
              Ref. do material no fabricante
              <span class="optional">(opcional)</span>
            </label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              v-model="expenseForm.manufacturer_reference"
              :readonly="readonly"
            />
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group>
            <label for="authorization_number" class="title-styles">
              Nº Autorização de Funcionamento
              <span class="optional">(opcional)</span>
            </label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              v-model="expenseForm.authorization_number"
              :readonly="readonly"
            />
          </b-form-group>
        </div>

        <div class="col-12 mt-3">
          <b-form-group>
            <label for="description" class="title-styles mr-1">Descrição</label>
            <span class="optional">(opcional)</span>
            <b-form-textarea
              placeholder="Descrever"
              rows="2"
              max-rows="5"
              autocomplete="off"
              v-model="expenseForm.description"
              :readonly="readonly"
            />
          </b-form-group>
        </div>

        <b-alert :show="expenseForm.matMed" variant="warning">
          <div>Utilizando valores MatMed</div>
          <small>
            <!-- {{ expenseForm.matMed.type === 'update' ? 
              `Atualização de ${expenseForm.matMed.start_in}` : 
              `Excessão de ${expenseForm.matMed.created_at}` }} -->
          </small>
        </b-alert>

        <div class="col-12">
          <hr id="hr" />
        </div>
      </div>

      <div 
        v-if="!readonly"
        class="wrapper-button"
      >
        <b-button
          v-if="Object.keys(item).length !== 0"
          variant="primary"
          size="lg"
          @click="updateExpense"
        >
          Editar despesa realizada
        </b-button>
        <b-button
          v-else
          variant="primary"
          size="lg"
          @click="addExpense"
        >
          Salvar despesa realizada
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import apiTable from '@tables/api'
import tableOptions from '@/components/Tiss/Data/tables'

export default {
  name: 'ExpensesFormModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    // SelectOtherExpense: () => import('../components/SelectOtherExpense'),
    ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    item: Object,
    index: Number,
    cds: Array,
    datetime: Object,
    unitMeasurementOptions: Array,
    clinicHealthPlan: Object,
    itemsKey: {
      type: String,
      default: 'items'
    },
    readonly: Boolean
  },
  data() {
    return {
      expenseForm: this.getDefaultForm(),
      validated: false,
      errors: {},
      editMode: false,
      quantityOptions: Array.from({length: 10}, (_, i) => i + 1),
      itemOptions: [],
      tableOptions,
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
    }
  },
  methods: {
    onShow() {
      if (this.datetime && Object.keys(this.datetime).length) {
        this.expenseForm.execution_date = this.datetime.execution_date ? this.moment(this.datetime.execution_date).toDate() : null
        this.expenseForm.start_time = this.datetime.start_time
        this.expenseForm.end_time = this.datetime.end_time
      }
    },
    setOtherExpenseItem(item) {
      this.expenseForm.item = item
    },
    selectCd(value) {
      this.itemOptions = []
      this.expenseForm.item = null
      this.itemOptions = []
      switch (value.value){
        case '01':
        case '05':
        case '07':
          this.expenseForm.table_name = 18
          break;
        case '02':
          this.expenseForm.table_name = 20
          break;
        case '03':
        case '08':
          this.expenseForm.table_name = 19
          break;
        default:
          break;
        }
    },
    getDefaultForm() {
      return {
        cd: null,
        execution_date: null,
        start_time: null,
        end_time: null,
        quantity: null,
        unit_measurement: null,
        decrease_or_increase_factor: 1.00,
        unitary_value: null,
        total_value: null,
        anvisa_registration: null,
        manufacturer_reference: null,
        authorization_number: null,
        description: null,
        table_name: null,
        item: null
      }
    },
    isValidForm() {
      this.validated = true
      return (
        this.expenseForm.tuss_item_id &&
        !this.invalidDecreaseOrIncrease &&
        this.expenseForm.cd &&
        this.expenseForm.unit_measurement &&
        this.expenseForm.quantity &&
        this.expenseForm.decrease_or_increase_factor &&
        this.expenseForm.unitary_value &&
        this.expenseForm.total_value
      )
    },
    getSelectedValue(){
      if(typeof this.expenseForm.cd === 'object') this.expenseForm.cd = this.expenseForm.cd.value
      if(typeof this.expenseForm.unit_measurement === 'object') this.expenseForm.unit_measurement = this.expenseForm.unit_measurement.value
      if(typeof this.expenseForm.table_name === 'object') this.expenseForm.table_name = this.expenseForm.table_name.value
    },
    addExpense() {
      if (!this.isValidForm()) return

      this.getSelectedValue()

      this.form[this.itemsKey].push(this.expenseForm)
      this.closeModal()
    },
    updateExpense() {
      if (!this.isValidForm()) return
      this.getSelectedValue()
      this.form[this.itemsKey][this.index].item = this.expenseForm
      this.$set(this.form[this.itemsKey], this.index, this.expenseForm)
      this.closeModal()
    },
    closeModal() {
      this.itemOptions = []
      this.validated = false
      this.expenseForm = this.getDefaultForm()
      this.$emit('onHide')
      this.$bvModal.hide('add_realized_expenses_modal')
    }
  },
  computed: {
    invalidDecreaseOrIncrease() {
      return this.expenseForm.decrease_or_increase_factor <= 0
    }
  },
  watch: {
    item: {
      handler(value) {
        if (Object.keys(value).length) {
          this.expenseForm = {
            ...this.expenseForm,
            ...value
          }
          this.expenseForm.tuss_item_id = value.other_expense_props.item.id
          this.expenseForm.execution_date = this.moment(this.expenseForm.execution_date).toDate()
          this.expenseForm.cd = this.cds.find(option => option.value === value.other_expense_props.cd)
          const item = value.other_expense_props.item
          item.cd = value.other_expense_props.cd
          item.execution_date = value.other_expense_props.execution_date
          item.start_time = value.other_expense_props.start_time
          item.end_time = value.other_expense_props.end_time
          item.quantity = value.other_expense_props.quantity
          item.decrease_or_increase_factor = value.other_expense_props.decrease_or_increase_factor
          item.unitary_value = value.other_expense_props.unitary_value
          item.total_value = value.other_expense_props.total_value
          item.anvisa_registration = value.other_expense_props.anvisa_registration
          item.manufacturer_reference = value.other_expense_props.manufacturer_reference
          item.authorization_number = value.other_expense_props.authorization_number
          item.description = value.other_expense_props.description
          item.table_name = value.other_expense_props.table_name
          item.unit_measurement = this.unitMeasurementOptions.find(option => option.value === value.other_expense_props.unit_measurement)
          this.expenseForm.item = item
          this.expenseForm.unit_measurement = this.unitMeasurementOptions.find(option => option.value === value.other_expense_props.unit_measurement)
        }
      },
      immediate: true
    },
    'expenseForm.item': {
      async handler(item) {
        if (item && Object.keys(item).length) {
          this.expenseForm.cd = this.cds.find(option => option.value === item.other_expense_props.cd)
          this.expenseForm.table_name = this.tableOptions.find(option => option.value === item.other_expense_props.table_number)
          this.expenseForm.tuss_item_id = item.other_expense_props.id
          this.expenseForm.anvisa_registration = item.other_expense_props.anvisa_registration ? item.other_expense_props.anvisa_registration : null
          this.expenseForm.model = item.other_expense_props.model ? item.other_expense_props.model : null
          this.expenseForm.item = item
          this.expenseForm.quantity = this.expenseForm.quantity ?? 1
          this.expenseForm.manufacturer_reference = item.other_expense_props.manufacturer_reference ? item.other_expense_props.manufacturer_reference : null
          this.expenseForm.authorization_number = item.other_expense_props.authorization_number ? item.other_expense_props.authorization_number : null
          this.expenseForm.unitary_value = this.expenseForm.unitary_value ? this.expenseForm.unitary_value : item.other_expense_props.unitary_value 
          this.expenseForm.unit_measurement = this.unitMeasurementOptions.find(option => parseInt(option?.value) === parseInt(item.other_expense_props.unit_measurement))
          this.expenseForm.decrease_or_increase_factor = this.expenseForm.decrease_or_increase_factor ? this.expenseForm.decrease_or_increase_factor : item.other_expense_props.decrease_or_increase_factor  || 1

          if (item.other_expense_props.material_medicine) {

            const tableId = item.other_expense_props.material_medicine.table_id;
            const clinicHealthPlanId = this.clinicHealthPlan.id;
            const { data } = await apiTable.getClinicHealthPlanTableFromTable(clinicHealthPlanId, tableId);
            const clinicHealthPlanTable = data
            const type = item.other_expense_props.material_medicine.type.toLowerCase();
            const exception = item.other_expense_props.material_medicine[type].exceptions[0];
            const updates = item.other_expense_props.material_medicine[type].updates;

            if (exception) {
              if (exception.clinic_health_plan_id === clinicHealthPlanTable.clinic_health_plan_id ) {
                const percent = exception.increase_decrease ? ((exception.increase_decrease + clinicHealthPlanTable.increase_decrease) / 100) : 1
                this.expenseForm.unitary_value = exception.unitary_value + (percent * exception.unitary_value)
                this.expenseForm.matMed = { ...exception, type: 'exception' }
              }
            } else  if (updates.length && type === 'simpro') {
              const update = updates[0]
              const pc = clinicHealthPlanTable.price_formation === 'PMC' ? 'pc_em_ven' : 'pc_em_fab'
              const value = update[pc] || update.pc_em_ven || update.pc_em_fab
              const percent = clinicHealthPlanTable.increase_decrease > 0 ? (clinicHealthPlanTable.increase_decrease / 100) : 0
              this.expenseForm.unitary_value = value + (percent * value)
              this.expenseForm.matMed = { ...update, type: 'update' }
            } else if (updates.length && type === 'brasindice') {
              const update = updates.find(el => el.flag_preco.includes(clinicHealthPlanTable.price_formation));
              if (!update) {
                this.expenseForm.unitary_value = 0
                return
              };
              const pc = clinicHealthPlanTable.price_formation === 'PMC' ? 'preco_apres' : 'preco_frac'
              const value = update[pc] || update.preco_apres || update.preco_frac
              const percent = clinicHealthPlanTable.increase_decrease ? (clinicHealthPlanTable.increase_decrease / 100) : 1
              this.expenseForm.unitary_value = value + (percent * value)
              this.expenseForm.matMed = { ...update, type: 'update' }
            }
          }

          // const config = item.other_expense_props.tiss_material_medicine_config;
          
          // if (item.other_expense_props?.exception){
          //   const unitMeasurement = item.other_expense_props.exception.unit_measurement ? this.unitMeasurementOptions.find(option => option?.label.includes(item.other_expense_props.exception.unit_measurement)) : null
          //   const sumInflatorDeflator = config?.inflator_deflator + item.other_expense_props.exception.decrease_or_increase_factor
          //   const validatedValue = item.other_expense_props.exception.unitary_value ? (item.other_expense_props.exception.unitary_value * ((sumInflatorDeflator/100)+1)) : null
            
          //   this.expenseForm.unitary_value = validatedValue ?? null
          //   this.expenseForm.unit_measurement = unitMeasurement ?? null
          // } else if (config?.table === "brasindice") {
          //   const validatedValue = (!item?.mat_med?.pmc_price_fraction || item?.mat_med?.pmc_price_fraction === "0.00") ? item?.mat_med?.pfb_price_fraction : item?.mat_med?.pmc_price_fraction
          //   this.expenseForm.unitary_value = config?.price_formation === "Preço Máximo do Consumidor (PMC)" ? (validatedValue * ((config?.inflator_deflator/100)+1)) : (item?.mat_med?.pfb_price_fraction  * ((config?.inflator_deflator/100)+1));
          //   this.expenseForm.unit_measurement = item?.unit_measurement ? this.unitMeasurementOptions.find(option => parseInt(option?.value) === parseInt(item?.unit_measurement)) : item?.unit_measurement 
          // } else if (config?.table === "simpro") {
          //   const validatedValue = (!item?.mat_med?.price_ven || item?.mat_med?.price_ven === "0,000" || item?.mat_med?.price_ven === "0") ? item?.mat_med?.price_fab : item?.mat_med?.price_ven
          //   const unitMeasurement = item?.mat_med?.fraction ? this.unitMeasurementOptions.find(option => option?.label.includes(item?.mat_med?.fraction)) : null
            
          //   this.expenseForm.unitary_value = config?.price_formation === "Preço Máximo do Consumidor (PMC)" ?  (validatedValue * ((config?.inflator_deflator/100)+1)) : (item?.mat_med?.price_fab * ((config?.inflator_deflator/100)+1));
          //   this.expenseForm.unit_measurement = unitMeasurement ?? null
          // } else if (!config?.table){           
          //   this.expenseForm.unitary_value = item?.unitary_value
          //   this.expenseForm.unit_measurement = typeof this.expenseForm.unit_measurement !== 'object' && item?.unit_measurement ? this.unitMeasurementOptions.find(option => parseInt(option?.value) === parseInt(item?.unit_measurement)) : item?.unit_measurement 
          // }
        }
      },
      immediate: true
    },
    'expenseForm.unitary_value': function(value) {
      const quantity = this.expenseForm.quantity ? this.expenseForm.quantity : 1
      this.expenseForm.total_value = (value * this.expenseForm.decrease_or_increase_factor) * quantity
    },
    'expenseForm.quantity': function(value) {
      if (value) {
        this.expenseForm.total_value = value * this.expenseForm.unitary_value * this.expenseForm.decrease_or_increase_factor
      }
    },
    'expenseForm.decrease_or_increase_factor'(value) {
      if (value) {
        this.expenseForm.total_value = (this.expenseForm.unitary_value * value) * this.expenseForm.quantity
      }
    }
  }
}
</script>

<style lang="scss">
#add_realized_expenses_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        .multiselect__single {
          color: var(--type-active) !important;
        }
      }
    }

    .wh-button {
      color: var(--blue-500);
    }

    .checkbox {
      color: gray;
      font-size: 15px;
      text-align: right;
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .width-80 {
    width: 80%;
  }

  .width-90 {
    width: 90%;
  }
  .title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin-bottom: 4px !important;
  }

  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  #hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .optional {
    color: var(--neutral-500);
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 150%;
  }
}
</style>
