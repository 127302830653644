<template>
  <section id="tiss-health-plan">
    <div class="config">
      <div  class="d-flex">
        <h3>Configurações de layout</h3>
        <b-button
          class="ml-auto"
          variant="primary"
          @click="newLayoutModal()"
          :disabled="loading"
          lg
        >
          Novo layout
        </b-button>
      </div>
      <Filters class="mb-3" :filters="filters"/>
      <div v-if="loading">
        <b-skeleton type="input" />
        <b-skeleton type="input" class="mt-2 mb-2" />
        <b-skeleton type="input" />
      </div>
      <div v-else>
        <Table 
          :layouts="layouts"
          :message="message"
          @copy="copyLayout"
          @edit="editLayout"
          @delete="deleteModal"
          withActions
          withDelete
        />
        <div class="pagination-position" v-show="total">
          <b-pagination
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            size="sm"
          />
        </div>
      </div>
    </div>
    <LayoutModal 
      :layout="layout"
      :clinicHealthPlan="clinicHealthPlan"
      @save="onSave"
    />
  </section>
</template>

<script>
export default {
  name: 'Settings',
  components: {
    Filters: () => import('@/components/Tiss/Generic/Filters/LayoutsFilter.vue'),
    Table: () => import('@/components/Tiss/Table/LayoutsTable.vue'),
    LayoutModal: () => import('@/components/Tiss/Modal/LayoutModal.vue'),
  },
  props: {
    clinicHealthPlan: Object,
  },
  data() {
    return {
      layout: null,
      layouts: [],
      message: undefined,

      filters: {
        name: null,
        type: ['Todos', 'Despesas', 'Procedimentos'],
      },

      page: 1,
      total: 0,
      perPage: 0,
      loading: true,
    }
  },
  async created() {
    await this.listLayouts()
  },
  methods: {
    newLayoutModal() {
      this.layout = null
      this.$bvModal.show('layout-modal')
    },
    copyLayout(index) {
      this.layout = JSON.parse(JSON.stringify(this.layouts[index]))
      this.$set(this.layout, 'id', null)
      this.$set(this.layout, 'name', this.layout.name + ' (Cópia)')
      this.$bvModal.show('layout-modal')
    },
    editLayout(index) {
      this.layout = JSON.parse(JSON.stringify(this.layouts[index]))
      this.$bvModal.show('layout-modal')
    },
    deleteModal(index) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          <p>Tem certeza que deseja excluir o layout <b>${this.layouts[index].name}</b>?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.deleteLayout(index)
        }
      })
    },
    async deleteLayout(index) {
      const layout = this.layouts[index]
      try {
        this.layouts.splice(index, 1)
        await this.api.deleteLayout(layout.id)
        this.$toast.success('Layout excluído com sucesso!')
      } catch (error) {
        this.layouts.unshift(layout)
        this.$toast.error('Não foi possível excluir o layout, contate o suporte se o erro persistir.')
      }
    },
    async onSave(layout) {
      await this.listLayouts();
    },
    async listLayouts() {
      try {
        this.loading = true
        const { data } = await this.api.getLayouts(this.clinicHealthPlan?.id, this.page, this.filters)
        this.layouts = data.data
        this.total = data.total
        this.perPage = data.per_page

      } catch (error) {
        this.message = 'Não foi possível carregar os layouts, contate o suporte se o erro persistir.'
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    filters: {
      async handler() {
        await this.listLayouts()
      },
      deep: true,
    },
    async page() {
      await this.listLayouts()
    }
  }
}
</script>

<style lang="scss" src="@/components/Tiss/tissHealthPlan.scss" />
