<template>
    <main>

      <div v-if="loading && !requiredFields.id">
        <b-skeleton width="100%" height="400px" />
      </div>
      <section v-else class="required-fields">
        <div class="required-fields__title-container">
          <span class="required-fields-title">Configurações</span>
        </div>
        <div>
          <toggle-button
            :sync="true"
            :height="24"
            class="mr-2"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
            v-model="requiredFields.guide_conference"
          />
          <span>Conferência de guias <InfoIcon id="info-guias" class="cursor-pointer" /></span>
          <b-tooltip v-if="!loading" target="info-guias" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
            Ao habilitar a “Conferência de guias” todas as guias terão um campo para checagem na aba “Relatório de guias” e na aba “Lotes a enviar” terão a porcentagem de guias conferidas.
          </b-tooltip>
        </div>
        <ul class="nav nav-pills">
          <li class="nav-item" v-for="(item, findx) in fields" :key="findx">
            <a 
              href="#" 
              class="nav-link" 
              :class="{ active: activeItem === item.type }"
              @click="setActiveItem(item.type)">
              {{ item.type }}
            </a>
          </li>
        </ul>
        <div class="" v-for="(typeField, findx) in fields" :key="findx">
          <div class="required-fields__category" v-if="activeItem === typeField.type">
            <span class="required-fields__category__title">GUIA {{ typeField.type }} <InfoIcon id="info-badge" class="cursor-pointer" /></span>
            <b-tooltip v-if="!loading" target="info-badge" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
              Selecione os campos que deverão ser obrigatórios o preenchimento para gerar as guias {{ typeField.type }} relacionadas ao convênio SUS. Os campos não selecionados serão opcionais.
            </b-tooltip>
            
            <div class="row required-fields__category__items"  v-if="isInitializedFields">
              <div class="col-12 my-2" v-for="(group, g_indx) in typeField.fields" :key="typeField.type + '-group-' + g_indx">
                <p class="group mb-2">{{ group.group }}</p>
                <div class="row">
                  <div class="col-3 my-1 item" v-for="(field, gf_indx) in group.fields" :key="typeField.type + '-field-' + gf_indx">
                    <input 
                      class="form-control" 
                      id="category-fieldname" 
                      name="category-fieldname" 
                      type="checkbox" 
                      v-model="selectedFields[typeField.type][group.group][field.field]"
                      :value="field.field" 
                    />
                    <span>{{ field.label }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import api from '@/modules/sus/manageGuides/api'
    import { getCurrentClinic } from '@/utils/localStorageManager'
    export default {
        components: {
            InfoIcon: () => import('@/assets/icons/info-icon.svg')
        },
        computed: {
            ...mapState('manageGuidesSus', ['requiredFields']),            
        },
        data() {
            return {
                loading: false,
                isInitializedFields: false,
                clinic: getCurrentClinic(),
                activeItem: 'AIH',
                fields: [
                  {
                    "type": "AIH",
                    "fields": [
                      {
                        group: 'Identificação do Estabelecimento de Saúde',
                        fields: [
                          { label: 'Nome do estabelecimento solicitante', field: 'requesting_establishment_name', default: true },
                          { label: 'CNES (solicitante)', field: 'requesting_establishment_cnes', default: true },
                          { label: 'Nome do estabelecimento executante', field: 'executing_establishment_name', default: true },
                          { label: 'Cód. município', field: 'executing_establishment_ibge_code', default: true },
                          { label: 'CNES (executante)', field: 'executing_establishment_cnes', default: true },
                        ]
                      },
                      {
                        group: 'Informações da AIH',
                        fields: [
                          { label: 'Identificação', field: 'identification', default: true },
                          { label: 'Número da AIH', field: 'aih_number', default: true },
                          { label: 'Modalidade da AIH', field: 'modality', default: true },
                          // { label: 'Competência', field: 'competency', default: true },
                        ]
                      },
                      {
                        group: 'Diretor do estabelecimento executante',
                        fields: [
                          { label: 'Nome do Diretor Clínico do estabelecimento', field: 'name_director_establishment', default: true },
                          { label: 'CPF do Diretor', field: 'cpf_director_establishment', default: true },
                          { label: 'N° Cons. de Classe', field: 'director_council_number', default: false },
                          { label: 'Nome do Médico responsável', field: 'executing_doctor', default: true },
                          { label: 'Indicador do profissional', field: 'indicator_executing_doctor', default: true },
                          { label: 'Número do documento', field: 'number_executing_doctor', default: true },
                        ]
                      },
                      {
                        group: 'Identificação do Paciente',
                        fields: [
                          { label: 'Nome do paciente', field: 'patient_name', default: true },
                          { label: 'CPF do paciente', field: 'patient_cpf', default: true },
                          { label: 'CNS', field: 'professional_cns', default: true },
                          { label: 'Cartão Nacional de Saúde (CNS)', field: 'patient_cns', default: true },
                          { label: 'Data de nascimento', field: 'patient_birthday', default: true },
                          { label: 'Nacionalidade', field: 'patient_nationality', default: true },
                          { label: 'Sexo', field: 'patient_gender', default: true },
                          { label: 'Raça/Cor', field: 'patient_ethnicity', default: true },
                          { label: 'Etnia', field: 'patient_ancestry', default: false },
                          { label: 'Nome da Mãe', field: 'patient_mother_name', default: true },
                          { label: 'Telefone (mãe)', field: 'patient_mother_phone', default: false },
                          { label: 'Nome do responsável', field: 'patient_responsible_name', default: true },
                          { label: 'Telefone (responsável)', field: 'patient_responsible_phone', default: false },
                          { label: 'CEP', field: 'patient_address_zipcode', default: true },
                          { label: 'Cód. IBGE Município', field: 'patient_address_ibge_code', default: true },
                          { label: 'Tipo Logradouro', field: 'patient_street_type', default: true },
                          { label: 'Logradouro', field: 'patient_address', default: true },
                          { label: 'Número', field: 'patient_address_number', default: true },
                          { label: 'Complemento', field: 'patient_address_complement', default: false },
                          { label: 'Bairro', field: 'patient_address_neighborhood', default: true },
                          { label: 'Município', field: 'patient_address_city', default: true },
                          { label: 'Estado', field: 'patient_address_state', default: true },
                        ]
                      },
                      {
                        group: 'Justificativa da Internação',
                        fields: [
                          { label: 'Principais sinais e sintomas clínicos', field: 'main_clinical_features', default: false },
                          { label: 'Condições que justificam a internação', field: 'conditions_justifying_admission', default: false },
                          { label: 'Principais resultados de provas diagnósticas', field: 'main_results_diagnostic_tests', default: false},
                          { label: 'Diagnóstico inicial', field: 'initial_diagnosis', default: false },
                          { label: 'CID 10 Principal', field: 'cid_main_aih', default: true },
                          { label: 'CID 10 secundário', field: 'cid_2', default: false },
                          { label: 'CID 10 causas associados', field: 'cid10_associated_causes', default: false },
                          { label: 'N° notific. compulsória', field: 'compulsory_reporting', default: false },
                        ]
                      },
                      {
                        group: 'Procedimento Solicitado',
                        fields: [
                          { label: 'Especialidade do leito',field: 'bed_specialty',default: true },
                          { label: 'Data de internação',field: 'date_admission',default: true },
                          { label: 'Data de saída',field: 'exit_date',default: true },
                          { label: 'Motivo saída/permanência',field: 'exit_stay_reason',default: true },
                          { label: 'Mudança de procedimento',field: 'change_procedure',default: true },
                          { label: 'Cód. procedimento',field: 'procedure_item',default: true },
                          { label: 'Caráter de internação',field: 'character_hospitalization',default: true },
                          { label: 'Clínica',field: 'clinic_requested_procedure',default: false },
                          { label: 'Leito complementar',field: 'supplementary_bed',default: false },
                          { label: 'Equipamentos requeridos para internação',field: 'equipment_required_hospitalization',default: false },
                          { label: 'Quantidade', field: 'quantity', default: false},
                          { label: 'Valor Unitário', field: 'procedure_unitary_value', default: false}
                        ]
                      },
                      {
                        group: 'Procedimento Secundário',
                        fields: [
                          { label: 'Indicador do Profissional (CPF ou CNS)',field: 'professional_indicator',default: true },
                          { label: 'Número do documento do Profissional',field: 'professional_document_number',default: true },
                          { label: 'CBO do Profissional',field: 'professional_cbo',default: true },
                          { label: 'Indicador da Equipe',field: 'team_indicator',default: true },
                          { label: 'Indicador do Prestador de Serviço',field: 'service_provider_indicator',default: true },
                          { label: 'Identificação do Prestador do Serviço',field: 'identification_service_provider',default: true },
                          { label: 'Indicador Documento do Executor',field: 'executor_provider_indicator',default: true },
                          { label: 'Documento do executor',field: 'executor_document',default: true },
                          { label: 'Código do Procedimento',field: 'procedure_item',default: true },
                          { label: 'Quantidade de Procedimentos',field: 'quantity',default: true },
                        ]
                      },
                      {
                        group: 'Órteses, Próteses e/ou Materiais Especiais (OPM)',
                        fields: [
                          { label: 'Código do OPM',field: 'opme_code',default: true },
                          { label: 'Registro na ANVISA',field: 'registration_anvisa',default: true },
                          { label: 'Número de Série',field: 'serial_number',default: true },
                          { label: 'Lote do Produto',field: 'product_batch',default: true },
                          { label: 'Número da Nota Fiscal',field: 'invoice_number',default: true },
                          { label: 'CNPJ do Fornecedor',field: 'supplier_cnpj',default: true },
                          { label: 'CNPJ do Fabricante',field: 'manufacturer_cnpj',default: true },
                        ]
                      },
                      {
                        group: 'Profissional Solicitante',
                        fields: [
                          { label: 'Nome do profissional solicitante',field: 'name_requesting_professional',default: true },
                          { label: 'Tipo de Documento profissional solicitante',field: 'indicator_requesting_professional',default: true },
                          { label: 'Num. Documento profissional solicitante',field: 'document_requesting_professional',default: true },
                          { label: 'N. Cons. de Classe',field: 'requesting_council_number',default: false },
                          { label: 'Data da solicitação',field: 'date_request',default: true },
                          { label: 'Data desejada p/internação',field: 'desired_date_admission',default: false },
                        ]
                      },
                      {
                        group: 'Acidentes ou violências (Preencher em causas externas)',
                        fields: [
                          { label: 'Tipo do acidente',field: 'type_accident',default: false},
                          { label: 'CNPJ da seguradora',field: 'insurance_company_cnpj',default: false},
                          { label: 'N. do bilhete',field: 'ticket_number',default: false},
                          { label: 'Série',field: 'serie',default: false},
                          { label: 'Vinculo com previdência',field: 'link_social_security',default: false},
                          { label: 'CNPJ da empresa',field: 'company_cnpj',default: false},
                          { label: 'CNAE da empresa',field: 'cnae_company',default: false},
                          { label: 'CBOR',field: 'cbor_company', default: false},
                        ]
                      },   
                      {
                        group: 'Autorização',
                        fields: [
                          { label: 'Nome do profissional autorizador',field: 'authorizing_establishment_name',default: true},
                          { label: 'Indicador do profissional',field: 'indicator_authorizing_professional',default: true},
                          { label: 'N° do documento do profissional',field: 'document_authorizing_professional',default: true},
                          { label: 'Data da autorização',field: 'authorization_date',default: true},
                          { label: 'Cód. Orgão Emissor',field: 'issuing_body_code',default: true},
                          { label: 'N° Cons. de Classe',field: 'authorizing_council_number',default: false},
                        ]
                      }, 
                    ],
                  },
                  {
                    "type": "APAC",
                    "fields": [
                      {
                        group: 'Tipo de APAC',
                        fields: [
                          { label: 'Tipo de APAC', field: 'type_apac', default: true },
                          { label: 'Número da APAC anterior', field: 'previous_apac_number', default: false },
                          // { label: 'Competência', field: 'competency', default: true },
                        ]
                      },
                      {
                        group: 'Identificação do estabelecimento de saúde (Solicitante)',
                        fields: [
                          { label: 'Nome', field: 'requesting_establishment_name', default: true },
                          { label: 'Número da APAC anterior', field: 'previous_apac_number', default: false },
                          { label: 'Competência', field: 'competency', default: true },
                        ]
                      },
                      {
                        group: 'Identificação do Paciente',
                        fields: [
                          { label: 'Nome', field: 'requesting_establishment_name', default: true },
                          { label: 'Nome do estabelecimento de saúde', field: 'name_establishment', default: true },
                          { label: 'CNES', field: 'cnaes_code', default: true },
                          { label: 'Nome do paciente', field: 'patient_name', default: true },
                          { label: 'CPF do paciente', field: 'patient_cpf', default: true },
                          { label: 'Cartão Nacional de Saúde (CNS)', field: 'patient_cns', default: true },
                          { label: 'N° da solicitação', field: 'request_number', default: true },
                          { label: 'Data de nascimento', field: 'patient_birthday', default: true },
                          { label: 'Nacionalidade', field: 'patient_nationality', default: true },
                          { label: 'Sexo', field: 'patient_gender', default: true },
                          { label: 'Raça/Cor', field: 'patient_ethnicity', default: false },
                          { label: 'Etnia', field: 'patient_ancestry', default: true },
                          { label: 'Nome da Mãe', field: 'patient_mother_name', default: true },
                          { label: 'Telefone (mãe)', field: 'patient_mother_phone', default: true },
                          { label: 'Nome do responsável', field: 'patient_responsible_name', default: false },
                          { label: 'Telefone (responsável)', field: 'patient_responsible_phone', default: false },
                          { label: 'CEP', field: 'patient_address_zipcode', default: true },
                          { label: 'Cód. IBGE Município', field: 'patient_address_ibge_code', default: false },
                          { label: 'Tipo Logradouro', field: 'patient_street_type', default: true },
                          { label: 'Logradouro', field: 'patient_address', default: true },
                          { label: 'Número', field: 'patient_address_number', default: true },
                          { label: 'Complemento', field: 'patient_address_complement', default: false },
                          { label: 'Bairro', field: 'patient_address_neighborhood', default: true },
                          { label: 'Município', field: 'patient_address_city', default: true },
                          { label: 'Estado', field: 'patient_address_state', default: true },
                        ]
                      },
                      {
                        group: 'Justificativa do(s) procedimento(s) solicitado(s)',
                        fields: [
                          { label: 'CID 10 Principal', field: 'cid_main_apac', default: false },
                          { label: 'CID 10 secundário', field: 'cid_secondary_apac', default: false },
                          { label: 'Motivo de saída/permanência', field: 'reason_leaving_staying_apac', default: false },
                          { label: 'Data de saída', field: 'exit_date', default: false },
                          { label: 'Caráter de atendimento', field: 'character_service_apac', default: true },
                          { label: 'Tipo de atendimento', field: 'apac_type_service_id', default: true },
                          { label: 'Observações', field: 'diagnosis_apac', default: false },
                          { label: 'Descrição do diagnóstico', field: 'observations_apac', default: false }
                        ]
                      },
                      {
                        group: 'Solicitação',
                        fields: [
                          { label: 'Nome do profissional', field: 'professional_name_apac', default: false },
                          { label: 'Data de solicitação', field: 'request_date_apac', default: false },
                          { label: 'Cartão Nacional de Saúde (CNS)', field: 'requesting_professional_cns', default: true },
                        ]
                      },
                      {
                        group: 'Autorização',
                        fields: [
                          { label: 'Nome do profissional autorizador', field: 'issuer_apac', default: false },
                          { label: 'Cód. Orgão Emissor', field: 'national_authorizer_card_apac', default: true },
                          { label: 'Cartão Nacional de Saúde (CNS)', field: 'patient_cns', default: true },
                          { label: 'Data de autorização', field: 'authorization_date_apac', default: false },
                          { label: 'N° da autorização (APAC)', field: 'authorization_apac', default: true },
                          { label: 'Período de validade da APAC (inicio)', field: 'validity_period_apac', default: false },
                          { label: 'Período de validade da APAC (fim) ', field: 'validity_period_end', default: false },
                        ]
                      },
                      {
                        group: 'Procedimento realizado',
                        fields: [
                          { label: 'Data de atendimento', field: 'attendance_date', default: true },
                          { label: 'Cód do Procedimento', field: 'procedure_item', default: true },
                          { label: 'Quantidade', field: 'quantity', default: true },
                          { label: 'Valor Unitário', field: 'unitary_value', default: false },
                        ]
                      },
                      {
                        group: 'Identificação do estabelecimento de saúde (Executante)',
                        fields: [
                          { label: 'Nome fantasia do estabelecimento de saúde executante', field: 'fantasy_name_apac', default: false },
                          { label: 'CNES', field: 'cnes_executant_apac', default: false },
                          { label: 'CNS Médico Executante', field: 'cns_executing_doctor_apac', default: true },
                          { label: 'CBO Médico Executante', field: 'cbo_executing_doctor_apac', default: true },
                        ]
                      },
                    ],
                  },
                  {
                    "type": "BPA",
                    "fields": [
                      {
                        group: 'Identificação do estabelecimento de saúde (Solicitante)',
                        fields: [
                          { label: 'Nome do estabelecimento de saúde', field: 'name_establishment', default: true },
                          { label: 'CNES', field: 'cnaes_code', default: true },
                        ]
                      },
                      {
                        group: 'Identificação do Profissional',
                        fields: [
                          // { label: 'Cartão Nacional de Saúde (CNS)', field: 'professional_cns', default: true },
                          { label: 'Nome do profissional', field: 'professional_name', default: true },
                          { label: 'CBO', field: 'professional_cbo', default: true },
                          // { label: 'Ano/Mês', field: 'year_month', default: true },
                          { label: 'Equipe', field: 'team', default: true },
                          { label: 'Folha', field: 'sheet', default: false },
                        ]
                      },
                      {
                        group: 'Identificação do Paciente (Sequência 1)',
                        fields: [
                          { label: 'Cartão Nacional de Saúde (CNS)', field: 'professional_cns', default: true },
                          { label: 'Nome do paciente', field: 'patient_name', default: false },
                          { label: 'Sexo', field: 'patient_gender', default: true },
                          { label: 'Data de nascimento', field: 'date_birth_bpa', default: false },
                          { label: 'Nacionalidade', field: 'nationality_bpa', default: true },
                          { label: 'Raça/Cor', field: 'race_color_bpa', default: true },
                          { label: 'Etnia', field: 'ethnicity_bpa', default: false },
                          { label: 'CEP', field: 'cep_bpa', default: true },
                          { label: 'Cód. IBGE Município', field: 'ibge_bpa', default: false },
                          { label: 'Tipo Logradouro', field: 'street_code_bpa', default: false },
                          { label: 'Logradouro', field: 'address_bpa', default: false },
                          { label: 'Número', field: 'number_bpa', default: false },
                          { label: 'Complemento', field: 'complement_bpa', default: false },
                          { label: 'Bairro', field: 'neighborhood_bpa', default: false },
                          { label: 'Município', field: 'patient_address_city', default: false },
                          { label: 'Estado', field: 'state_bpa', default: false },
                          { label: 'Telefone de contato', field: 'contact_telephone_bpa', default: false },
                          { label: 'E-mail', field: 'e_mail_bpa', default: false },
                        ]
                      },
                      {
                        group: 'Procedimento realizado',
                        fields: [
                          { label: 'Data de atendimento', field: 'attendance_date', default: true },
                          { label: 'Cód do Procedimento', field: 'procedure_item', default: true },
                          { label: 'Quantidade', field: 'procedure_quantity', default: true },
                          { label: 'Serviço', field: 'procedure_service', default: false },
                          { label: 'Class', field: 'procedure_class', default: false },
                          { label: 'Valor Unitário', field: 'unitary_value', default: false },
                          { label: 'CID', field: 'procedure_cid', default: true },
                          { label: 'Caráter de atendimento', field: 'procedure_attendance_character', default: false },
                          { label: 'Nº da autorização', field: 'authorization_number', default: false },
                        ]
                      },
                    ],
                  },
                ],
                selectedFields: {},
            }
        },
        methods: {
          setActiveItem(item) {
            this.activeItem = item;
          },
          initializeSelectedFields() {
            const selectedFields = this.requiredFields?.required_fields ?? {};

            this.fields.forEach(({ type, fields }) => {
              selectedFields[type] = !selectedFields[type] || (Array.isArray(selectedFields[type]) && selectedFields[type].length === 0)
                  ? {}
                  : selectedFields[type];

              fields.forEach(({ group, fields: groupFields }) => {
                selectedFields[type][group] = selectedFields[type][group] ?? {};

                const groupFieldsInSelected = selectedFields[type]?.[group];
                if (groupFieldsInSelected && this.fields[type]?.[group]) {
                  Object.keys(groupFieldsInSelected).forEach(el => {
                    if (this.fields[type][group][el] === undefined) {
                      delete selectedFields[type][group][el];
                    }
                  });
                }

                groupFields.forEach(({ field, default: defaultValue }) => {
                  if (selectedFields[type][group][field] === undefined) {
                    selectedFields[type][group][field] = defaultValue;
                  }
                });
              });
            });

            this.selectedFields = selectedFields;
            this.isInitializedFields = true;
          },
          async updateSusGuideRequiredFields() {
              this.loading = true
              await api.updateSusGuideRequiredFields(this.requiredFields.id, {
                'required_fields': this.selectedFields,
                'guide_conference': this.requiredFields.guide_conference
              })
                  .then(() => {})
                  .catch(err => this.$toast.error(err.message))
                  .finally(() => (this.loading = false))
          }
        },
        watch: {
            requiredFields: {
                async handler(requiredFields) {
                  if(requiredFields){
                    this.initializeSelectedFields();
                  }
                },
                immediate: true,
                deep: true
            },
            selectedFields: {
                async handler() {
                  if(this.isInitializedFields){
                    this.updateSusGuideRequiredFields();
                  }
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss" scoped>
.required-fields {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;

  .nav-link {
    font-size: 14px;
    margin: 2px 3px;
    padding: 0 10px;
    border: 1px solid #D9DFF2;
    border-radius: 100px;
    cursor: pointer;
    color: var(--type-active);

    &.active {
      background-color: #F4F5FB;
    }
  }

  &__title-container {
    display: flex;
    justify-content: space-between;

    .required-fields-title {
      font-size: 16px;
      font-weight: 600;
      color: #142666;
    }

    .delete-settings {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #305BF2;
      cursor: pointer;

      svg {
        width: 16px;
        height: 20px;
        fill: #305BF2;
      }
    }
  }

  &__category {
    &__title {
      font-weight: 600;
    }

    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      .group {
        border-bottom: 1px solid #D9DFF2;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 335px;

        input {
          width: 16px;
          height: 16px;
        }

        span {
          display: flex;
          height: 24px;
          flex-direction: column;
          justify-content: center;
          font-weight: 400;
        }
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>