<template>
    <div id="professional-participation-module">
      <b-row v-if="guide != 'hospitalization_summary'" align-v="end">
        <b-col cols="12">
          <p class="form-title mb-3">Profissionais executantes</p>
        </b-col>
      </b-row>    
      <b-row class="mb-3">
        <b-col cols="4">
          <label for="professional_name" class="title-styles">
            Nome do Profissional
          </label>
          <multiselect
            v-model="form.professional_selected"
            id="professional_selected"
            :options="professionalOptions"
            @select="onProfessionalSelect"
            label="label"
            track-by="value"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :allow-empty="false"
            placeholder="Selecionar"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions">
              Digite para pesquisar um profissional
            </template>
            <template slot="noResult">Nenhum profissional encontrado</template>
          </multiselect>
          <div v-if="validated && !form.professional_selected" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>
        <b-col cols="4">
          <label for="degree_of_participation" class="title-styles">
            Grau Part.
          </label>
          <multiselect
            id="degree_of_participation"
            v-model="form.degree_of_participation"
            placeholder="Selecionar"
            :options="participationPercentages"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <span class="d-inline-block text-truncate width-85">
                {{ option.label }}
              </span>
            </template>
            <template slot="noOptions">
              Nenhuma opção
            </template>
            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
          <div v-if="validated && !form.degree_of_participation" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>
        <b-col cols="4">
          <div>
            <label for="" class="title-styles">Profissional Repasse Médico</label>
            <span id="info-badge">!</span>
            <b-tooltip target="info-badge" triggers="hover" custom-class="custom-onlending-tooltip">
              <span style="font-size: 14px">
                <strong>Defina o profissional para repasse médico</strong><br>
              </span>
              <span style="font-size: 12px">
                Obs.: Se a guia for gerada a partir de um agendamento, será definido o profissional do agendamento.
              </span>
            </b-tooltip>
          </div>
          <BeneficiarySelect
            v-model="form.onlending_professional"
            :value="form.onlending_professional"
            :onlendingRule="true"
          />
        </b-col>
      </b-row>  
      <b-row>
        <b-col cols="3">
          <div class="d-flex justify-content-between">
            <label class="title-styles">
              Red/Acresc.
            </label>
            <span
              v-if="guide != 'hospitalization_summary'"
              class="checkbox"
            >
              <input
                type="checkbox"
                v-model="form.custom"
              />
              Personalizado?
            </span>
          </div>
          <b-form-input
            placeholder="Descrever"
            autocomplete="off"
            type="number"
            min="0"
            step="0.01"
            v-model="form.decrease_or_increase_factor"
            :disabled="!form.custom"
          />
          <div v-if="validated && !form.decrease_or_increase_factor" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

            <!-- <b-col cols="2">
                <label for="code_type" id="title-styles">
                  Tipo de código
                </label>
                <multiselect
                  v-model="form.code_type"
                  id="code_type"
                  :options="contractedCodeTypes"
                  label="label"
                  track-by="value"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  placeholder="Selecionar"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    <Ellipsis>{{ option.label }}</Ellipsis>
                  </template>
                  <template slot="noResult">Nenhum código encontrado</template>
                </multiselect>
                <div v-if="validated && !form.code_type" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col> -->

            <!-- <b-col cols="3">
                <label for="contractor_code" id="title-styles">
                    Cód. Operadora/CPF
                </label>
                <b-form-input
                  placeholder="Descrever"
                  autocomplete="off"
                  v-model="form.contractor_code"
                />
                <div v-if="validated && !form.contractor_code" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col> -->

            <!-- <b-col cols="3" class="mt-3">
              <label for="professional_council" id="title-styles">
                Conselho
              </label>
              <multiselect
                id="professional_council"
                v-model="form.professional_council"
                placeholder="Selecionar"
                :options="professionalCouncils"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  <Ellipsis>{{ option.label }}</Ellipsis>
                </template>
                <template slot="noOptions">
                  Nenhuma opção
                </template>
                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.professional_council" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col> -->

            <!-- <b-col cols="3" class="mt-3">
                <label for="professional_council_registration" id="title-styles">
                  Nº Conselho
                </label>
                <b-form-input
                  placeholder="Descrever"
                  autocomplete="off"
                  v-model="form.professional_council_registration"
                />
                <div v-if="validated && !form.professional_council_registration" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col> -->

            <!-- <b-col cols="2" class="mt-3">
              <label for="uf" id="title-styles">
                UF
              </label>
              <multiselect
                id="uf"
                v-model="form.uf"
                placeholder="Selecionar"
                :options="statesUf"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
                <template slot="noOptions">
                  Nenhuma opção
                </template>
                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.uf" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col> -->

            <!-- <b-col cols="2" class="mt-3">
              <label for="cbo_code" id="title-styles">
                CBO
              </label>
              <multiselect
                id="cbo_code"
                v-model="form.cbo_code"
                placeholder="Selecionar"
                :options="cbos"
                track-by="value"
                label="label"
                :allow-empty="false"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    <Ellipsis>{{ option.label }}</Ellipsis>
                </template>
                <template slot="noOptions">
                  Nenhuma opção
                </template>
                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.cbo_code" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col> -->

        <b-col cols="3">
          <label for="unitary_value" class="title-styles">
            Valor Unitário
          </label>
          <money
            id="unitary_value"
            v-model="form.unitary_value"
            name="unitary_value"
            key="tissGuideItem.unitary_value"
            class="form-control"
            :disabled="!form.custom"
          />
        </b-col>
        <b-col cols="3">
          <label for="unitary_value" class="title-styles">
            Valor total
          </label>
          <money
            disabled
            id="value"
            v-model="form.value"
            name="value"
            key="tissGuideItem.value"
            class="form-control"
          />
        </b-col>
        <b-col cols="3" class="button-container">
          <b-button
            v-if="edit"
            variant="primary"
            block
            class="text-center btn-professional"
            @click="updateProfessional"
          >
            Alterar
          </b-button>
          <b-button
            v-else
            variant="primary"
            block
            class="text-center btn-professional"
            @click="addProfessional"
          >
            Adicionar
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="guide === 'hospitalization_summary'">
        <b-col cols="12">
          <p class="form-title mb-3">Identificação da Equipe</p>
        </b-col>
        <b-col cols="3">
          <label for="reference_sequence" id="title-styles">Seq. Ref</label>
          <b-form-input
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.reference_sequence"
          />
        </b-col>
        <b-col cols="2">
          <label for="degree_of_participation" id="title-styles">Grau Part.</label>
          <multiselect
            id="degree_of_participation"
            v-model="form.degree_of_participation"
            placeholder="Selecionar"
            :options="participationPercentages"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <span class="d-inline-block text-truncate width-85">
                {{ option.label }}
              </span>
            </template>
            <template slot="noOptions">
              Nenhuma opção
            </template>
            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
        </b-col>

        <b-col cols="3">
          <label for="contractor_code" id="title-styles">Cód. Operadora/CPF</label>
          <b-form-input
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.contractor_code"
          />
        </b-col>
        <b-col cols="4">
          <label for="professional_name" id="title-styles">Nome do Profissional</label>
            <multiselect
              v-model="form.professional_selected"
              id="professional_selected"
              :options="professionalOptions"
              @select="onProfessionalSelect"
              label="label"
              track-by="value"
              :allow-empty="false"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="true"
              placeholder="Selecionar"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Digite para pesquisar um profissional
              </template>
              <template slot="noResult">Nenhum profissional encontrado</template>
            </multiselect>
        </b-col>
        <b-col cols="3" class=" mt-3">
          <label for="professional_council" id="title-styles">Conselho</label>
          <multiselect
            id="professional_council"
            v-model="form.professional_council"
            placeholder="Selecionar"
            :options="professionalCouncils"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <div class="text-truncate">
                <span class="d-inline-block width-85">
                  {{ option.label }}
                </span>
              </div>
            </template>
            <template slot="noOptions">
              Nenhuma opção
            </template>
            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
        </b-col>
        <b-col cols="3" class="mt-3">
          <label for="professional_council_registration" id="title-styles">Nº Conselho</label>
          <b-form-input
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.professional_council_registration"
          />
        </b-col>
        <b-col cols="2" class="mt-3">
          <label for="uf" id="title-styles">UF</label>
          <multiselect
            id="uf"
            v-model="form.uf"
            placeholder="Selecionar"
            :options="statesUf"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>
            <template slot="noOptions">
              Nenhuma opção
            </template>
            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
        </b-col>
        <b-col cols="2" class="mt-3">
          <label for="cbo_code" id="title-styles">CBO</label>
          <multiselect
            id="cbo_code"
            v-model="form.cbo_code"
            placeholder="Selecionar"
            :options="cbos"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              <div class="text-truncate">
                <span class="d-inline-block width-85">
                  {{ option.label }}
                </span>
              </div>
            </template>

            <template slot="noOptions">
              Nenhuma opção
            </template>

            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
        </b-col>
        <b-col cols="2" class="mt-5 button-container" >
          <b-button
            v-if="edit"
            variant="primary"
            block
            class="btn-professional text-center"
            @click="updateProfessional"
          >
            Alterar
          </b-button>
          <b-button
            v-else
            variant="primary"
            block
            class="btn-professional text-center"
            @click="addProfessional"
          >
            Adicionar
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <ProfessionalParticipationTableModule
          edit
          :invoicingTissSetting="invoicingTissSetting"
          :participationPercentage="participationPercentage"
          :key="refreshProfessionals"
          :guide="guide"
          :professionals="tissGuideItem.professionals"
          :councils="professionalCouncils"
          :grades="participationPercentages"
          :procedureValue="procedureValue"
          :professionalOptions="professionalOptions"
          @edit-professional="editProfessional"
          @deleteProfessional="$emit('deleteProfessional', $event)"
        />
        <span v-if="env.toLowerCase() !== 'production' || clinic.id === '221b7dba-7986-47ac-aaa1-ede896820dcb'" class="rule-description">Regra: {{ rule }}</span>
      </b-row>
    </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager';
import { getDecreaseOrIncreaseFactor } from '../../utils/valueRules'
export default {
  name: 'ProfessionalParticipationModule',
  components: {
    ProfessionalParticipationTableModule: () => import('./ProfessionalParticipationTableModule'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
    // Ellipsis: () => import('@/components/General/Ellipsis')
  },
  props: {
    guide: String,
    settingsId: String,
    professionals: Array,
    invoicingTissSetting: Object,
    participationPercentage: Object,
    procedureValue: Number,
    tissGuideItem: Object,
    beneficiariesList: Array,
    reloadFields: Number,
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      validated: false,
      edit: false,
      editIndex: null,
      professionalOptions: [],
      professionalsArray: [],
      invoicingTissSettingProfessional: {},
      professionalEdit: {},
      refreshProfessionals: 0,
      contractedCodeTypes: require('@/components/Tiss/Data/contractedCodeTypes.json'),
      participationPercentages: require('@/components/Tiss/Data/grades.json'),
      professionalCouncils: require('@/components/Tiss/Data/professionalCouncils.json'),
      statesUf: require('@/components/Tiss/Data/statesUf.json'),
      cbos: require('@/components/Tiss/Data/cbos.json'),
      accidentIndications: require('@/components/Tiss/Data/accidentIndications.json'),
      consultationTypes: require('@/components/Tiss/Data/consultationTypes.json'),
      rule: null,
      env: process.env.NODE_ENV ?? ''
    }
  },
  async created() {
    await this.getProfessionals()
  },
  methods: {
    getParticipationPercentage(degreeOfParticipation) {
     return {
        '00': this.participationPercentage.surgeon,
        '01': this.participationPercentage.first_assistant,
        '02': this.participationPercentage.second_assistant,
        '03': this.participationPercentage.third_assistant,
        '04': this.participationPercentage.fourth_assistant,
        '05': this.participationPercentage.instrumentator,
        '06': this.participationPercentage.anesthetist,
        '07': this.participationPercentage.assistant_anesthetist,
        '08': this.participationPercentage.consultant,
        '09': this.participationPercentage.perfusionist,
        '10': this.participationPercentage.pediatrist,
        '11': this.participationPercentage.assistant_sadt,
        '12': this.participationPercentage.clinical,
        '13': this.participationPercentage.intensivist,
      }[degreeOfParticipation]
    },
    getDefaultForm() {
      return {
        reference_sequence: null,
        professional_id: null,
        degree_of_participation: null,
        onlending_professional: null,
        code_type: null,
        contractor_code: null,
        professional_selected: null,
        professional_council: null,
        professional_council_registration: null,
        uf: null,
        cbo_code: null,

        decrease_or_increase_factor: '1.0',
        custom: false,
        unitary_value: 0,
        value: 0,
      }
    },
    clearForm(){
      this.validated = false;
      this.form = this.getDefaultForm()
    },
    isValidForm() {
      this.validated = true;
      return this.form.professional_selected &&
        this.form.degree_of_participation &&
        this.form.decrease_or_increase_factor
    },
    addProfessional() {
      if (!this.isValidForm()) return
      this.form.professional_selected.value && this.$emit('addProfessional', {
        reference_sequence: this.form.reference_sequence,
        professional_id: this.form.professional_selected.value,
        degree_of_participation: this.form.degree_of_participation?.value,
        code_type: this.form.code_type?.value,
        contractor_code: this.form.contractor_code,
        professional_council: this.form.professional_council?.value,
        professional_council_registration: this.form.professional_council_registration,
        uf: this.form.uf?.value,
        cbo_code: this.form.cbo_code?.value,
        decrease_or_increase_factor: parseFloat(this.form.decrease_or_increase_factor),
        custom: this.form.custom,
        unitary_value: this.form.unitary_value,
        value: this.form.value,
        onlending_professional: {id: this.form.onlending_professional?.id, name: this.form.onlending_professional?.name}
      })

      this.clearForm();
    },
    updateProfessional() {
      if (!this.isValidForm()) return

      const newForm = {
        id: this.professionalEdit?.id,
        reference_sequence: this.form.reference_sequence,
        professional_id: this.form.professional_selected.value,
        degree_of_participation: this.form.degree_of_participation?.value,
        code_type: this.form.code_type?.value,
        contractor_code: this.form.contractor_code,
        professional_council: this.form.professional_council?.value,
        professional_council_registration: this.form.professional_council_registration,
        uf: this.form.uf?.value,
        cbo_code: this.form.cbo_code?.value,
        decrease_or_increase_factor: parseFloat(this.form.decrease_or_increase_factor),
        custom: this.form.custom,
        unitary_value: this.form.unitary_value,
        value: this.form.value,
        onlending_professional: {id: this.form.onlending_professional?.id, name: this.form.onlending_professional?.name}
      }

      if (this.editIndex != null) {
        this.form.professional_selected.value && this.$emit('updateProfessional', { index: this.editIndex, professional: newForm })
      }

      this.edit = false;
      this.clearForm();
    },
    async getProfessionals() {
      try {
        if(this.tissGuideItem.professionals?.length > 0){
          this.tissGuideItem.professionals.forEach((el) => {
            if(el.onlending_professional_id && this.beneficiariesList?.length > 0){
              const beneficiary = this.beneficiariesList.find(benef => el.onlending_professional_id === benef.id);
              if(beneficiary) el.onlending_professional = {id: beneficiary.id, name: beneficiary.name};
            }
          })
        }
        const { data } = await this.api.getInvoicingTissSettingProfessionals(this.settingsId)
        this.professionalsArray = data
        this.professionalOptions = data.map(item => {
          return {
            label: item.professional.name,
            value: item.professional.id
          }
        });
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onProfessionalSelect($event) {
      const id = $event.value
      const professional = this.professionalsArray.find(item => item.professional.id === id)
      this.invoicingTissSettingProfessional = professional?.professional

      this.form = {
        ...this.form,
        cbo_code: this.cbos.find(option => option.value === this.invoicingTissSettingProfessional.tiss_professional_setting.cbo),
        uf: this.statesUf.find(option => option.value === this.invoicingTissSettingProfessional.tiss_professional_setting.state),
        professional_council: this.professionalCouncils.find(option => option.value === this.invoicingTissSettingProfessional.tiss_professional_setting.council),
        professional_council_registration: this.invoicingTissSettingProfessional.tiss_professional_setting.professional_number,
        contractor_code: professional.operator_code,
        code_type: this.contractedCodeTypes.find(option => option.label === professional.code_type),
        degree_of_participation: this.participationPercentages.find(option => option.value === this.invoicingTissSettingProfessional.tiss_professional_setting?.grade),
        participation_percentage: this.getParticipationPercentage(this.form.degree_of_participation?.value)

      }
    },
    editProfessional({professional, index}) {
      this.form = {
        ...this.form,
        ...professional
      }

      this.form = {
        ...this.form,
        degree_of_participation: this.participationPercentages.find(option => option.value === professional.degree_of_participation),
        professional_council: this.professionalCouncils.find(option => option.value === professional.professional_council),
        uf: this.statesUf.find(option => option.value === professional.uf),
        cbo_code: this.cbos.find(option => option.value === professional.cbo_code),
        code_type: this.contractedCodeTypes.find(option => option.value === professional.code_type),
        professional_selected: this.professionalOptions.find(option => option.value === professional.professional_id),
      }

      this.edit = true
      this.editIndex = index
      this.professionalEdit = professional
    },
    updateValueByRules() {
      if (!this.form.degree_of_participation?.value) return
      const [decreaseOrIncreaseFactor, unitaryValue, value, rule] = getDecreaseOrIncreaseFactor(this.tissGuideItem, this.invoicingTissSetting, this.form.degree_of_participation?.value)
      this.form.unitary_value = unitaryValue
      this.form.decrease_or_increase_factor = decreaseOrIncreaseFactor
      this.form.value = value
      this.rule = rule
    },
    updateValueByProps() {
      if (!this.form.custom || !this.form.decrease_or_increase_factor || !this.form.unitary_value || !this.tissGuideItem.quantity) return
      let quantity = this.tissGuideItem.quantity
      if (this.tissGuideItem.pathway?.value === '1' && this.tissGuideItem.quantity >= 2) {
        quantity = 1
      }
      this.form.value = quantity * this.form.unitary_value * this.form.decrease_or_increase_factor
    },
  },
  watch: {
    'form.degree_of_participation'() {
      this.updateValueByRules()
    },
    'tissGuideItem.professionals': {
      handler(newVal){
      },
      deep: true
    },  
    'tissGuideItem.quantity'() {
      this.updateValueByRules()
    },
    'tissGuideItem.pathway'() {
      this.updateValueByRules()
    },
    'form.decrease_or_increase_factor'() {
      this.updateValueByProps()
    },
    'form.unitary_value'() {
      this.updateValueByProps()
    },
    'reloadFields'() {
      this.$forceUpdate()
      this.updateValueByRules()
    }
  },
}
</script>

<style lang="scss" scoped>
.rule-description {
  font-size: 0.6rem;
  color: var(--type-placeholder);
  margin-left: 10px;
}

#info-badge{
  font-size: small;
  border: 2px solid lightgray;
  margin-left: 5px;
  padding: 1px 6px;
  border-radius: 20px;
  font-weight: bold;
  color: lightgray;
  cursor: pointer;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

</style>
