<template>
  <div>
    <b-row class="filters-container">
      <b-col cols="3">
        <div class="custom-input-group">
          <label>Situação repasse</label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="onlendingSituation"
              trackBy="value"
              optionLabel="label"
              v-model="filters.onlendingSituation"
              :options="onlendingSituationsList"
              :value="[]"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('onlendingSituation')"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="3">
        <div class="custom-input-group">
          <label>
            Situação procedimento
          </label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="itemSituation"
              trackBy="value"
              optionLabel="label"
              v-model="filters.itemSituation"
              :options="itemSituationsList"
              :value="[]"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('itemSituation')"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="2">
        <div class="custom-input-group">
          <label>
            Nº do extrato
          </label>
          <div class="multiselect-container">
            <b-input
              id="itemExtract"
              v-model="filters.itemExtract"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="2">
        <div class="custom-input-group">
          <label for="appointment_date">Período atendimento</label>
          <b-form-group>
            <div class="multiselect-container">
              <Periods
                @select="handlePeriod"
                :clearSelection="shouldReload"
                :monthSelected="startingFilters"
              />
            </div>
          </b-form-group> 
        </div>
      </b-col>

      <b-col cols="2">
        <div class="custom-input-group">
          <label for="profile_id">Perfil/Grau part.</label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="profile"
              trackBy="value"
              optionLabel="label"
              v-model="filters.profile_id"
              :options="profileOptions"
              :value="[]"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('profile')"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="filters-container">
      <b-col cols="4">
        <div class="custom-input-group">
          <label>Paciente</label>
          <PatientInput 
            :showLabel="false"
            v-model="patient" 
            @select="selectPatient"
          />
        </div>
      </b-col>

      <b-col cols="4">
        <div class="custom-input-group">
          <label for="health_plan_id">Convênio</label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="healthplan"
              trackBy="value"
              optionLabel="label"
              :searchable="true"
              v-model="filters.health_plan_id"
              :options="healthPlanOptions"
              :value="[]"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('healthPlan')"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="4">
        <div class="custom-input-group">
          <label for="guideType">Item</label>
          <b-form-group>
            <div class="multiselect-container">
              <ItemSelect 
                :value="item"
                placeholder="Selecionar..."
                :hasCreateItem="false"
                @select="item => selectItem(item)"
              />

              <!-- <ItemSelector
                :item="item"
                class="item-container"
                @select="item => selectItem(item)"
                noResultMessage="Nenhum procedimento encontrado"
                v-b-tooltip.html.hover.right="(item) ? item.name : ''"
              /> -->
            </div>
          </b-form-group>   
        </div>
      </b-col>
    </b-row>

    <b-row class="filters-container">
      <b-col cols="3">
        <div class="custom-input-group">
          <label for="item_group">Grupo de procedimento</label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="item_group"
              trackBy="value"
              optionLabel="label"
              v-model="filters.item_group"
              :options="itemGroupList"
              :value="[]"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('itemGroup')"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="3">
        <div class="custom-input-group">
          <label for="payment_method_id">Forma de pagamento</label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="payment_method_id"
              trackBy="value"
              optionLabel="label"
              v-model="filters.payment_method_id"
              :options="paymentMethods"
              :value="filters.payment_method_id"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('paymentMethods')"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="3">
        <div class="custom-input-group">
          <label for="rule_id">Regra de repasse</label>
          <div class="multiselect-container">
            <CheckboxSelect
              id="rule_id"
              trackBy="value"
              optionLabel="label"
              v-model="filters.rule_id"
              :options="ruleOptions"
              :value="[]"
              placeholder="Selecionar"
              :clearSelection="shouldReload"
              v-b-tooltip.html.hover.right="tooltipContent('rule')"
            />
          </div>
        </div>
      </b-col>
    
      <b-col cols="3">
        <div class="custom-input-group" id="button-container">
          <b-button 
            id="reload-button" 
            variant="outline-secondary"
            @click="reloadFilters"
            :disabled="loading"
          >
            Redefinir
          </b-button>
          <b-button 
            id="update-button" 
            variant="primary"
            @click.prevent="updateOnlending" 
            :disabled="loading"
          >
            Aplicar
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { debounce } from 'lodash';
import { showFeature } from '@/utils/permissionsHelp'

export default {
  components: {
    CheckboxSelect: () => import('../OnlendingMultiselect'),
    ItemSelect: () => import('@items/components/ItemSelect'),
    // ItemSelector: () => import('@/components/General/ItemSelector'),
    Periods: () => import('@/components/General/Periods'),
    PatientInput: () => import('@/components/General/PatientInput')
  },
  props:{
    profileOptions: Array,
    ruleOptions: Array,
    healthPlanOptions: Array
  },
  created(){
    this.getPaymentMethods()
  },
  mounted(){
    this.getItemSituations();
    this.getOnlendingSituations();
    this.getItemGroup();
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      debounceGetPatients: debounce(this.searchPatient, 300),
      loading: false,
      shouldReload: false,      

      filters: {
        onlendingSituation: [],
        itemSituation: [],
        profile_id: [],
        health_plan_id: [],
        item_group: [],
        appointment_start_date: null,
        appointment_end_date: null,
        patient_id: null,
        item_id: null,
        payment_method_id: [],
        rule_id: [],
        itemExtract: null
      },

      patient: null,
      item: null,
      onlendingSituationsList: [],
      itemSituationsList: [],
      itemGroupList: [],
      patientOptions: [],
      paymentMethods: [],

      enableButton: false,
      startingFilters: true
    }
  },
  methods: {
    handlePeriod(value) {
      this.filters.appointment_start_date = value.start;
      this.filters.appointment_end_date = value.end;
      if(this.startingFilters){
        this.updateOnlending();
        this.startingFilters = false;
      }
    },
    parseBirthDay(birthday) {
      if (!birthday) return ''
      return this.moment(birthday).format('DD/MM/YYYY')
    },
    searchPatient(query) {
      this.loading = true
      this.patientSearchQuery = query;
      this.api.searchPatients(this.clinic.id, query)
        .then(res => {
          this.patientOptions = res.data;
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false
        });
    },
    selectPatient(patient) {
      this.filters.patient_id = patient?.id || null
    },
    clearPatient(){
      this.filters.patient_id = null
      this.patient = null
    },
    selectItem(item) {
      this.item = item
      this.filters.item_id = item?.id || null
    },
    clearItem(){
      this.filters.item_id = null
      this.item = null
    },
    getPaymentMethods() {
      const isLoading = this.$loading.show()
      this.api.getPaymentMethods(this.clinic.id).then(res => {
        this.paymentMethods = res.data.map(paymentMethod => {
          return {
            label: paymentMethod.name,
            value: paymentMethod.id
          }
        });
      }).catch(err => this.$toast.error(err.message)).finally(() => {
        isLoading.hide()
      })
    },
    getOnlendingSituations(){
      this.onlendingSituationsList = [
        {label: 'Em aberto', value: 'Em aberto'},
        {label: 'A revisar', value: 'A revisar'},
        {label: 'Extrato', value: 'Extrato'},
        {label: 'Finalizado', value: 'Finalizado'}
      ];
    },
    getItemSituations(){
      this.itemSituationsList = [
        {label: 'Pendente', value: 'Pendente'},
        {label: 'Pago', value: 'Pago'},
        {label: 'Pago parcialmente', value: 'Pago parcialmente'},
        {label: 'Atrasado', value: 'Atrasado'},
        {label: 'Glosado', value: 'Glosado'},
        {label: 'Em recurso', value: 'Em recurso'},
        {label: 'Expirado', value: 'Expirado'},
        {label: 'Indefinido', value: 'Indefinido'}
      ];
    },
    getItemGroup(){
      this.itemGroupList = [
        {label: 'Consulta', value: 'APPOINTMENT'},
        {label: 'Exame', value: 'EXAM'},
        {label: 'Procedimento', value: 'PROCEDURE'},
        {label: 'Retorno', value: 'RETURN'},
        {label: 'Telemedicina', value: 'TELEMEDICINE'}
      ];
      
      if(showFeature('Centro cirúrgico')){
        this.itemGroupList.push({
          label: 'Centro Cirúrgico',
          value: 'SURGICAL'
        })
      }
    },
    tooltipContent(input){
      let options = '';
      switch(input){
        case 'onlendingSituation': 
        this.filters.onlendingSituation.forEach((el, index) => {
          if(index !== this.filters.onlendingSituation.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        case 'itemSituation': 
        this.filters.itemSituation.forEach((el, index) => {
          if(index !== this.filters.itemSituation.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        case 'profile': 
        this.filters.profile_id.forEach((el, index) => {
          if(index !== this.filters.profile_id.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        case 'healthPlan': 
        this.filters.health_plan_id.forEach((el, index) => {
          if(index !== this.filters.health_plan_id.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        case 'itemGroup': 
        this.filters.item_group.forEach((el, index) => {
          if(index !== this.filters.item_group.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        case 'paymentMethods': 
        this.filters.payment_method_id.forEach((el, index) => {
          if(index !== this.filters.payment_method_id.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        case 'rule': 
        this.filters.rule_id.forEach((el, index) => {
          if(index !== this.filters.rule_id.length - 1){
            options += `${el.label}<br>` 
          } else {
            options += el.label;
          }
        })
        break;
        default:
          options = 'texto do tooltip';
        break;
      }
      return options;
    },
    convertMultiFilters(filters){
      const newFilters = {...filters};

      if(newFilters.health_plan_id?.length > 0){
        newFilters.health_plan_id = newFilters.health_plan_id.map(el => el.value);
      }

      if(newFilters.itemSituation?.length > 0){
        newFilters.itemSituation = newFilters.itemSituation.map(el => el.value);
      }

      if(newFilters.item_group?.length > 0){
        newFilters.item_group = newFilters.item_group.map(el => el.value);
      }

      if(newFilters.onlendingSituation?.length > 0){
        newFilters.onlendingSituation = newFilters.onlendingSituation.map(el => el.value);
      }

      if(newFilters.payment_method_id?.length > 0){
        newFilters.payment_method_id = newFilters.payment_method_id.map(el => el.value);
      }

      if(newFilters.profile_id?.length > 0){
        newFilters.profile_id = newFilters.profile_id.map(el => el.value);
      }

      if(newFilters.rule_id?.length > 0){
        newFilters.rule_id = newFilters.rule_id.map(el => el.value);
      }

      return newFilters;
    },
    updateOnlending(){
      const newFilters = this.convertMultiFilters(this.filters);
      this.$emit('changeOnlendingFilters', newFilters)
    },
    reloadFilters(){
      this.filters.onlendingSituation = [];
      this.filters.itemSituation = [];
      this.filters.profile_id = [];
      this.filters.health_plan_id = [];
      this.filters.item_group = [];
      this.filters.appointment_start_date = null;
      this.filters.appointment_end_date = null;
      this.filters.patient_id = null
      this.patient = null
      this.filters.item_id = null
      this.item = null
      this.filters.payment_method_id = [];
      this.filters.rule_id = [];
      this.filters.itemExtract =[];

      this.shouldReload = !this.shouldReload;
      this.$emit('changeOnlendingFilters', this.filters)
    }
  },
}

</script>

<style lang="scss">
.filters-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .custom-input-group {
    margin: 0px 2px;

    @media only screen and (max-width: 1280px){
      min-width: 160px;
    }

    @media only screen and (min-width: 1281px) and (max-width: 1499px){
      min-width: 170px;
    }

    @media only screen and (min-width: 1500px) and (max-width: 1749px){
      min-width: 200px;
    }

    @media only screen and (min-width: 1750px) and (max-width: 1919px){
      min-width: 250px;
    }

    @media only screen and (min-width: 1920px) and (max-width: 2139px){
      min-width: 270px;
    }

    @media only screen and (min-width: 2140px) and (max-width: 2559px){
      min-width: 310px;
    }

    @media only screen and (min-width: 2560px){
      min-width: 370px;
    }
  }

  .patient-input-group {
    margin: 0px 2px;

    @media only screen and (max-width: 1280px){
      min-width: 160px;
    }

    @media only screen and (min-width: 1281px) and (max-width: 1499px){
      min-width: 250px;
    }

    @media only screen and (min-width: 1500px) and (max-width: 1749px){
      min-width: 400px;
    }

    @media only screen and (min-width: 1750px) and (max-width: 1919px){
      min-width: 450px;
    }

    @media only screen and (min-width: 1920px) and (max-width: 2139px){
      min-width: 500px;
    }

    @media only screen and (min-width: 2140px) and (max-width: 2559px){
      min-width: 520px;
    }

    @media only screen and (min-width: 2560px){
      min-width: 550px;
    }
  }

  label {
    font-weight: 700;
    font-size: 14px;
  }

  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      height: 100%;
      margin-left: 10px;
    }
  }


  .patient-filter{
    border-radius: 8px;
  }

  .patient-name {
    color: var(--type-active);
    font-size: 16px;
    .chevron {
      right: 8px;
    }
  }

  .patient-props {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--type-placeholder);
    font-size: 14px;

    .patient-cpf {
      color: var(--blue-500);
      font-weight: 400;
    }
  }

  .close-icon {
    width: 25px;
    height: 25px;
    fill: rgb(127, 145, 210);
    position: absolute;
    right: 15px;
    top: 47%;
  }

  .close-procedure-icon{
    width: 25px;
    height: 25px;
    fill: rgb(127, 145, 210);
    position: absolute;
    right: 15px;
    top: 40%;
  }

  .multiselect-container {
    .multiselect {
      line-height: 140%;
    }

    .multiselect .multiselect__tags {

      align-items: center;
      border-radius: 8px;
      border: 1px solid lightgray !important;
      max-height: 90px !important;
      padding: 7px;
      padding-right: 20px;
    }
  
    .multiselect .multiselect__content-wrapper .multiselect__content .multiselect__option {
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      color: var(--type-active);
      white-space: break-spaces;
    }

    .multiselect .multiselect__content-wrapper .multiselect__content .multiselect__option--selected {
      background-color: lightgray !important;
      color: gray !important;
    }

    .multiselect__option--highlight:after {
      background-color: transparent !important;
      color: gray
    }

    .multiselect .multiselect__input {
      font-weight: 400;
      font-size: 12px;
      background-color: transparent;
    }

    .multiselect__tag {
      max-width: 100px !important;
    }

  }
  .close-icon {
   text-align: center;
  }
  
  #button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    // button {
    //   font-size: 16px;
    //   font-weight: bold;
    //   color: white;
    //   transition: 0.2s;
    //   align-self: flex-end;
    //   margin-bottom: 5px;
    //   margin: 10px 0px;
    // }

    #reload-button {
      width: 150px;
      margin-top: 30px;
    }

    #update-button {
      width: 150px;
      margin-top: 30px;
    }
  }

}
</style>