<template>
  <div class="autocomplete-container">
    <b-form-input
      ref="input"
      autocomplete="off"
      :trim="trim"
      class="input"
      :debounce="debounce || 0"
      v-model="inputValue"
      :disabled="disabled"
      :placeholder="placeholder"
      v-on:blur="closeOptions"
      v-on:focus="onFocus"
      @update="$event => emitUpdate($event)"
    />

    <span v-if="!selected" class="icon-box arrow-icon"  @click="showOptions = !showOptions">
      <ChevronDown class="icon stroke select-arrow" :class="{ 'opened-options': showOptions }" />
    </span>

    <span v-if="selected" id="help-icon" class="icon-box close-icon">
      <Close class="icon stroke" @click="clearSelection" />
    </span>

    <div v-if="showOptions" class="options-content">
      <div v-if="options">
        <div
          v-for="(option, index) in auxOptions"
          :key="'autocomplete-' + index"
          class="option"
          :class="{ 'disabled': option.disabled }"
          @click="!option.disabled ? handleSelected(option) : null"
        >
          {{ option.label }}
            <div class="small-date">
              {{ (option.date) ? `Validade: ${moment(option.date).format('D/MM/Y')}`: ``}}
            </div>
        </div>
      </div>

      <div v-if="inputValue && auxOptions.length === 0" class="message-option">
        Nenhum resultado encontrado "{{ inputValue }}"
      </div>
      <div v-if="inputValue && auxOptions.length === 0 && onAddOption" 
        class="add-option" @click="onAddOption(inputValue)"
      >
        <Plus class="icon stroke" />
        Adicionar "{{ inputValue }}" à lista
      </div>
      <div v-if="!inputValue && auxOptions.length === 0" class="message-option">
        {{ emptyResultsMessage || 'Digite para iniciar a busca...' }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Plus: () => import('@/assets/icons/plus-blue.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
  },
  props: {
    value: { validate: v => true },
    placeholder: String,
    trim: Boolean,
    options: { type: Array },
    disabled: Boolean,
    debounce: String,
    emptyResultsMessage: String,
    onAddOption: Function,
    situationSelected: Boolean,
  },
  data() {
    return {
      inputValue: this.value,
      showOptions: false,
      selected: null,
    }
  },
  mounted() {
    this.inputValue !== null && this.options.length && this.handleSelected(this.options.find(option => option.value === this.inputValue));
  },
  methods: {
    closeOptions() {
      setTimeout(() => {
        this.showOptions = false;
      }, 300);
    },
    onFocus() {
      this.openOptions();
      this.$emit('focus')
    },
    openOptions() {
      this.showOptions = true;
    },
    handleSelected(option) {
      this.inputValue = option?.label;
      this.selected = option;
      this.$emit('input', option?.value);
      this.showOptions = false;
    },
    clearSelection() {
      if (this.disabled) return;
      this.inputValue = '';
      this.selected = null;
      this.$emit('input', null);
      this.$emit('generic-cleaner', null);
    },
    emitUpdate(value) {
      this.$emit('update', value);
    },
  },

  watch: {
    value: function(newVal, oldValue) {
      if (newVal === null) {
        this.inputValue = '';
        this.selected = null;
      }
      newVal && this.options.length && newVal !== oldValue && this.handleSelected(this.options.find(option => option.value === newVal));
      oldValue && !newVal && this.clearSelection()
    },
    inputValue: function(newVal) {
      this.$emit('typing', newVal);
    },
    showOptions: function() {
      if (this.showOptions) {
        this.$refs.input.focus();
      }
    },
    options(newOptions) {
      if (newOptions.length && this.value) {
        this.handleSelected(newOptions.find(option => option.value === this.value))
      }else{
        if(newOptions.length < 1)
          this.clearSelection();
      }
    },
    situationSelected: {
      handler: function (val) {
        if(val){
          this.selected = this.options.find(o => o.label === 'Pendente');
          this.handleSelected(this.selected);
        }
      },
      immediate: true,
    }
  },
  computed: {
    auxOptions: {
      get() {
        return this.inputValue
            ? this.options.filter(o => 
                typeof o.label === 'string' && 
                o.label.toLowerCase().includes(this.inputValue.toLowerCase())
              )
            : this.options;
        },
      set(val) {},
    }
  }
}
</script>
<style scoped lang="scss">
  .autocomplete-container {
    position: relative;

    .input {
      height: 38px;
      padding: 15px 40px 15px 16px !important;
    }

    .input::placeholder {
      color: var(--type-placeholder);
    }

    .arrow-icon {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;
    }
    .opened-options {
      transform: rotate(-180deg);
    }
    .close-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;

      svg {
        fill: var(--neutral-500);
      }
    }
    .icon {
      width: 25px;
      transition: all 500ms;
    }
    .options-content {
      width: 100%;
      max-height: 400px;
      position: absolute;
      top: 110%;
      left: 0;
      background-color: white;
      border-radius: 8px;
      border: 1px solid #C6CEEB;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      overflow-x: auto;
      z-index: 300;
    }
    .option {
      padding: 20px;
      font-size: 16px;
      text-align: left;
      cursor: pointer;
    }
    .option:hover {
      background-color: #F4F5FB;
    }
    .message-option {
      padding: 20px;
      font-size: 14px;
      text-align: left;
      color: var(--type-placeholder);
    }

    .add-option {
      cursor: pointer;
      padding: 20px;
      font-size: 14px;
      text-align: left;
      font-weight: 700;
      color: var(--blue-500);
    }

    .disabled {
      color: var(--neutral-500);
      cursor: not-allowed;
    }

    .small-date{
      color: var(--blue-500);
      font-size: 12px;
    }
  }
</style>
