<template>
  <section class="patient-id">
    <div class="patient-id__section" v-for="tp in idTypes" :key="tp.type">
      <div class="patient-id__section__title" @click="expanded[tp.type] = !expanded[tp.type]">
        <span>Impressão {{ tp.type === 'tags' ? 'etiquetas' : 'pulseiras' }} - Identificação de pacientes</span>
        <ChevronDown class="icon" :class="{'expanded-icon': !expanded[tp.type]}"/>
      </div>
      <transition name="slide">
        <div class="patient-id__section__content" v-if="expanded[tp.type]">
          <div class="type-selector">
            <div class="type-input-group">
              <input type="radio" class="form-control" :name="tp.type" value="bar" v-model="tp.code_type" :disabled="loading[tp.type]">
              <span>Código de barras</span>
            </div>
            <div class="type-input-group">
              <input type="radio" class="form-control" :name="tp.type" value="qr" v-model="tp.code_type" :disabled="loading[tp.type]">
              <span>QR Code</span>
            </div>
            <div class="type-input-group">
              <input type="radio" class="form-control" :name="tp.type" value="none" v-model="tp.code_type" :disabled="loading[tp.type]">
              <span>Nenhum</span>
            </div>
          </div>
          <hr>
          <span>Exibir informações do paciente</span>
          <div class="patient-info">
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.name" disabled>
              <span>Nome</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.cpf" :disabled="loading[tp.type]">
              <span>CPF</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.rg" :disabled="loading[tp.type]">
              <span>RG</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.birth_date" :disabled="loading[tp.type]">
              <span>Data nasc.</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.age" :disabled="loading[tp.type]">
              <span>Idade</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.gender" :disabled="loading[tp.type]">
              <span>Sexo</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.medical_record" :disabled="loading[tp.type]">
              <span>N. prontuário</span>
            </div>
            <div class="patient-input-group">
              <input type="checkbox" class="form-control" v-model="tp.mother_name" :disabled="loading[tp.type]">
              <span>Nome da mãe</span>
            </div>
          </div>
          <hr>
          <div class="patient-id-footer">
            <toggle-button
                v-if="tp.type === 'bracelets'"
                :sync="true"
                :height="20"
                :width="40"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                v-model="toggleBracelet"
                :disabled="loading[tp.type]"
            />
            <button class="btn btn-primary" :disabled="loading[tp.type]" @click="update(tp.type)">
              <span v-if="!loading[tp.type]">Salvar</span>
              <b-spinner small v-else />
            </button>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>

import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
  name: 'PatientIdPrintSettings',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  data() {
    return {
      clinicId: getCurrentClinic().id,
      expanded: {
        bracelets: true
      },
      loading: {
        bracelets: false
      },
      idTypes: [
        {
          type: 'bracelets',
          active: false,
          name: true,
          cpf: false,
          rg: false,
          birth_date: false,
          age: false,
          gender: false,
          medical_record: false,
          mother_name: false,
          code_type: 'bar',
        }
      ]
    }
  },
  computed: {
    toggleBracelet: {
      get() {
        const index = this.idTypes.findIndex(tp => tp.type === 'bracelets');
        return Boolean(this.idTypes[index].active);
      },
      set(value) {
        const index = this.idTypes.findIndex(tp => tp.type === 'bracelets');
        this.idTypes[index].active = value ? 1 : 0;
        this.idTypes = [...this.idTypes];
      }
    }
  },
  mounted(){
    this.getBraceletsPrintSettings()
  },
  methods: {
    getBraceletsPrintSettings() {
      this.loading.bracelets = true;
      this.api.getBraceletPrintSettings(this.clinicId)
      .then(({data}) => {
        this.idTypes[0] = {...this.idTypes[0], ...data}
        if(data.active === 1) this.toggleBracelet = true;
      })
      .catch(error => {
        console.error('Erro ao obter configuracoes de pulseira: ', error)
      }).finally(() => {
        this.loading.bracelets = false;
      })
    },
    update(type){
      this.loading.bracelets = true;
      this.api.updateBraceletPrintSettings(this.clinicId, this.idTypes[0])
      .then(() => {
        this.$toast.success('Configurações de pulseira atualizadas com sucesso')
      }).catch(error => {
        console.error('Erro ao atualizar configuracoes de pulseira: ', error)
        this.$toast.error('Erro ao atualizar configurações de pulseira')
      }).finally(() => {
        this.getBraceletsPrintSettings();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-id {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__section {
    font-family: "Nunito Sans", sans-serif;
    box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px 0px #00000024, 0 1px 3px 0px #0000001F;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid lightgray;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;

      span {
        font-size: 16px;
        color: black;
        font-weight: 400;
      }

      .icon {
        width: 20px;
        height: 20px;
        stroke: black;
        transition: transform 0.3s ease-in-out;
      }

      .expanded-icon {
        transform: rotate(180deg);
      }

    }

    &__content {
      padding: 8px 16px 16px 16px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      color: black;

      .type-selector {
        display: flex;
        gap: 24px;
        width: 100%;
        flex-wrap: wrap;

        .type-input-group {
          display: flex;
          align-items: center;
          gap: 8px;
          white-space: nowrap;

          span {
            font-size: 16px;
            color: black;
            cursor: pointer;
          }

          input {
            cursor: pointer;
            width: 20px;
          }
        }
      }

      .patient-info {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        margin: 8px;

        .patient-input-group {
          display: flex;
          align-items: center;
          gap: 8px;
          white-space: nowrap;

          span {
            font-size: 16px;
            color: black;
            cursor: pointer;
          }

          input {
            cursor: pointer;
            width: 20px;
          }
        }
      }

      hr {
        border: none;
        border-top: 2px solid rgba(lightgray, 0.8);
        color: #333;
        overflow: visible;
        text-align: center;
        height: 5px;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .patient-id-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 8px;

        &::before {
          align-self: stretch;
          content: '';
          border: 1px solid rgba(lightgray, 0.8);
        }

        label {
          order: -1;
        }

        button {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 13px;
          cursor: pointer;
          line-height: 20px;
          min-width: 60px;
        }
      }
    }
  }

}

.slide-enter-active {
  transition: all .2s ease-in;
}
.slide-leave-active {
  transition: all .2s ease-out;
}
.slide-enter, .slide-leave-to {
  opacity: 0;
  overflow: hidden;
}
</style>