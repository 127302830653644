<template>
<div>
  <div class="wh-container">
    <div class="header">
      <p class="title">Relatórios gerenciais</p>
    </div>

    

    <Tab
      ref="report_tabs"
      :index="currentTabIndex"
      :keys="tabs2"
      @onChange="onChangeTabMaster"
    >
      <SubcategoriesTab
        v-if="currentTabIndex === tabs2.findIndex(el => el.label === 'Procedimentos')"
        :active="getInitialActive()"
        fontSize="1em"
        v-bind:tabs="tabs"
        v-on:onChange="onChangeTab"
      >
        <Appointments v-if="currentTab === 'Agendamentos'" />
        <MedicalReferral v-if="currentTab === 'Encaminhamentos'" />
        <PatientLoyalty v-if="currentTab === 'Fidelização de pacientes'" />
        <Attendances v-if="currentTab === 'Atendimentos'" />
        <!-- Work in Progress -->
        <ProcessTime v-if="currentTab === 'Tempo dos processos'" />

        <ProceduresTab v-if="currentTab === 'Agendamento'" />
        <TissTab v-if="currentTab === 'Faturamento TISS'" />
      </SubcategoriesTab>
      <div v-if="currentTabIndex === tabs2.findIndex(el => el.label === 'Relatório Financeiro BETA')">
        <div class="report-header">
          <div class="report-header-title">Relatórios financeiros</div>
          <div>Estes relatórios estão em <span style="color: orange; font-weight: 700;">fase BETA de teste</span> e serão melhorados com seu feedback antes de sua versão final.</div>
        </div>
        <iframe
          v-if="iframeUrl"
          :src="iframeUrl"
          class="metabase-iframe"
          frameborder="0"
          allowtransparency
        ></iframe>
      </div>
      <div v-if="currentTabIndex === tabs2.findIndex(el => el.label === 'Relatório Operacional BETA')">
        <div class="report-header">
            <div class="report-header-title">Relatório operacional</div>
            <div>Estes relatórios estão em <span style="color: orange; font-weight: 700;">fase BETA de teste</span> e serão melhorados com seu feedback antes de sua versão final.</div>
          </div>
          <iframe
            v-if="iframeUrlOperational"
            :src="iframeUrlOperational"
            class="metabase-iframe"
            frameborder="0"
            allowtransparency
          ></iframe>
      </div>
      <div v-if="currentTabIndex === tabs2.findIndex(el => el.label === 'Fechamento Diário')">
        <div class="report-header">
            <div class="report-header-title">Fechamento Diário</div>
            <div>Estes relatórios estão em <span style="color: orange; font-weight: 700;">fase BETA de teste</span> e serão melhorados com seu feedback antes de sua versão final.</div>
          </div>
          <iframe
            v-if="iframeUrlFechamentoDiario"
            :src="iframeUrlFechamentoDiario"
            class="metabase-iframe"
            frameborder="0"
            allowtransparency
          ></iframe>
      </div>
    </Tab>



    
  </div>
</div>
</template>

<script>
import jwt from 'jsonwebtoken'
import { 
  getCurrentClinic, 
  getCurrentUser, 
  userHasPermission 
} from '@/utils/localStorageManager'

export default {
    name: 'ManagementReports',
    metaInfo: {
        title: 'Eyecare - Relatórios gerenciais',
    },

    components: {
      Tab: () => import('@/components/Tab'),
      Appointments: () => import('./Appointments.vue'),
      Attendances: () => import('./Attendances.vue'),
      MedicalReferral: () => import('./MedicalReferral.vue'),
      PatientLoyalty: () => import('./PatientLoyalty.vue'),
      ProcessTime: () => import('./ProcessTime.vue'),
      SubcategoriesTab: () => import('@/components/SubcategoriesTab.vue'),
      ProceduresTab: () => import('./Procedures.vue'),
      TissTab: () => import('./TissInvoicing.vue')
    },
    created() {
      this.startMetabaseFinancial();
      this.startMetabaseOperational();
      this.startMetabaseFechamentoDiario();
    },
    data() {
      const tabs = {}

      const tabs2 = [];
      
      tabs2.push({ label: 'Procedimentos', disabled: false });

      if (userHasPermission('ReGerRelBet1')) {
        tabs2.push({ label: 'Relatório Financeiro BETA', disabled: false });
      }
      
      if (userHasPermission('ReGerAge1')) {
        tabs2.push({ label: 'Relatório Operacional BETA', disabled: false });
      }

      if (userHasPermission('ReGerFecDia1')) {
        tabs2.push({ label: 'Fechamento Diário', disabled: false });
      }

      // if (userHasPermission('ReGerAge1')) {
      //   tabs.Atendimento = ['Encaminhamentos']
      // }

      // if (userHasPermission('ReGerProc1')) {
      //   tabs.Processos = ['Tempo dos processos']
      // }

      tabs.Procedimentos = ['Agendamento', 'Faturamento TISS']

      return {
        clinic: getCurrentClinic(),
        currentTabIndex: userHasPermission('ReGerRelBet1') ? 0 : 1,
        tabs2: tabs2,
        iframeUrl: null,
        iframeUrlOperational: null,
        iframeUrlFechamentoDiario: null,
        currentTab: 'Agendamentos',
        person: getCurrentUser(),
        tabs,
      }
    },

    model: {
      prop: 'currentIndex',
      event: 'onChange'
    },

    methods: {
      onChangeTabMaster(index, dontPush) {
        if(!userHasPermission('ReGerRelBet1') && index === 0)
          index = 1;

        this.currentTabIndex = index
        if (!dontPush) {
          window.history.pushState(null,null,`/relatorios-gerenciais/${this.getPathByIndex(index)}`)
        }
      },
      getPathByIndex(index) {
        const indexes = {
          0: 'relatorios-beta',
          1: 'procedimentos',
        }

        return indexes[index] || 'dashboard'
      },
      startMetabaseFinancial() {
        const METABASE_SITE_URL = "https://eyecare-health.metabaseapp.com";
        const payload = {
          resource: { dashboard: 463 },
          params: {
            "clinic_name": this.clinic.name,
          },
          exp: Math.round(Date.now() / 1000) + (4 * 60 * 60) // 4 hours expiration
        };
        const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);
        this.iframeUrl = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=false`;
      },
      startMetabaseOperational() {
        const METABASE_SITE_URL = "https://eyecare-health.metabaseapp.com";
        const payload = {
          resource: { dashboard: 529 },
          params: {
            "cl%C3%ADnica": this.clinic.name,
          },
          exp: Math.round(Date.now() / 1000) + (4 * 60 * 60) // 4 hours expiration
        };
        const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);
        this.iframeUrlOperational = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=false`;
      },
      startMetabaseFechamentoDiario() {
        console.log('NAME =>', this.clinic.name)
        const METABASE_SITE_URL = "https://eyecare-health.metabaseapp.com";
        const payload = {
          resource: { dashboard: 925 },
          params: {
            "clinica": this.clinic.name,
          },
          exp: Math.round(Date.now() / 1000) + (4 * 60 * 60) // 4 hours expiration
        };
        const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);
        this.iframeUrlFechamentoDiario = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=false`;
      },

      getInitialActive() {
        if (userHasPermission('ReGerAge1'))
          return 'Agendamentos'
        else if (userHasPermission('ReGerProc1'))
          return 'Tempo dos processos'
        else
          return null
      },
      onChangeTab(active, dontPush) {
          this.currentTab = active
          if (!dontPush) {
              window.history.pushState(
              null,
              null,
              `/relatorios-gerenciais/${active}`
              )
          }
      }
    }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .wh-button {
    margin: 0 5px;
    color: var(--neutral-000);
  }
}
.report-header {
  padding: 2rem 0;
  .report-header-title {
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.metabase-iframe {
  width: 100%;
  height: 100vh;
}
</style>