<template>
  <div class="television-content">
    <div class="wrapper">
      <section
        class="section-left"
        :class="{ preferential: callPanelPassword?.is_preferential }"
      >
        <div class="current-wrapper">
          <div class="identifier-wrapper">
            <div class="type">
              {{
                callPanelPassword && callPanelPassword.patient
                  ? 'Paciente'
                  : 'Senha'
              }}
              <span
                class="header-tag preferential-tag"
                v-if="callPanelPassword?.is_preferential"
                >Preferencial</span
              >
              <span v-else></span>
            </div>
            <div class="password-info">
              <div
                v-if="callPanelPassword && callPanelPassword.patient"
                class="name"
                :class="{ blink: blinking }"
              >
                {{ callPanelPassword.patient.name }}
                <div
                  v-if="callPanelPassword?.patient?.birthday"
                  class="birthday"
                >
                  Data de nascimento:
                  {{ getDate(callPanelPassword?.patient?.birthday) }}
                </div>
              </div>
              <div v-else class="password" :class="{ blink: blinking }">
                {{ callPanelPassword?.password }}
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="local-wrapper">
            <div class="department">
              {{
                callPanelPassword && callPanelPassword.listener
                  ? callPanelPassword.department.name
                  : ''
              }}
            </div>
            <div class="listener">
              {{
                callPanelPassword && callPanelPassword.listener
                  ? callPanelPassword.listener.name
                  : callPanelPassword?.department?.name
              }}
            </div>
          </div>
        </div>
      </section>
      <section class="section-right">
        <div class="last-calls-title">Últimas chamadas</div>
        <div class="call-history-container">
          <div
            v-for="callPanelPassword in calledPasswords"
            :key="callPanelPassword.id"
            class="call-history-item"
          >
            <div class="call-history-item-identifier">
              <div class="call-history-item-content-name">
                {{
                  callPanelPassword.patient
                    ? callPanelPassword.patient.name
                    : callPanelPassword.password
                }}
                <span
                  v-if="callPanelPassword?.is_preferential"
                  class="preferential-icon"
                  >P</span
                >
              </div>
              <div
                v-if="callPanelPassword?.patient?.birthday"
                class="call-history-item-content-birthday"
              >
                DN: {{ getDate(callPanelPassword?.patient?.birthday) }}
              </div>
            </div>
            <div class="call-history-item-local">
              <div
                v-if="callPanelPassword?.listener?.name"
                class="call-history-item-local-identifier"
              >
                {{ callPanelPassword?.listener?.name }}
              </div>
              <div v-else class="call-history-item-local-identifier">
                {{ callPanelPassword.department.name }}
              </div>
              <div class="call-history-item-local-time">
                {{ moment(callPanelPassword.attendance_start).format('HH:mm') }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="footer">
      <div class="footer-props"><Clock class="icon" /> {{ timer }}</div>
      <div class="footer-props">
        <Calendar class="icon-calendar" /> {{ getDate() }}
      </div>
      <div class="message">
        <span>{{ settings?.panel_message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  getCurrentClinic,
  getCallPanelGroup
} from '@/utils/localStorageManager'
import { parseName } from '@/utils/callPanelHelper'
import Clock from '@/assets/icons/clock.svg'
import Calendar from '@/assets/icons/calendar.svg'

export default {
  components: { Clock, Calendar },
  created() {
    this.pannelChannel = this.pusher.subscribe(`call-pannel-${this.clinic.id}`);
    this.setTimer()
  },
  computed: {
    ...mapGetters('pusher', ['channel'])
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      settings: null,
      blinking: false,
      timer: null,
      callPanelPassword: null,
      calledPasswords: [],
      panelId: null,
      departments: []
    }
  },
  async mounted() {
    this.getPanelId()
    await this.getCallPanelDepartments().then(() => {
      this.channel.bind('call-panel-password', this.receivedPassword, this)
      this.pannelChannel.bind('call-panel-password', this.receivedPassword, this)
      this.getCurrentPasswordFromDepartments()
      this.getCallPanelSettings()
      this.getClinicCalledPanelPassword()
    })
  },
  beforeDestroy() {
    this.unsubscribe()
    this.channel.unbind('call-panel-password', this.receivedPassword, this)
    this.pannelChannel.unbind('call-panel-password', this.receivedPassword, this)
  },
  methods: {
    ...mapActions('pusher', ['unsubscribe']),
    parseName,
    getDate(date) {
      return (date ? this.moment(date) : this.moment()).format(
        date ? 'DD/MM/YYYY' : 'DD/MM/YY'
      )
    },
    async getPanelId() {
      if (this.$route.params.panelId && !getCallPanelGroup()) {
        localStorage.setItem('callPanelGroup', this.$route.params.panelId)
        this.panelId = this.$route.params.panelId
      } else if (!this.$route.params.panelId && getCallPanelGroup()) {
        this.panelId = getCallPanelGroup()
      } else if (this.$route.params.panelId && getCallPanelGroup()) {
        this.panelId = this.$route.params.panelId
        localStorage.setItem('callPanelGroup', this.$route.params.panelId)
      } else {
        this.$swal.fire({
          title: 'Erro',
          text: 'Não foi possível carregar o painel. Por favor, volte à página de seleção de painéis e tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    setTimer() {
      const setup = () => {
        this.timer = this.moment().format('HH:mm')
      }
      setInterval(setup, 1000)
    },
    speech() {
      let text = this.callPanelPassword.patient
        ? `Paciente; ${this.callPanelPassword.patient.name}`
        : `Senha; ${this.callPanelPassword.password.split('').join(';')};`
      text += `${this.callPanelPassword.department.name};`
      this.callPanelPassword.listener &&
        (text += `${this.callPanelPassword.listener.name}`)
      var msg = new SpeechSynthesisUtterance()
      var voices = window.speechSynthesis.getVoices()
      const voice = voices?.find(voice => /pt-BR/.test(voice.lang))
      msg.voice = voice
      msg.volume = 1 // From 0 to 1
      msg.rate = 1 // From 0.1 to 10
      msg.pitch = 1 // From 0 to 2
      msg.text = text.toLowerCase()
      msg.lang = 'pt-BR'
      speechSynthesis.speak(msg)
    },
    startBlink() {
      this.blinking = true
      setTimeout(() => {
        this.blinking = false
      }, 5000)
    },
    playSong() {
      try {
        const audio = new Audio('@/assets/songs/TF032.mp3')
        audio
          .play()
          .then(() => {
            console.log('*** THEN **')
          })
          .catch(err => {
            console.log('*** CATCH ** ', err)
          })
      } catch (err) {
        console.log('failed audio', err)
      }
    },
    getCurrentPasswordFromDepartments() {
      this.api
        .getCurrentPasswordFromDepartments(this.departments)
        .then(({ data }) => {
          if (this.departments.includes(data.department_id))
            this.callPanelPassword = data || null
        })
    },
    receivedPassword(data) {
      console.log('DATA => ', data);
      if (!this.departments.includes(data.department_id)) return
      console.log(data);
      if (data.status === 'started') {
        this.callPanelPassword = data
        this.startBlink()
        // this.playSong()
        this.speech()
      }

      this.getClinicCalledPanelPassword()
    },
    getCallPanelSettings() {
      this.api.getCallPanelSettings(this.clinic.id).then(res => {
        this.settings = res.data
      })
    },
    getClinicCalledPanelPassword() {
      this.calledPasswords = []
      this.api.getClinicCalledPanelPassword(this.clinic.id).then(({ data }) => {
        if (data.length) {
          data.map(password => {
            if (this.departments.includes(password.department_id))
              this.calledPasswords.push(password)
          })
        }
      })
    },
    async getCallPanelDepartments() {
      await this.api
        .getCallPanelGroupDepartments(this.panelId)
        .then(({ data }) => {
          if (data.length) {
            this.departments = data.flatMap(el =>
              el.departments.map(department => department.id)
            )
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.television-content {
  height: 100vh;
}
.wrapper {
  height: 92%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.section-left {
  flex: 1;
  height: 100%;
  background-color: var(--blue-500);
  color: white;
}
.preferential {
  background-color: var(--orange);
}
.section-right {
  flex: 1;
  height: 100%;
  overflow-y: hidden;
}
.current-wrapper {
  height: 100%;
  display: flex;
  padding: min(56px, 5%);
  flex-direction: column;
  justify-content: space-between;
}
.identifier-wrapper {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.password-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.local-wrapper {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}
.type {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: max(40px, 2.5vw);
  margin-bottom: 40px;
}
.name {
  font-weight: 700;
  font-size: max(60px, 2vw);
}
.birthday {
  font-weight: 500;
  font-size: 32px;
}
.password {
  font-weight: 700;
  font-size: 90px;
}
.divider {
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background-color: var(--neutral-200);
}
.department {
  font-weight: 500;
  font-size: 40px;
}
.listener {
  font-weight: 600;
  font-size: 50px;
}
.last-calls-title {
  font-weight: 600;
  font-size: max(40px, 2.2vw);
  padding: 20px;
  color: var(--blue-700);
}
.call-history-item {
  display: flex;
  gap: 20px;
  padding: 40px;
  border-top: 2px dashed #305bf2;

  &:nth-child(odd) {
    background-color: #f4f5fb;
  }

  &-identifier {
    flex: 1;
    justify-content: space-between;
  }

  &-local {
    flex: 1;

    &-identifier {
      font-weight: 600;
      font-size: 40px;
    }

    &-time {
      font-weight: 500;
      font-size: 28px;
    }
  }

  &-content-name {
    font-weight: 600;
    font-size: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &-content-birthday {
    font-size: 32px;
  }
}
.name-or-password {
  display: flex;
  align-items: center;
  gap: 5px;

  &.preferential {
    color: var(--orange);
  }
}
.preferential-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  color: white;
  font-size: 1vw;
  font-weight: 700;
  padding: 2px 10px;
  border-radius: 30px;
}
.preferential-icon {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  color: white;
  font-size: 1vw;
  font-weight: 700;
  padding: 0;
  border-radius: 50%;
}
.footer {
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 8%;
  background-color: var(--blue-700);
  color: white;
}
.footer-props {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: min(48px, 3rem);
  gap: 20px;
  padding: 0 50px;
  .icon {
    width: 50px;
    height: 50px;
    stroke: var(--blue-300);
  }
  .icon-calendar {
    width: 50px;
    height: 50px;
    fill: var(--blue-300);
  }
}
.message {
  width: 100%;
  font-weight: 500;
  font-size: min(48px, 3rem);
  white-space: nowrap;
  overflow: hidden;

  span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 25s linear infinite;
  }
}

.blink {
  animation: blinking 1s linear infinite;
}

.header-tag {
  border-radius: 80px;
  padding: 0px 16px;
  width: fit-content;
  font-size: 40px;
  display: flex;
  align-items: center;
}

.common-tag {
  color: var(--blue-500);
  background-color: white;
}

.preferential-tag {
  color: var(--orange);
  background-color: white;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
</style>
