<template>
  <section class="tiss-container">
    <GuidesReportFilter v-if="filter" class="no-print" @change="updateFilter" />
    <GuideReportTable
      :canDelete="canDelete"
      :headers="headers"
      :subHeaders="subHeaders"
      :rows="rows"
      :getGuide="getGuide"
      :openHistoric="openHistoric"
      :openDocumentModal="openDocumentModal"
      :openDocumentListModal="openDocumentListModal"
      :addAndOpenGuide="addAndOpenGuide"
      :hasSavedInvoiceId="hasSavedInvoiceId"
      :filters="filters"
      :allSelectableGuides="allSelectableGuides"
      @onDeleteGuide="confirmDelete"
      @onOrdenate="handleOrder"
      @selected-guides="setSelectedGuides"
      @unselectedChecks="setUnselectedChecks"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>

    <TissGuidesModalsContainer
      :patient="patient"
      :tissGuide="tissGuide"
      :clinicHealthPlan="clinicHealthPlan"
      :appointmentId="tissGuide.appointment_id"
      :getLastTissGuide="getLastTissGuide"
      :deleteTissGuide="deleteTissGuide"
      :clearTissGuide="clearTissGuide"
      :required_fields="tissRequiredCustomField"
      :getClinicHealthPlan="getClinicHealthPlan"
      :setTissGuide="setTissGuide"
      :getGuide="getGuide"
    />

    <TissGuideFilesContainer
      :file="file"
      :tissGuide="tissGuide"
      :files="files"
      :tissGuides="rows"
      :openDocumentModal="openDocumentModal"
      :openEditFileModal="openEditFileModal"
      :clearFile="clearFile"
      @hide-file-list-modal="hideFileListModal"
    />

    <TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />

    <NewTissGuideModal
      ref="newTissGuideModal"
      :closeModal="closeTissGuideModal"
      :getClinicHealthPlanProp="getClinicHealthPlanByPatient"
      :clinicHealthPlan="clinicHealthPlan"
      @createGuide="createGuide"
    />

    <SetInvoiceModal :selectedGuides="selectedGuides" @onSave="onSave" />
  </section>
</template>

<script>
import { parseNumberToMoney, parseMoneyToNumber } from '@/utils/moneyHelper'
import { handleGuideUpdate } from '@/utils/invoicingTissHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'GuideReportContainer',
  props: {
    filter: {
      type: Boolean,
      default: true
    },
    rowsProps: {
      type: Array,
      default: () => []
    },
    headersProps: {
      type: Array,
      default: () => []
    },
    subHeadersProps: {
      type: Array,
      default: () => []
    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  components: {
    GuidesReportFilter: () =>
      import('@/components/Tiss/Generic/Filters/GuidesReportFilter.vue'),
    GuideReportTable: () =>
      import('@/modules/tiss/manageGuides/components/GuideReportTable'),
    NewTissGuideModal: () =>
      import('@/components/Tiss/Modal/NewTissGuideModal.vue'),
    SetInvoiceModal: () =>
      import('@/components/Tiss/Modal/SetInvoiceModal.vue'),
    TissGuideAuditModal: () =>
      import('@/components/Tiss/Modal/TissGuideAuditModal.vue'),
    TissGuideFilesContainer: () =>
      import('@/layouts/InvoicingTiss/TissGuide/TissGuideFilesContainer.vue'),
    TissGuidesModalsContainer: () =>
      import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue')
  },
  data() {
    return {
      guideType: '',
      clinic: getCurrentClinic(),
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      tissGuide: {},
      clinicHealthPlan: {},
      tissGuideAudits: [],
      tissRequiredCustomField: {},
      file: {},
      files: [],
      patient: {},
      rows: this.rowsProps.length ? this.rowsProps : [],
      headers: this.headersProps.length
        ? this.headersProps
        : [
            'Situação',
            'Data',
            'Paciente/Guia',
            'Convênio',
            'Fatura/Lote',
            'Tipo de guia',
            'Faturado',
            'Recebido',
            'Conferido',
            ''
          ],
      subHeaders: this.subHeadersProps.length
        ? this.subHeadersProps
        : [
            'Situação',
            'Guia',
            'Atualização',
            'Tabela',
            'Cód. Item',
            'Descrição',
            'Valor faturado',
            'Valor pago'
          ],
      filters: {},
      allSelectableGuides: [],
      selectedGuides: [],
      hasSavedInvoiceId: false
    }
  },
  async created() {
    if (!this.headersProps.length) {
      await this.getGuides()
    }
  },
  methods: {
    parseNumberToMoney,
    parseMoneyToNumber,
    closeTissGuideModal() {
      this.clearTissGuide()
      this.$bvModal.hide('new-tiss-guide-modal')
    },
    createGuide(values) {
      this.patient = values.patient
    },
    async getGuide(tissGuide, notOpen) {
      const isLoading = this.$loading.show()
      try {
        const id = typeof tissGuide === 'object' ? tissGuide.id : tissGuide
        const response = await this.api.getTissGuideById(id)
        this.tissGuide = response.data

        this.patient = this.tissGuide.patient
        await this.getClinicHealthPlan(this.tissGuide.invoicing_tiss_setting_id)
        if (notOpen) return
        this.handleGuide(this.tissGuide.guide_type)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    setSelectedGuides(guides) {
      this.selectedGuides = guides
      this.$emit('selected-guides', guides)
    },
    onSave() {
      this.getGuides()
      this.hasSavedInvoiceId = true
    },
    setUnselectedChecks() {
      this.hasSavedInvoiceId = false
    },
    async getLastTissGuide(data) {
      if (data.isSus) {
        this.currentPage = 1
        this.getGuides()
        return
      }

      if (data.other_expenses) {
        this.currentPage = 1
        this.getGuides()
        return
      }
      if (data.id && !data.guide_id) {
        const tissGuide = await this.findByGuideIdReport(data.id)
        this.$refs.newTissGuideModal.updateHealthPlan()
        this.rows.unshift(tissGuide)
        if (!data?.notReopen) this.getGuide(tissGuide)
        return
      }
      const tissGuide = await this.findTissGuideByIdToReport(
        data.guide_id,
        true
      )
      const index = this.rows.findIndex(guide => guide.id === data.guide_id)
      this.$set(this.rows, index, tissGuide)
    },
    addAndOpenGuide(tissGuide) {
      this.rows.unshift(tissGuide)
      this.getGuide(tissGuide)
    },
    async findByGuideIdReport(id) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.findByGuideIdReport(id)
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async findTissGuideByIdToReport(id) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.findTissGuideByIdToReport(id)
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getClinicHealthPlan(invoicingTissSettingId) {
      if (!this.clinic.id || !invoicingTissSettingId) return
      try {
        const { data } = await this.api.findByClinicIdAndInvoicingTissSettingId(
          this.clinic.id,
          invoicingTissSettingId
        )
        this.clinicHealthPlan = data
        // this.tissRequiredCustomField =
        //   this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getClinicHealthPlanByPatient(patientHealthPlanId) {
      if (!this.clinic.id || !patientHealthPlanId) return
      try {
        const { data } = await this.api.getClinicHealthPlanByHealthId(
          this.clinic.id,
          patientHealthPlanId,
          false
        )
        if (!data) {
          this.$toast.warning('Convênio selecionado não vinculado a clínica')
          return
        }
        this.clinicHealthPlan = data

        // this.tissRequiredCustomField =
        //   this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateFilter(newFilter) {
      const types = [];
      types.TissGuideConsultation = 'App\\Models\\TissGuide\\TissGuideConsultation';
      types.TissGuideSpSadt = 'App\\Models\\TissGuide\\TissGuideSpSadt';
      types.TissGuideFee = 'App\\Models\\TissGuide\\TissGuideFee';

      this.filters = newFilter
      this.currentPage = 1
      await this.getGuides()
      if(this.filters.healthPlan){
        this.api.getGuidesByHealthPlanId(
          this.filters.healthPlan
        ).then(res => {
          this.allSelectableGuides = res.data
          if (this.filters.situation) {
            this.allSelectableGuides = this.allSelectableGuides.filter(guide => {
              return guide.situation === this.filters.situation;
            });
          }
          if (this.filters.guideType) {
            this.allSelectableGuides = this.allSelectableGuides.filter(guide => {
              return guide.guide_type === types[this.filters.guideType];
            });
          }
          if (this.filters.period) {
            const start = new Date(this.filters.period.start);
            const end   = new Date(this.filters.period.end);
            this.allSelectableGuides = this.allSelectableGuides.filter(guide => {
              const date = new Date(guide.created_at);
              return date >= start && date <= end;
            });
          }
          if (this.filters.procedures) {
            this.filters.procedures.forEach(el => {
              this.allSelectableGuides = this.allSelectableGuides.filter(guide => {
                return guide.items.find(i => i.description === el);
              });
            })
            
          }
        }).catch(error => {
          this.$toast.error(error.message)
        })
      }
    },
    async getGuides() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getTissByClinicId(
          this.clinic.id,
          this.currentPage,
          this.filters
        )
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.totalItems = data.total
        this.rows = data.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },

    openDocumentModal(tissGuide) {
      this.tissGuide = tissGuide
      this.$bvModal.show('tiss-guide-file-modal')
    },
    async getTissGuideFiles(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideFiles(tissGuideId)
        return response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openHistoric(tissGuideId) {
      await this.getTissGuideAudits(tissGuideId)
      this.$bvModal.show('tiss-guide-audit-modal')
    },
    async getTissGuideAudits(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideAudits(tissGuideId)
        this.tissGuideAudits = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openDocumentListModal(tissGuide) {
      this.tissGuide = tissGuide
      this.files = await this.getTissGuideFiles(tissGuide.id)
      this.$bvModal.show('tiss-guide-file-list-modal')
    },
    openEditFileModal(file) {
      this.file = file
      this.$bvModal.show('tiss-guide-file-modal')
    },
    openModalUpdateTiss({ tissGuide, type }) {
      this.tissGuide = tissGuide
      this.guide_type = type
      this.handleGuide()
    },
    clearFile() {
      this.file = {}
    },
    clearTissGuide() {
      this.tissGuide = {}
    },
    hideFileListModal(files) {
      if (files.length) {
        this.$set(this.tissGuide, 'files_count', files.length)
      } else {
        this.$set(this.tissGuide, 'files_count', 0)
      }
    },
    handleGuide(type) {
      const modal = handleGuideUpdate(type)
      this.$bvModal.show(modal)
    },
    async confirmDelete(tissGuide) {
      await this.deleteTissGuide(tissGuide)
    },
    async deleteTissGuide(tissGuide, decrease = false) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteTissGuide(tissGuide.id, decrease)
        this.$toast.success('Guia removida com sucesso!')
        this.rows.splice(
          this.rows.findIndex(row => row.id === tissGuide.id),
          1
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    setTissGuide(tissGuide) {
      this.tissGuide = null
      this.tissGuide = tissGuide
    },
    handleOrder(value) {
      this.$set(this.filters, 'order', value)
      this.updateFilter(this.filters)
    }
  },
  watch: {
    currentPage: {
      handler: async function () {
        await this.getGuides()
      }
    },
    rowsProps(value) {
      this.rows = value
    }
  }
}
</script>
