<template>
    <div class="forwarding-email-module-container">


    <!-- <div class="email-wrapper" v-if="this.attendance?.type === 'BV_EXAM'">
      <Check v-model="sentSmsToBv" :disabled="!canEdit" />
      <h5 class="label">
        Encaminhar paciente para telemedicina obrigatória
      </h5>
    </div> -->
    <div class="email-wrapper">
      <Check v-model="sentSms" :disabled="!canEdit" />
      <h5 class="label">
        Encaminhar documentos para o paciente, via SMS
      </h5>
    </div>
    <b-input
      class="mt-1"
      type="text"
      :disabled="!canEdit || !sentSms"
      placeholder="Telefone para encaminhamento dos documentos da consulta"
      v-model="form.forwarding_sms"
      @input="value => debounceChangeValue('maisInformacoes', value)"
      :state="smsState"
      :loading="loading"
      v-mask="['(##) ####-####', '(##) #####-####']"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getCurrentClinic } from '@/utils/localStorageManager'
import { debounce } from 'lodash'

export default {
  components: {
    Check: () => import('@/components/General/Check')
  },
  props:{
    attendance: Object
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      loading: false,
      sentSms: false,
    }
  },
  created(){
    this.debounceChangeValue = debounce(this.updateAttendance, 500)
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
    }),
    smsState() {
      return !this.form.forwarding_sms ? null : true
    }
  },
  methods: {
    getDefaultForm(){
      return {
        forwarding_sms: this.attendance?.forwarding_sms ?? null,
      }
    },

    updateAttendance(){
      // if(!this.validateEmail()) return
      this.loading = true
      this.api.updateAttendanceApi(this.attendance.id, { forwarding_sms: this.form.forwarding_sms })
      .then(() => {
        console.log('SMS para encaminhamento adicionado com sucesso!')
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => (this.loading = false));
    },

    validateEmail(){
      return !this.form.forwarding_sms || Boolean(String(this.form.forwarding_sms)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
    }
  },
  watch: {
    sentSms(checked) {
      console.log(this.attendance?.patient );
        this.form.forwarding_sms = checked ? (this.attendance?.patient?.cellphone || this.attendance.forwarding_sms || null) : null
        this.updateAttendance()
    }
  }
}
</script>
<style lang="scss" scoped>
.forwarding-email-module-container {
  margin-top: 38px;
  padding: 16px;
}
.label{
  font-weight: 600;
  font-size: 16px;
  color: var(--dark-blue);
  margin-left: -8px;
  margin-bottom: 2px;
}
.email-wrapper {
  display: flex;
  align-items: center;
}
</style>
