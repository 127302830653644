<template>
    <b-modal
      id="procedure-apac-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @show="onShow"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">
          <span v-if="procedure?.id">Atualizar</span>
          <span v-else>Novo Procedimento</span>
        </div>
        <span class="icon-box">
          <Close class="icon stroke" @click="closeModal" />
        </span>
      </div>
  
      <div class="around-content">
        <b-row class="mt-3">
          <b-col cols="12">
            <p class="form-title mb-3">
              Dados da execução do procedimento
            </p>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Procedimento">
              <ItemHealthPlanSimpleSelector 
                  :clinicHealthPlanId="clinicHealthPlanId"
                  :allowedTypes="['PROCEDURE','APPOINTMENT', 'EXAM', 'RETURN']"
                  v-model="procedure.item"
                  @select="setGuideItem"
                  :state="errors.procedure_item ? false : null"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Data de atendimento">
              <date-picker
                placeholder="Selecionar"
                id="start_datetime"
                v-model="procedure.attendance_date"
                format="DD/MM/YYYY"
                :clearable="false"
                class="full"
                :readonly="false"
                :state="errors.procedure_attendance_date ? false : null"
                ref="procedure_attendance_date"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_attendance_date">
                {{ errors.procedure_attendance_date }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Serviço">
              <b-form-input
                v-model="procedure.service"
                placeholder="000"
                autocomplete="off"
                v-mask="'###'"
                :state="errors.procedure_service ? false : null"
                ref="procedure_service"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_service">
                {{ errors.procedure_service }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Class">
              <b-form-input
                v-model="procedure.class"
                placeholder="000"
                autocomplete="off"
                v-mask="'###'"
                :state="errors.procedure_class ? false : null"
                ref="procedure_class"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_class">
                {{ errors.procedure_class }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Qtde.">
              <b-form-input
                v-model="procedure.quantity"
                placeholder="00"
                autocomplete="off"
                type="number"
                min="1"
                :state="errors.procedure_quantity ? false : null"
                ref="procedure_quantity"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_quantity">
                {{ errors.procedure_quantity }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Unitário">
              <money 
                id="unitary_value"
                v-model="procedure.unitary_value" 
                class="form-control"
                ref="unitary_value"
              />
              <b-form-invalid-feedback v-if="errors?.unitary_value">
                {{ errors.unitary_value }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Total">
              <money 
                id="total_value"
                v-model="procedure.total_value" 
                class="form-control"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-form-checkbox
              id="is-principal"
              v-model="procedure.is_principal"
              name="is-principal"
              class="col-form-label"
            >
              Procedimento Principal
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
  
      <div class="wrapper-button text-center mt-3">
        <b-button variant="primary" size="lg" @click="saveProcedure" v-if="readonly">
          Salvar procedimento
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>
  export default {
    name: 'ProcedureApacModal',
    components: {
      Close: () => import('@/assets/icons/close.svg'),
      ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
    },
    data() {
      return {
        attendanceCharacterOptions:[
          '1',
          '2',
          '3',
        ],
        errors: {}
      }
    },
    props: {
        type: String,
        procedure: {
            type: Object,
            default: () => ({}),
        },
        readonly: Boolean,
        clinicHealthPlanId: String,
        requestFields: []
    },
    watch: {
      'procedure.service'(value) {
        //
      },
      'procedure.class'(value) {
        //
      },
      'procedure.quantity'() {
        this.procedure.total_value = this.procedure.unitary_value * this.procedure.quantity
      },
      'procedure.unitary_value'() {
        this.procedure.total_value = this.procedure.unitary_value * this.procedure.quantity
      }
    },
    methods: {
      setGuideItem(item) {
        this.procedure.item = item;
        this.procedure.code = item.code;
        this.procedure.item_id = item.id;
        this.procedure.name = item.name;
        this.procedure.quantity = 1;
        this.procedure.unitary_value = item.health_plan_props.value;
        this.procedure.total_value = item.health_plan_props.value;
        this.procedure.service = item.health_plan_props.service_code;
        this.procedure.class = item.health_plan_props.class_code;
      },
      onShow() {
      },
      closeModal() {
        this.$bvModal.hide('procedure-apac-modal')
      },
      saveProcedure() {
        if (this.isValidForm()) {
          this.$toast.warning('Preencha todos os campos obrigatórios.')
          return
        }

        this.$emit('update-procedures', { ...this.procedure });
        this.closeModal();
      },
      isValidForm() {
        const errors = {};
        
        if(!this.procedure.item_id){
          errors.procedure_item = "Campo Obrigatório";
        }

        if(!this.procedure.quantity){
          errors.procedure_quantity = "Campo Obrigatório";
        }

        if(this.requestFields) {
          Object.keys(this.requestFields).map(item => {
            if (this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requestFields[item]) {
              errors[item] = "Campo obrigatório";
            }
          });
        }

        this.errors = errors;

        return Object.keys(errors).length;

      }
    },
  };
  </script>
  <style lang="scss">
  #procedure-apac-modal {
    .form-title {
      color: var(--neutral-600);
      font-size: 18px;
      font-weight: bolder;
      line-height: 28px;
    }
    .modal-header {
      .modal-title {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon-close {
        width: 25px;

        svg {
          fill: var(--neutral-500);
        }
      }
    }
    
    .col-form-label {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #0a184c;
        margin: 0;
        margin-bottom: 0px;
    }

    .invalid-feedback{
      display: block;
    }
}
</style>