import api from './api'
import Vue from 'vue'

export const checkCloudAuth = async (userId, item, reload = false) => {
  const loading = Vue.prototype.$loading.show();
  const data = {
    professional_id: userId,
    serviceName: item.name,
    customState: userId
  };
  const result = await api.webCertCheckAuth(data);
  loading.hide();
  if (result.data.authenticated === false) {
    window.open(result.data.url, '_blank');
  }else{
    if(reload){
      window.reload();
    }
  }
}