<template>
  <div class="attendance-container">
    <Header />
    <div class="content">
      <Menu />
      <Forms />
      <History />
    </div>

    <PreConsultationModal />
    <PauseAttendanceModal :attendanceRedirect="attendanceRedirect" />
    <PauseEssilorAttendanceModal :attendanceRedirect="attendanceRedirect" />
    <PrintMedicalRecordsModal />
    <AttendanceReviewModal />
    <SaveAsObjectiveRefractioEssilorModal
      :attendance="attendance"
      :clinic="clinic"
     />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { checkCloudAuth } from '@/utils/certificateHelper'

export default {
  components: {
    Header: () => import('@/components/Attendance/Header'),
    Menu: () => import('@/components/Attendance/Menu'),
    Forms: () => import('@/components/Attendance/Forms'),
    History: () => import('@/components/Attendance/History'),
    PreConsultationModal: () => import('@/components/Attendance/Forms/Components/PreConsultationModal'),
    PauseAttendanceModal: () => import('@/components/Attendance/Forms/Components/PauseAttendanceModal'),
    PauseEssilorAttendanceModal:() => import('@/components/Attendance/Forms/Components/PauseEssilorAttendanceModal'),
    PrintMedicalRecordsModal: () => import('@/components/Attendance/Forms/Components/PrintMedicalRecordsModal'),
    AttendanceReviewModal: () => import('@/components/Attendance/Forms/Components/AttendanceReviewModal'),
    SaveAsObjectiveRefractioEssilorModal:() => import('@/components/Attendance/Forms/Components/SaveAsObjectiveRefractioEssilorModal'),
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      patientId: this.$route.params.patientId,
      attendanceId: this.$route.params.attendanceId,
      attendanceRedirect: null,
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
      loading: state => state.attendance.loading,
    })
  },
  async mounted() {
    await this.getAttendance({
      clinicId: this.clinic.id,
      patientId: this.patientId,
      attendanceId: this.attendanceId
    })
    this.checkCertCloudAuth();
  },
  methods: {
    ...mapActions('attendance', ['getAttendance', 'clearAttendance']),
    ...mapActions('attendance/menu', ['clearHiddenGroup']),
    checkCertCloudAuth(){
      const item = JSON.parse(localStorage.getItem('singItemCloud'));
      if (item) {
        checkCloudAuth(this.attendance.professional_id, item, false);
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.attendanceRedirect = to.path
    if(this.canEdit && this.attendance.type === 'ESSILOR_ATTENDANCE'){
      next(false)
      return this.$bvModal.show('essilor-pause-modal')
    }
    if (this.canEdit && this.attendance.type === 'ATTENDANCE') {
      next(false)
      return this.$bvModal.show('pause-modal')
    } else {
      this.clearAttendance()
      this.clearHiddenGroup()
      next()
    }
  },
}
</script>
<style lang="scss" scoped>
  .attendance-container {
    height: calc(100vh - 170px);
    padding: 24px;
    .content {
      display: flex;
      height: 100%;
    }
  }
</style>
