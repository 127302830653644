<template>
  <div id="invoicing-tiss">
    <h3>Configurações do prestador</h3>
    <div class="config">
      <b-row>
        <b-col cols="5">
          <label for="code">Código de identificação do prestador</label>
          <multiselect
            id="providerCode"
            v-model="invoicingTissSetting.provider_code"
            placeholder="Selecionar"
            :options="providerCode"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
          <div
            v-if="validated && !invoicingTissSetting.provider_code"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <b-col cols="7">
          <label for="hiredCode">
            Código do contratado executante na operadora
          </label>
          <b-input
            placeholder="Descrever"
            v-model="invoicingTissSetting.hired_executant_code"
          />
          <div
            v-if="validated && !invoicingTissSetting.hired_executant_code"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <label for="hiredName">Nome do contratado executante</label>
          <b-input
            id="hiredName"
            placeholder="Descrever"
            v-model="invoicingTissSetting.hired_executant_name"
          />
          <div
            v-if="validated && !invoicingTissSetting.hired_executant_name"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <b-col cols="4">
          <label for="cnes">CNES</label>
          <b-input
            id="cnes"
            type="text"
            placeholder="Descrever"
            v-model="invoicingTissSetting.CNES"
          />
          <div v-if="validated && !invoicingTissSetting.CNES" class="custom-invalid-feedback">
            Campo obrigatório
          </div>
        </b-col>

        <b-col cols="4">
          <div class="form-group">
            <label for="title-body-content"> Utilizar nos tipos de guia </label>
            <validation-provider name="visualizar" :rules="{ required: true }">
              <multiselect
                id="visualizar"
                v-model="invoicingTissSetting.guides"
                :options="guideTypes"
                :close-on-select="false"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                :multiple="true"
                placeholder="Selecionar"
                class="multiple"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <div
                  slot="selection"
                  slot-scope="{ values }"
                  class="selectedGuides"
                >
                  <span>{{ values.join(', ') }}</span>
                </div>
              </multiselect>
            </validation-provider>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
    <h3>Grau de participação (%)</h3>
    <div class="config">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <b-table-simple table-variant="default" borderless>
              <b-thead>
                <b-tr>
                  <b-th>Grau Participação</b-th>
                  <b-th class="text-right" width="38%">Porcentagem</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(percentage, index) of filterPercentages(0, 12)"
                  :key="index"
                >
                  <b-td>{{ percentage.label }}</b-td>
                  <b-td class="text-right">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting[percentage.model]"
                        type="number"
                        class="input-table"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="invoicingTissSetting[percentage.model] < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
    <h3>Casos especiais</h3>
    <div class="config">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <b-table-simple table-variant="default" borderless>
              <b-thead>
                <b-tr>
                  <b-th>Casos</b-th>
                  <b-th class="text-right" width="20%">% Bilateral</b-th>
                  <b-th class="text-right" width="20%">% Mesma via</b-th>
                  <b-th class="text-right" width="20%">% Via diferente</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(percentage, index) of specialCasesPercentage"
                  :key="index"
                >
                  <b-td>
                    <span v-html="percentage.label"></span>
                    <span
                      v-if="percentage.label.includes('monocular')"
                      class="ml-2"
                    >
                      <b-tooltip
                        target="help"
                        triggers="hover"
                        placement="bottom"
                      >
                        Em um procedimento oftalmológico monocular realizado
                        bilateralmente, <br />
                        a remuneração do custo operacional será em 100% do valor
                        previsto <br />
                        nesta Classificação para um lado, e em
                        {{ invoicingTissSetting.operational_cost_monocular_bilateral - 100 }}%
                        para o outro.
                      </b-tooltip>
                      <HelpCircle class="icon" id="help" />
                    </span>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting[percentage.modelBilateral.model]"
                        type="number"
                        class="input-table"
                        :disabled="percentage.modelBilateral.disabled"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="invoicingTissSetting[percentage.modelBilateral.model] < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting[percentage.modelMesmaVia.model]"
                        type="number"
                        class="input-table"
                        :disabled="percentage.modelMesmaVia.disabled"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="invoicingTissSetting[percentage.modelMesmaVia.model] < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting[percentage.modelViaDiferente.model]"
                        type="number"
                        class="input-table"
                        :disabled="percentage.modelViaDiferente.disabled"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="invoicingTissSetting[percentage.modelViaDiferente.model] < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>

        <b-col cols="12" class="mt-4 full-width">
          <div class="card">
            <b-table-simple table-variant="default" borderless>
              <b-thead>
                <b-tr>
                  <b-th>Outros</b-th>
                  <b-th class="text-right" width="20%">% Urgência</b-th>
                  <b-th class="text-right" width="20%">% D.H.E</b-th>
                  <b-th class="text-right" width="20%">% Apartamento</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Outros</b-td>
                  <b-td class="text-right">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting.other_urgency"
                        type="number"
                        class="input-table"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="this.invoicingTissSetting.other_urgency < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting.other_dhe"
                        type="number"
                        class="input-table"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="invoicingTissSetting.other_dhe < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>

                  <b-td class="text-right">
                    <b-input-group append="%">
                      <b-input
                        v-model="invoicingTissSetting.other_apartment"
                        type="number"
                        class="input-table"
                        @input="validatePercentage"
                      />
                    </b-input-group>
                    <div
                      class="custom-invalid-feedback"
                      v-if="invoicingTissSetting.other_apartment < 0"
                    >
                      Insira um valor maior que 0
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
    <div class="config">
      <h3>Configurações gerais da operadora</h3>
      <b-row>
        <b-col cols="4">
          <label for="attendanceCharacter">
            Caráter do atendimento
            <span class="help">(opcional)</span>
          </label>
          <multiselect
            id="attendanceCharacter"
            v-model="invoicingTissSetting.attendance_character"
            placeholder="Selecionar"
            :options="attendanceCharacter"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="4">
          <label for="attendanceType">
            Tipo de atendimento
            <span class="help">(opcional)</span>
          </label>
          <multiselect
            id="attendanceType"
            v-model="invoicingTissSetting.attendance_type"
            placeholder="Selecionar"
            :options="attendanceTypes"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="4">
          <label for="hiredCode">
            Indicação de acidente
            <span class="help">(opcional)</span>
          </label>
          <multiselect
            id="accidentIndication"
            v-model="invoicingTissSetting.accident_indication"
            placeholder="Selecionar"
            :options="accidentIndications"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <label for="consultationType">
            Tipo de consulta
            <span class="help">(opcional)</span>
          </label>
          <multiselect
            id="consultationType"
            v-model="invoicingTissSetting.consultation_type"
            placeholder="Selecionar"
            :options="consultationTypes"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>
      </b-row>
    </div>
    <hr />
    <div class="config">
      <h3>Recebimento de faturas TISS</h3>
      <b-row>
        <b-col cols="4">
          <label for="bankAccount">Conta bancária</label>
          <multiselect
            id="bankAccount"
            :value="bankAccount"
            placeholder="Selecionar"
            :options="bankAccounts"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            @select="selectBankAccount"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
          <div
            v-if="validated && !invoicingTissSetting.bank_account_id"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <b-col cols="4">
          <label for="paymentMethod">Método de pagamento</label>
          <multiselect
            id="paymentMethod"
            :value="paymentMethod"
            placeholder="Selecionar"
            :options="paymentMethods"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            @select="selectPaymentMethod"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
          <div
            v-if="validated && !invoicingTissSetting.payment_method_id"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <b-col
          cols="4"
          class="invoicing-days-container"
          v-click-outside="
            () => {
              openedDaysOptions = false
            }
          "
        >
          <label class="top-label">Dias para geração da fatura</label>
          <div class="date-input-container">
            <label for="invoicingDays" class="input-days-label"
              >Todo dia:
            </label>
            <b-input
              readonly
              id="invoicingDays"
              type="text"
              class="invoicing-days"
              autocomplete="off"
              :value="invoice_create_days"
              placeholder=""
              @focus="
                () => {
                  openedDaysOptions = true
                }
              "
            />
          </div>
          <ul class="days-options" v-if="openedDaysOptions">
            <li v-for="(col, index) in daysOptions" :key="index">
              <button
                v-for="(option, i) in col"
                :key="i"
                @click="handleInvoicingCreateDay(option)"
                :class="{
                  'disabled-option':
                    (index === 0 && option === '30') ||
                    (index === 4 &&
                      (option === '1' || option === '2' || option === '3')),
                  'day-selected': invoice_create_days.split(',').find(el => {
                    return el === option
                  })
                }"
                :disabled="
                  (index === 0 && option === '30') ||
                  (index === 4 &&
                    (option === '1' || option === '2' || option === '3'))
                "
              >
                {{ option }}
              </button>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <label for="estimatePaymentDays">
            Tempo estimado para o pagamento (dias)
          </label>
          <b-form-input
            id="estimatePaymentDays"
            autocomplete="off"
            type="number"
            min="0"
            v-model="invoicingTissSetting.estimate_payment_time"
          />
          <div
            v-if="validated && !invoicingTissSetting.estimate_payment_time"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <b-col cols="6">
          <label for="estimateGlossesPaymentDays">
            Tempo estimado para o pagamento de glosas (dias)
          </label>
          <b-form-input
            autocomplete="off"
            id="estimateGlossesPaymentDays"
            type="number"
            min="0"
            v-model="invoicingTissSetting.estimate_glosses_payment_time"
          />
          <div
            v-if="validated && !invoicingTissSetting.estimate_glosses_payment_time"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />

    <div class="config">
      <h3>
        Procedimento padrão na autorização de guias de consulta
        <HelpCircle 
          class="icon" 
          v-b-tooltip.hover title="A seleção do procedimento padrão só será habilitada após a importação da tabela principal de procedimentos"        
        />
      </h3>

      <b-row>
        <b-col cols="12">
          <label for="procedure_id">Procedimento</label>
          <ItemSelect 
              :value="invoicingTissSetting.item"
              :types="['PROCEDURE','APPOINTMENT']"
              placeholder="Digite um identificador, código ou nome para buscar itens"
              @select="setItem"
          />
          <!-- <multiselect
            :loading="loading"
            id="procedure_id"
            :value="procedureSelected"
            placeholder="Buscar procedimento"
            :options="tissTableProcedures"
            :option-height="40"
            :showLabels="false"
            @select="setProcedure"
            @search-change="getProceduresDebounce"
            :searchable="true"
            :internal-search="false"
            class="with-border"
            
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <span class="d-inline-block text-truncate" style="width: 80%">
                {{ props.option.code }}
              </span>
            </template>
            <template slot="option" slot-scope="props">
              {{ props.option.code }} - {{ props.option.name }}
            </template>
            <template slot="noOptions">
              Digite para pesquisar procedimentos
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect> -->
          <div
            v-if="validated && !invoicingTissSetting.item_id"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <!-- <b-col cols="6">
          <label for="description">Descrição procedimento</label>
          <b-input
            id="description"
            v-model="invoicingTissSetting.procedure_description"
            readonly
          />
          <div
            v-if="validated && !invoicingTissSetting.procedure_description"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col> -->
      </b-row>
    </div>

    <div class="config">
      <h3>Exceções gerais sobre as guias</h3>
      <b-row class="ml-1">
          <b-form-checkbox
            id="hidePerformer"
            class="autoFillBtn"
            :width="55"
            :height="30"
            v-model="invoicingTissSetting.hide_performer"
            @input="onChangeHidePerformer"
            switch
          />
          <label for="hidePerformer" class="bold-text ml-2">
            Ocultar profissional executante no arquivo XML
          </label>
      </b-row>
        <b-col cols="4" class="mt-0">
          <TypeMultiSelector 
            :groupType="ITEM.ITEM_TYPE_PROCEDURE" 
            v-model="invoicingTissSetting.hide_performer_item_types" 
            label="Tipo de itens a serem ocultados"
            disabled
          />
          
        </b-col>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getCurrentClinic } from '@/utils/localStorageManager'
import * as ITEM from '@items/utils/constants'

export default {
  props: {
    invoicingTissSetting: Object,
    validated: Boolean
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    ItemSelect: () => import('@items/components/ItemSelect'),
    TypeMultiSelector: () => import('@/components/Tiss/components/SelectItemType')
  },
  async created() {
    this.getProceduresDebounce = debounce(this.searchTissTableProcedure, 500)

    const isLoading = this.$loading.show()
    await this.getBankAccounts()
    await this.getPaymentMethods()
    await this.findTissTableProcedure()
    this.setDefaultPercentage()
    this.getInvoiceCreateDays(this.invoicingTissSetting)
    this.hasActivatedTissProcedures()
    isLoading.hide()
  },
  data() {
    const clinic = getCurrentClinic()
    return {
      ITEM,
      date: String,
      invoice_create_days: '',
      clinic_id: clinic.id,
      bankAccounts: [],
      paymentMethods: [],
      tissTableProcedures: [],
      guideTypes: [
        'Guia de Consulta',
        'Guia SP/SADT',
        // 'Guia Resumo de internação',
        // 'Guia Solicitação de internação',
        'Guia de Honorários',
        'Anexo de outras despesas'
      ],
      providerCode: [
        'Código do Contratado na Operadora',
        'CPF do contratado',
        'CNPJ do contratado'
      ],
      attendanceCharacter: ['1 - Eletivo', '2 - Urgência/Emergência'],
      attendanceTypes:
        require('@/components/Tiss/Data/attendanceTypes.json').map(item => {
          return item.label
        }),
      accidentIndications: [
        '0 - Trabalho',
        '1 - Trânsito',
        '2 - Outros',
        '9 - Não Acidente'
      ],
      consultationTypes: [
        '1 - Primeira Consulta',
        '2 - Retorno',
        '3 - Pré-natal',
        '4 - Por encaminhamento'
      ],
      participationPercentages: [
        { label: '00 - Cirurgião', model: 'surgeon' },
        { label: '01 - Primeiro Auxiliar', model: 'first_assistant' },
        { label: '02 - Segundo Auxiliar', model: 'second_assistant' },
        { label: '03 - Terceiro Auxiliar', model: 'third_assistant' },
        { label: '04 - Quarto Auxiliar', model: 'fourth_assistant' },
        { label: '05 - Instrumentador', model: 'instrumentator' },
        { label: '06 - Anestesista', model: 'anesthetist' },
        { label: '12 - Clínico', model: 'clinical' }
      ],
      specialCasesPercentage: [
        {
          label: 'Honorário',
          modelBilateral: { model: 'honorary_bilateral' },
          modelMesmaVia: { model: 'honorary_same_way' },
          modelViaDiferente: { model: 'honorary_different_way' }
        },
        {
          label: 'Custo operacional',
          modelBilateral: { model: 'operational_cost_bilateral' },
          modelMesmaVia: { model: 'operational_cost_same_way' },
          modelViaDiferente: { model: 'operational_cost_different_way' }
        },
        {
          label: 'Custo de operacional (monocular)',
          modelBilateral: { model: 'operational_cost_monocular_bilateral' },
          modelMesmaVia: { disabled: true, model: '' },
          modelViaDiferente: { disabled: true, model: '' }
        },
        {
          label: 'Porte anestésico',
          modelBilateral: { model: 'anesthetic_port_bilateral' },
          modelMesmaVia: { model: 'anesthetic_port_same_way' },
          modelViaDiferente: { model: 'anesthetic_port_different_way' }
        },
        {
          label: 'M<sup>2</sup> Filme',
          modelBilateral: { model: 'm2_bilateral' },
          modelMesmaVia: { model: 'm2_same_way' },
          modelViaDiferente: { model: 'm2_different_way' }
        }
      ],
      canEditCreateDay: true,
      openedDaysOptions: false,
      daysOptions: [
        ['30', '1', '2', '3', '4', '5', '6'],
        ['7', '8', '9', '10', '11', '12', '13'],
        ['14', '15', '16', '17', '18', '19', '20'],
        ['21', '22', '23', '24', '25', '26', '27'],
        ['28', '29', '30', '31', '1', '2', '3']
      ],
      getProceduresDebounce: Function,
      loading: false,
      canChooseProcedure: false,
      hide_performer: this.invoicingTissSetting.hide_performer,
    }
  },
  computed: {
    filterPercentages() {
      return (start, end) => this.participationPercentages.slice(start, end)
    },
    bankAccount() {
      return this.bankAccounts.find(
        bankAccount => bankAccount.value === this.invoicingTissSetting.bank_account_id
      )
    },
    paymentMethod() {
      return this.paymentMethods.find(
        paymentMethod => paymentMethod.value === this.invoicingTissSetting.payment_method_id
      )
    }
  },
  watch: {
    invoicingTissSetting: {
      handler(value) {
        this.getInvoiceCreateDays(value)
        this.$emit('updateTiss', value)
      },
      deep: true
    },
    invoice_create_days: function (value) {
      this.$emit('update-invoice-days', value)
    }
  },
  methods: {
    getInvoiceCreateDays(invoicingTissSetting) {
      if (this.isJson(invoicingTissSetting.invoice_create_days)) {
        const data = JSON.parse(invoicingTissSetting.invoice_create_days)
        if (data.length > 1) {
          this.invoice_create_days = data.join(',')
        } else {
          this.invoice_create_days = data.toString()
        }
      } else if (typeof invoicingTissSetting.invoice_create_days === 'object') {
        this.invoice_create_days = invoicingTissSetting.invoice_create_days.join(',')
      } else {
        this.invoice_create_days = invoicingTissSetting.invoice_create_days
      }
    },
    selectBankAccount(value) {
      this.invoicingTissSetting.bank_account_id = value.value
    },
    selectPaymentMethod(value) {
      this.invoicingTissSetting.payment_method_id = value.value
    },
    setDefaultPercentage() {
      this.invoicingTissSetting.surgeon = this.invoicingTissSetting.id ? this.invoicingTissSetting.surgeon : 100
      this.invoicingTissSetting.first_assistant = this.invoicingTissSetting.id ? this.invoicingTissSetting.first_assistant : 30
      this.invoicingTissSetting.second_assistant = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.second_assistant
        : 20
      this.invoicingTissSetting.third_assistant = this.invoicingTissSetting.id ? this.invoicingTissSetting.third_assistant : 20
      this.invoicingTissSetting.fourth_assistant = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.fourth_assistant
        : 20
      this.invoicingTissSetting.instrumentator = this.invoicingTissSetting.id ? this.invoicingTissSetting.instrumentator : 10
      this.invoicingTissSetting.anesthetist = this.invoicingTissSetting.id ? this.invoicingTissSetting.anesthetist : 100
      this.invoicingTissSetting.clinical = this.invoicingTissSetting.id ? this.invoicingTissSetting.clinical : 100

      this.invoicingTissSetting.honorary_bilateral = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.honorary_bilateral
        : 170
      this.invoicingTissSetting.honorary_same_way = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.honorary_same_way
        : 50
      this.invoicingTissSetting.honorary_different_way = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.honorary_different_way
        : 70

      this.invoicingTissSetting.operational_cost_monocular_bilateral = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.operational_cost_monocular_bilateral
        : 170

      this.invoicingTissSetting.anesthetic_port_bilateral = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.anesthetic_port_bilateral
        : 170
      this.invoicingTissSetting.anesthetic_port_same_way = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.anesthetic_port_same_way
        : 50
      this.invoicingTissSetting.anesthetic_port_different_way = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.anesthetic_port_different_way
        : 70

      this.invoicingTissSetting.m2_bilateral = this.invoicingTissSetting.id ? this.invoicingTissSetting.m2_bilateral : 170
      this.invoicingTissSetting.m2_same_way = this.invoicingTissSetting.id ? this.invoicingTissSetting.m2_same_way : 70
      this.invoicingTissSetting.m2_different_way = this.invoicingTissSetting.id
        ? this.invoicingTissSetting.m2_different_way
        : 70

      this.invoicingTissSetting.other_urgency = this.invoicingTissSetting.id ? this.invoicingTissSetting.other_urgency : 130
      this.invoicingTissSetting.other_dhe = this.invoicingTissSetting.id ? this.invoicingTissSetting.other_dhe : 200
      this.invoicingTissSetting.other_apartment = this.invoicingTissSetting.id ? this.invoicingTissSetting.other_apartment : 200
    },
    isJson(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    validatePercentage($event) {
      const percentage = parseFloat($event.target.value)
      if (percentage < 0) {
        this.$emit('invalidPercentage', true)
        return
      }
      this.$emit('invalidPercentage', false)
    },
    fillOptions(data) {
      return data.map(el => ({
        label: `${el.name}`,
        value: `${el.id}`
      }))
    },
    fillTussOptions(data) {
      const result = []
      data.forEach(el => {
        const procedure = el.procedure
        procedure.id = el.id
        result.push(procedure)
      })
      return result
    },
    async getBankAccounts() {
      try {
        const response = await this.api.getBankAccount(this.clinic_id)
        this.bankAccounts = this.fillOptions(response.data)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getPaymentMethods() {
      try {
        const response = await this.api.getPaymentMethods(this.clinic_id)
        this.paymentMethods = this.fillOptions(response.data)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async searchTissTableProcedure(query) {
      if (query.length > 2) {
        this.tissTableProcedures = []
        this.loading = true
        try {
          const response = await this.api.getTissTableProcedures(
            this.invoicingTissSetting.invoicing_tiss_setting_id,
            query
          )
          this.tissTableProcedures = this.fillTussOptions(response.data)
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          this.loading = false
        }
      }
    },
    async findTissTableProcedure() {
      const procedureId = this.invoicingTissSetting.tiss_table_procedure_id
      if (!procedureId) return
      try {
        const response = await this.api.findTissTableProcedureById(procedureId)
        this.tissTableProcedures = this.fillTussOptions([response.data])
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    handleInvoicingCreateDay(option) {
      if (
        this.invoice_create_days.substring(
          this.invoice_create_days.length - 1
        ) === ','
      ) {
        this.canEditCreateDay = false
        this.invoice_create_days = this.invoice_create_days.substring(
          0,
          this.invoice_create_days.length - 1
        )
        this.canEditCreateDay = true
      }

      let arraySelectedOptions = this.invoice_create_days.split(',')
      arraySelectedOptions = arraySelectedOptions.sort((a, b) => {
        return parseInt(a) - parseInt(b)
      })
      this.canEditCreateDay = false
      this.invoice_create_days = ''
      if (
        arraySelectedOptions.find(el => {
          return el === option
        })
      ) {
        arraySelectedOptions.map((el, index) => {
          if (el !== option) {
            if (index > 0) this.invoice_create_days += ','
            this.invoice_create_days += el
          }
        })
      } else {
        arraySelectedOptions.push(option)
        arraySelectedOptions = arraySelectedOptions.sort((a, b) => {
          return parseInt(a) - parseInt(b)
        })
        arraySelectedOptions.map((el, index) => {
          if (index > 0) this.invoice_create_days += ','
          this.invoice_create_days += el
        })
      }

      if (!parseInt(this.invoice_create_days.substring(0, 1))) {
        this.invoice_create_days = this.invoice_create_days.substring(
          1,
          this.invoice_create_days.length
        )
      }
      this.canEditCreateDay = true
    },
    setItem(item) {
      this.invoicingTissSetting.item = item;
      this.invoicingTissSetting.procedure_description = item.name;
      // this.invoicingTissSetting.tiss_table_procedure_id = item.id
      this.invoicingTissSetting.item_id = item.id;
    },
    async hasActivatedTissProcedures() {
      try {
        const {data} = await this.api.hasActivatedTissProcedures(this.invoicingTissSetting.invoicing_tiss_setting_id)
        this.canChooseProcedure = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onChangeHidePerformer(value) {
        if (value) {
          this.invoicingTissSetting.hide_performer_item_types =  [ITEM.ITEM_TYPE_EXAM]
        } else {
          this.invoicingTissSetting.hide_performer_item_types =  []
        }
    }
  }
}
</script>

<style lang="scss">
#invoicing-tiss {
  margin-top: 2em;

  h3 {
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--blue-500);
  }

  .config {
    .input-group:focus-within .input-group-prepend .input-group-text,
    .form-control:focus ~ .input-group-append .input-group-text {
      border-color: var(--blue-500);
      border-left: none;
    }
    margin-top: 25px;
    width: 98%;
    padding: 0px;

    .card {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #a4b1df;
      border-radius: 8px !important;
    }

    label {
      font-family: Nunito Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--dark-blue);
    }

    & > div {
      margin-top: 15px;
    }

    .invoicing-days-container {
      display: flex;
      flex-direction: column;
      width: 400px !important;

      .date-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 6.9px;

        .input-days-label {
          z-index: 2;
          position: relative;
          margin-bottom: 0;
          margin-left: 8px;
        }

        input {
          position: absolute;
          padding-left: 23%;
          &:read-only {
            background-color: var(--default-white);
          }
        }
      }

      .days-options {
        list-style: none;
        display: flex;
        flex-direction: column;
        z-index: 11;
        margin-top: 85px;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        background: var(--neutral-000);
        border-radius: 8px;
        padding: 10px;
        border: 1px solid var(--neutral-300);
        box-shadow: 5px 5px 5px var(--neutral-200);
        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            transition: 0.3s;
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            color: var(--type-active);

            &:hover {
              transition: 0.3s;
              background-color: var(--neutral-100);
            }
          }

          .day-selected {
            transition: 0.3s;
            background-color: #305bf2 !important;
            color: var(--neutral-000);
            &:hover {
              transition: 0.3s;
              background-color: #ff6b00 !important;
            }
          }

          .disabled-option {
            font-weight: 500 !important;
            color: var(--neutral-400) !important;
            background-color: var(--neutral-000) !important;
            &:hover {
              transition: 0.3s;
              background-color: var(--neutral-000) !important;
            }
          }
        }
      }
    }

    table {
      width: 98%;
      margin: 0 auto;
      thead {
        background-color: unset !important;
      }
      th {
        border-bottom: 2px solid var(--neutral-300);
        font-size: 14px !important;
        padding-right: calc(12px + (130px / 4));
      }
      &.full-width {
        width: 100% !important;
      }
      tr {
        border-bottom: 1px dashed var(--neutral-300);
        &:last-child {
          border-bottom: unset;
        }
      }
      td {
        border-top: none;
        font-size: 16px !important;
        vertical-align: middle;
        .icon {
          width: 24px;
          height: 24px;
          stroke: var(--blue-500);
        }
        .input-group {
          width: 130px !important;
          float: right;
          .input-table {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: none !important;
            height: 40px !important;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .multiple {
      border: 1px solid var(--neutral-300);
      border-radius: 8px;
      margin: 4px 0 20px 0;
      height: 38px !important;
      box-sizing: border-box;

      .selectedGuides {
        height: 70% !important;
        overflow: hidden !important;
      }

      .multiselect__tags {
        height: 100%;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-top: -6px;
      cursor: pointer;
    }
  }
}
</style>
