<template>
  <b-modal
    id="bpa-sus-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
    no-close-on-backdrop
    no-close-on-esc 
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="localSusGuide?.id">
          Atualizar guia BPA-I (Boletim de Produção Ambulatorial Dados Individualizados) - N° {{ localSusGuide?.guide?.guide_number }}
        </span>
        <span v-else>
          Nova guia BPA-I (Boletim de Produção Ambulatorial Dados Individualizados) - N° {{ sus_information.current_bpa_guide_number + 1 }}
        </span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content">
      <!-- Estabelecimento -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do estabelecimento de saúde (Solicitante)</p>
        </b-col>
        <b-col class="mt-3" cols="10">
          <b-form-group>
            <label for="name_establishment">Nome do estabelecimento de saúde</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="clinic.name"
              :readonly="true"
              :state="errors?.name_establishment ? false : null"
              ref="name_establishment"
            />
            <b-form-invalid-feedback v-if="errors?.name_establishment">
              {{ errors.name_establishment }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="cnaes_code">CNES</label>
            <b-form-input
              id="cnaes_code"
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="sus_information.cnaes_code"
              :readonly="true"
              :state="errors?.cnaes_code ? false : null"
              ref="cnaes_code"
            />
            <b-form-invalid-feedback v-if="errors?.cnaes_code">
              {{ errors.cnaes_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Profissional -->
      <b-row class="mt-3">
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do Profissional</p>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000000000000000"
              autocomplete="off"
              class="form-control"
              v-model="form.professional.cns"
              :readonly="true"
              :state="errors?.professional_cns ? false : null"
              ref="professional_cns"
            />
            <b-form-invalid-feedback v-if="errors?.professional_cns">
              {{ errors.professional_cns }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="professional_name">Nome do profissional</label>
            <ProfessionalFieldModule
              :professional="form.professional"
              :clinic="clinic"
              :readonly="readonly"
              @update-professional="updateProfessional"
              ref="professional_name"
            />
            <b-form-invalid-feedback v-if="errors?.professional_name">
              {{ errors.professional_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cbo">CBO</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.professional.cbo"
              :readonly="true"
              :state="errors?.professional_cbo ? false : null"
              ref="professional_cbo"
            />
            <b-form-invalid-feedback v-if="errors?.professional_cbo">
              {{ errors.professional_cbo }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="year_month">Competência</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.year_month"
              name="year_month"
              format="MM/YYYY"
              value-type="date"
              id="year_month"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.year_month ? false : null"
              :disabled="readonly || !!localSusGuide?.sus_batch_id"
              ref="year_month"
            />
            <b-form-invalid-feedback v-if="errors?.year_month">
              {{ errors.year_month }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-form-group>
            <label for="team">Equipe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.team"
              :readonly="readonly"
              :state="errors?.team ? false : null"
              ref="team"
            />
            <b-form-invalid-feedback v-if="errors?.team">
              {{ errors.team }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="sheet">Folha</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.sheet"
              :readonly="true"
              ref="sheet"
            />
            <b-form-invalid-feedback v-if="errors?.sheet">
              {{ errors.sheet }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Paciente -->
      <b-row class="mt-3">
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do Paciente (Sequência 1)</p>
        </b-col>  
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cns"
              :readonly="readonly"
              :state="errors?.patient_cns ? false : null"
              ref="patient_cns"
            />
            <b-form-invalid-feedback v-if="errors?.patient_cns">
              {{ errors.patient_cns }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>      
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_name">Nome do paciente</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name"
              :readonly="readonly"
              :state="errors?.patient_name ? false : null"
              ref="patient_name"
              />
            <b-form-invalid-feedback v-if="errors?.patient_name">
              {{ errors.patient_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_gender">Sexo</label>
            <b-form-radio-group
              id="patient_gender"
              v-model="form.patient.gender"
              name="patient_gender"
              class="pt-2"
              :disabled="readonly"
            >
              <b-form-radio value="M">Masc.</b-form-radio>
              <b-form-radio value="F">Fem.</b-form-radio>
            </b-form-radio-group>
            <b-form-invalid-feedback v-if="errors?.patient_gender">
              {{ errors.patient_gender }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_birthday">Data de nascimento</label>
            <b-form-input
              v-model="form.patient.birthday"
              id="patient_birthday"
              name="patient_birthday"
              class="full"
              type="date"
              :readonly="readonly"
              :state="errors?.date_birth_bpa ? false : null"
              ref="date_birth_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.date_birth_bpa">
              {{ errors.date_birth_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_nationality">Nacionalidade</label>
              <multiselect
                id="nationality"
                autocomplete="off"
                v-model="selectedNationality"
                @input="changeSelectedNationality"
                :options="nationalities"
                track-by="id"
                label="label"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                placeholder="Selecione um país"
                class="with-border"
                :disabled="readonly"
                ref="nationality_bpa"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            <b-form-invalid-feedback v-if="errors?.nationality_bpa">
              {{ errors.nationality_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
              <label for="patient_ethnicity">
                Raça/Cor
                <span id="help-icon-ancestry" class="icon-box help-icon" v-b-tooltip.hover title="Quando o campo “Raça/cor” for selecionado “Indígena” o campo “Etnia” será obrigatório.">
                  <HelpCircle class="icon stroke" />
                </span>
              </label>
              <multiselect
                id="ethinicity"
                v-model="selectedEthnicity"
                :options="ethnicityOptions"
                @input="changeSelectedEthnicity"
                track-by="id"
                label="label"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                :disabled="readonly"
                ref="race_color_bpa"
              >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.race_color_bpa ? false : null">
              {{ errors.race_color_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_ancestry">
                Etnia
              </label>
              <multiselect
                id="ancestry"
                v-model="selectedAncestry"
                @input="changeSelectedAncestry"
                track-by="id"
                label="label"
                :options="ancestryOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                :disabled="selectedEthnicity?.code !== '05' || readonly"
                ref="ethnicity_bpa"
              >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.ethnicity_bpa ? false : null">
              {{ errors.ethnicity_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_zipcode">CEP</label>
            <b-form-input
              v-mask="'#####-###'"
              placeholder="00000-000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.zipCode"
              :readonly="readonly"
              :state="errors?.cep_bpa ? false : null"
              @keyup="searchCEP"
              ref="cep_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.cep_bpa">
              {{ errors.cep_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_ibge_code">Cód. IBGE Município</label>
            <b-form-input
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.ibge_code"
              :readonly="readonly"
              :state="errors?.ibge_bpa ? false : null"
              ref="ibge_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.ibge_bpa">
              {{ errors.ibge_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_street_type">Tipo Logradouro</label>
            <multiselect
              id="patient_street_type"
              v-model="selectedStreetType"
              @input="changeSelectedStreetType"
              track-by="code"
              label="label"
              :options="streetTypeOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecione"
              class="with-border"
              :disabled="readonly"
              ref="street_code_bpa"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.street_code_bpa">
              {{ errors.street_code_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address">Logradouro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.address"
              :readonly="readonly"
              :state="errors?.address_bpa ? false : null"
              ref="address_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.address_bpa">
              {{ errors.address_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_number">Número</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.number"
              :readonly="readonly"
              :state="errors?.number_bpa ? false : null"
              ref="number_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.number_bpa">
              {{ errors.number_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_number">Complemento</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.complement"
              :readonly="readonly"
              :state="errors?.complement_bpa ? false : null"
              ref="complement_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.complement_bpa">
              {{ errors.complement_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_neighborhood">Bairro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.neighborhood"
              :readonly="readonly"
              :state="errors?.neighborhood_bpa ? false : null"
              ref="neighborhood_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.neighborhood_bpa">
              {{ errors.neighborhood_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_city">Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.city"
              :readonly="readonly"
              :state="errors?.patient_address_city ? false : null"
              ref="patient_address_city"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_city">
              {{ errors.patient_address_city }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_state">Estado</label>
            <multiselect
              id="addressState"
              v-model="selectedAddressState"
              @input="changeSelectedAddressState"
              track-by="id"
              label="label"
              :options="statesOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="UF"
              class="with-border"
              :disabled="readonly"
              ref="state_bpa"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.state_bpa">
              {{ errors.state_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_phone_number">Telefone de contato</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cellphone"
              :readonly="readonly"
              :state="errors?.contact_telephone_bpa ? false : null"
              ref="contact_telephone_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.contact_telephone_bpa">
              {{ errors.contact_telephone_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_address_neighborhood">E-mail</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.email"
              :readonly="readonly"
              :state="errors?.e_mail_bpa ? false : null"
              ref="e_mail_bpa"
              />
            <b-form-invalid-feedback v-if="errors?.e_mail_bpa">
              {{ errors.e_mail_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <ProceduresPerformed
        :checkedItems="procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="sus_information?.clinic_health_plan?.id"
        @update-procedures="updateProcedures"
        :requestFields="requiredField"
        :type="'bpa'"
      />

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="localSusGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updateSusGuide"
          :disabled="localSusGuide?.situation === 'Cancelada'"
          v-if="localSusGuide && localSusGuide.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import ProceduresPerformed from './Modules/ProceduresPerformed.vue';
import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
import { saveAs } from 'file-saver'
import api from '@/modules/sus/api'
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProceduresPerformed,
  },
  props: {
    sus_information_setting: Object,
    patient: {
      type: [Object, null],
      required: true,
    },
    clinicHealthPlan: {
      type: Object
    },
    susGuide: {
      type: Object,
      default: () => ({})
    },
    checkedItems: Array,
    appointmentId: String,
    clinicHealthPlanId: String
  },
  data() {
    return {
      isShow: false,
      localPatient: { ...this.patient },
      isUpdatingPatient: false,
      user: getCurrentUser(),
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      localSusGuide: null,
      procedures: [],
      readonly: this.isReadonly(),
      requiredField: [],
      sus_information: {},
      errors: {},
      nationalities: [],
      statesOptions: [],
      ancestryOptions: [],
      ethnicityOptions: [],
      streetTypeOptions: [
        { code: '008', label: '008 - Avenida (AV)' },
        { code: '081', label: '081 - Rua (R)' },
        { code: '004', label: '004 - Alameda (AL)' },
        { code: '100', label: '100 - Travessa (TR)' },
        { code: '065', label: '065 - Praça (PÇA)' },
        { code: '031', label: '031 - Estrada (EST)' },
        { code: '090', label: '090 - Rodovia (ROD)' },
        { code: '054', label: '054 - Largo (LGO)' },
        { code: '104', label: '104 - Vila (VL)' },
        { code: '011', label: '011 - Beco (BCO)' },
      ],
      selectedAncestry: null,
      selectedEthnicity: null,
      selectedNationality: null,
      selectedStreetType: null,
      selectedAddressState: null,
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
    };
  },
  async mounted() {
    try {
      const { data } = await api.getInformationSusSettingByClinicId(this.clinic.id);
      this.sus_information = data;

      this.getRequiredFields();
    
      this.getAncestries();
      this.getEthnicities();
      this.getNationalities();
      this.getStates();

    } catch (error) {
      console.error('Erro ao carregar dados do SUS', error);
    }
  },
  methods: {
    isReadonly() {
      this.readonly = false;
      
      if(this.localSusGuide?.id){
        this.readonly = Boolean(
          this.localSusGuide?.id &&
          (this.localSusGuide.sus_batch !== null && ( this.localSusGuide?.sus_batch?.status === SusBatch.BATCH_STATUS_FINISHED) )
        ); 
      }    
    },
    getCheckedItems(){
      if(this.checkedItems){
        this.procedures = this.checkedItems.map((item, index) => ({
          id: null,
          item: item.item,
          item_id: item.item_id,
          code: item.item.code,
          name: item.item.name,
          attendance_date: moment(item?.appointment?.start_datetime).toDate(),
          quantity: 1,
          appointment_item_id: item.id,
          unitary_value: item.item.health_plan_props.value,
          total_value: item.item.health_plan_props.value,
          is_principal: index === 0
        }));
      }
    },
    getDefaultForm(){
      return {
        professional: { 
          ...this.professional || 
          {
            cns: '',
            cbo: '',
            name: '',
          }
        },
        patient: {
            cns: '',
            name: '',
            gender: '',
            birthday: '',
            email: '',
            nationality: null,
            ethnicity: null,
            ancestry: null,
            address: {
              zipCode: '',
              ibge_code: '',
              street_code: '',
              street_type: '',
              address: '',
              number: '',
              complement: ''
            }
        },
        year_month: null,
        team: '',
        sheet: '1',
        procedures: [],
      }
    },
    async getRequiredFields() {
      try {
        const requiredfield = {};
        if (this.clinic.id) {
          const { data } = await api.getSusGuideRequiredFields(this.clinic.id);
          
          if(data.required_fields?.BPA){
            Object.values(data.required_fields?.BPA).map(group => {
              Object.keys(group).map((field)  => {
                requiredfield[`${field}`] = group[field];
              });
            });
          }
        }

        this.requiredField = requiredfield;        
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onShow() {
      this.isShow = true;

      this.isReadonly();

      if(this.localSusGuide && Object.values(this.localSusGuide).length > 1){
        this.updateLocalSusGuide(this.localSusGuide);  
      }
      
      if(this.patient && Object.values(this.patient).length > 1){
        this.updateLocalPatient(this.patient);
      }

      this.getCheckedItems();      
    },
    closeModal() {
      this.isShow = false;
      this.form = this.getDefaultForm();
      this.selectedAncestry = null;
      this.selectedEthnicity = null;
      this.selectedAddressState = null;
      this.selectedNationality = null;
      this.procedures = [];
      this.form.procedures = [];
      this.localPatient = null;
      this.localSusGuide = null;
      this.$emit('onHide');
      this.$bvModal.hide('bpa-sus-guide-modal');
    },
    async searchCEP() {
      if (this.form.patient?.address?.zipCode.length === 9) {
        if (this.form.patient?.address?.zipCode.length === 9) {
          try {
            const res = await axios.get(`https://viacep.com.br/ws/${this.form.patient.address.zipCode}/json/`);    

            this.form.patient.address.address = res.data.logradouro;
            this.form.patient.address.neighborhood = res.data.bairro;
            this.form.patient.address.state = this.statesOptions.find(item => item.uf === res.data.uf);
            this.selectedAddressState = this.statesOptions.find(item => item.uf === res.data.uf);
            this.form.patient.address.city = res.data.localidade;
            this.form.patient.address.ibge_code = res.data.ibge;	

            this.$refs.number_bpa.focus()
          } catch (error) {
            this.$toast.errpr('Erro ao buscar o endereço');
            console.error("Erro ao buscar o endereço:", error);
          }
        }
      }
    },
    isValidForm() {
      const errors = {};
      
      if(this.form.procedures.length === 0) {   
        errors.procedures = "Campo obrigatório";     
        this.$toast.error('Obrigatório incluir procedimento a guia!');
      }

      if(!this.selectedNationality){
        errors.nationality_bpa = "Campo obrigatório";
      }

      if(!this.selectedNationality && !this.form.patient.nationality?.is_sus){
        errors.nationality_bpa = "Nacionalidade não é aceita pelo convênio";        
      }
      
      if (this.selectedEthnicity?.code === '05' && !this.selectedAncestry) {
        errors.ethnicity_bpa = "Campo obrigatório, quando raça/cor for igual Indígena";
      }

      if(!this.selectedNationality){
        errors.nationality_bpa = "Campo obrigatório";
      }

      if(!this.isValidCPF(this.form.patient.cpf)){
        // errors.patient_cpf = "CPF Inválido.";
      }

      Object.keys(this.requiredField).map(item => {
        if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requiredField[item]){
          errors[item] = "Campo obrigatório";
        }
      });

      if(this.requiredField.professional_name && !this.form.professional?.id){
        errors.professional_name = "Campo obrigatório";
      } else {
        delete errors.professional_name;
      }

      if(this.requiredField.patient_gender && !this.form.patient?.gender){
        errors.patient_gender = "Campo obrigatório";
      } else {
        delete errors.patient_gender;
      }

      this.errors = errors;

      return Object.keys(errors).length;
    },
    getPayload(){
      const payload = {
        sus_information_id: this.sus_information.id,
        guide_number: this.sus_information.current_bpa_guide_number,
        clinic_health_plan_id: this.sus_information.clinic_health_plan.id,
        clinic_id: this.clinic.id,
        professional_id: this.form.professional.id,
        year_month: this.form.year_month,
        competency: this.form.year_month,
        team: this.form.team,
        sheet: this.form.sheet,
        patient_id: this.form.patient.id,
        address: {
          ...this.form.patient.address,
          state: this.selectedAddressState ? this.selectedAddressState.acronym : null
        },
        procedures: this.form.procedures,
        guide_type: "BPA",
        appointment_id: this.appointmentId,
        patient: {
          ...this.form.patient,
          nationality: this.selectedNationality ? this.selectedNationality.value : null,
          ancestry: this.selectedAncestry ? this.selectedAncestry.value :  null,
          ethnicity: this.selectedEthnicity ? this.selectedEthnicity.value :  null,
        },
      };

      return payload;
    },
    async createGuide() {      
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.createSusGuide(payload);
        
        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });

        this.$emit('onHide');

        this.$toast.success('Guia BPA criada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateSusGuide() {
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.updateSusGuide(this.localSusGuide.id, payload);

        this.$emit('onHide');

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia BPA atualizada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateProfessional(professional) {
      this.form.professional = professional;
    },
    updateProcedures(procedures) {
      this.form.procedures = procedures;
    },
    async searchNationality(query) {
      if (query.length > 2) {
        this.nationalities = []
        try {
          const response = await this.api.searchNationality({ term: query })
          this.nationalities = response.data.map(nationality => {
            return {
              label: `${nationality.value}`,
              id: nationality.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async print() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await api.printSusGuide(this.localSusGuide.id, getCurrentUser()?.name) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, 'Guia BPA')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateLocalSusGuide(susGuide) {
      if (Object.values(susGuide).length > 0) {
        this.localSusGuide = { ...susGuide };    
        
        const isLoading = this.$loading.show();
        
        this.form = {
          ...susGuide,
          procedures: { ...this.localSusGuide?.guide?.procedures || [] },
          team: this.localSusGuide?.guide?.team || '',
          sheet: this.localSusGuide?.guide?.sheet || 1,
          year_month: this.localSusGuide?.guide?.date ? moment(this.localSusGuide.guide.date).toDate() : null,
          professional: this.localSusGuide?.professional || {},
        };

        this.updateLocalPatient(this.localSusGuide?.patient);

        if(this.localSusGuide?.professional?.sus_professional_setting){
          this.form.professional.cns = this.localsusGuide.professional?.sus_professional_setting?.cns;
          this.form.professional.cbo = this.localsusGuide.professional?.sus_professional_setting?.cbo;
        }

        if(this.localSusGuide?.guide?.procedures){
          this.procedures = this.localSusGuide.guide?.procedures.map(item => ({
              'id': item.id,
              'item': item.item,
              'item_id': item.item_id,
              'code': item.item.code,
              'name': item.item.name,
              'attendance_date': moment(item.attendance_date).toDate(),
              'quantity': item.quantity,
              'appointment_item_id': item.appointment_id,
              'total_value': item.total_value,
              'unitary_value': item.unitary_value,
              'is_principal': false,
              'service': item.service,
              'class': item.class,
              'cid': {
                ...item.cid,
                label: item.cid ? (item.cid.code + ' - ' + item.cid.description) : ''
              },
              'attendance_character': item.character_service ? {
                label: `${item.character_service.code} - ${item.character_service.description}`,
                code: item.character_service.code,
                description: item.character_service.description,
                id: item.character_service.id
              } : {},
              'authorization_number': item.authorization_number
          
          }));
        }
        
        isLoading.hide();
      }    
    },
    updateLocalPatient(patient) { 
      this.form.patient = {
        ...patient,
        address: patient.address || {
          zipCode: '',
          ibge_code: null,
          street_code: null,
          address: '',
          complement: '',
          neighborhood: ''
        }
      };  
      
      const streetType = patient.address?.street_type;
      if (streetType) {
        this.selectedStreetType = this.streetTypeOptions.find(item => item.code === streetType);
      }

      if(patient?.ancestry){
        this.selectedAncestry = {
            label: `${patient.ancestry.code} - ${patient.ancestry.name}`,
            code: patient.ancestry.code,
            id: patient.ancestry.id
        };
      }

      if(patient?.ethnicity){
        this.selectedEthnicity = {
            label: `${patient.ethnicity.code} - ${patient.ethnicity.name}`,
            code: patient.ethnicity.code,
            id: patient.ethnicity.id
        };
      }
      
      if(patient?.address?.state){
        this.selectedAddressState = {
            label: `${patient.address.state.code_ibge} - ${patient.address.state.name}`,
            code: patient.address.state.code_ibge,
            id: patient.address.state.id
        };
      }
      
      if(patient?.nationality){
        this.selectedNationality = {
          label: `${patient.nationality.code} - ${patient.nationality.value}`,
          code: patient.nationality.code,
          id: patient.nationality.id
        };
        if(!patient.nationality.is_sus && this.isShow){
          this.$toast.error('Nacionalidade não é aceita pelo convênio');
        }
      }
    },
    getAncestries() {
      if (this.ancestryOptions.length === 0) {
        this.api.getAncestries().then(async res => {
          this.ancestryOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.name}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getEthnicities() {
      if (this.ethnicityOptions.length === 0) {
        this.api.getEthnicities().then(async res => {
          this.ethnicityOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.name}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getNationalities() {
      if (this.nationalities.length === 0) {
        this.api.getNationalities({is_sus: true}).then(async res => {
          this.nationalities = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.value}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getStates() {
      if (this.statesOptions.length === 0) {
        this.api.getStates().then(async res => {
          this.statesOptions = res.data.map(type => {
            return {
              label: `${type?.code_ibge} - ${type.name}`,
              code: type.code_ibge,
              uf: type.acronym,
              id: type.id
            }
          })
        })
      }
    },
    changeSelectedAncestry(value) {
      this.form.patient.ancestry_id = value.id;
		},
    changeSelectedEthnicity(value) {
      if(value.code !== "05"){
        this.form.patient.ancestry = null;
        this.form.patient.ancestry_id = null;
        this.selectedAncestry = null;
      }
      this.form.patient.ethnicity_id = value.id;
		},
    changeSelectedAddressState(value) {
      this.form.patient.address.state_id = value.id;
		},
    changeSelectedNationality(value) {
      this.form.patient.nationality_id = value.id;
		},
    changeSelectedStreetType(value) {
      this.form.patient.address.street_type = value.code;
    },
    isValidCPF(cpf) {
      if (!cpf || typeof cpf !== 'string') return false;
      
      const numbers = cpf.replace(/[\D]/g, '');
      if (/^(.)\1+$/.test(numbers)) return false;
      
      let sum = 0;
      for (let i = 0; i < 9; i++) sum += parseInt(numbers[i]) * (10 - i);
      
      let digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      if (parseInt(numbers[9]) !== digit) return false;
      
      sum = 0;
      for (let i = 0; i < 10; i++) sum += parseInt(numbers[i]) * (11 - i);
      
      digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      return parseInt(numbers[10]) === digit;
    },
    isValidCNPJ(cnpj) {
      if (!cnpj || typeof cnpj !== 'string') return false;
      
      const numbers = cnpj.replace(/[\D]/g, '');
      if (/^(.)\1+$/.test(numbers)) return false;
      
      let sum = 0;
      let factor = 5;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(numbers[i]) * factor--;
        if (factor < 2) factor = 9;
      }
      
      let digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      if (parseInt(numbers[12]) !== digit) return false;
      
      sum = 0;
      factor = 6;
      for (let i = 0; i < 13; i++) {
        sum += parseInt(numbers[i]) * factor--;
        if (factor < 2) factor = 9;
      }
      
      digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      return parseInt(numbers[13]) === digit;
    },
  },
  watch: {
    susGuide: {
      handler(susGuide) {
          this.localSusGuide = { ... susGuide }
          this.updateLocalSusGuide(this.localSusGuide);
          this.isReadonly();
        },
        immediate: true,
    },
    patient: {
      handler(patient) {
        if (patient) {;
          this.localPatient = { ...patient }
          this.updateLocalPatient(this.localPatient);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#bpa-sus-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        color: var(--type-active) !important;

        .multiselect__single {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 10px);
        }
      }

      .help-icon {
        svg {
          height: 20px;
          padding-bottom: 3px;
        }
      }

    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .form-title{
    background: #F4F5FB;
    color: var(--surface-brand, #305BF2);
    font-size: 18px;
    font-weight: bolder;
    padding: 12px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }

  .invalid-feedback{
    display: block;
  }
}

:root .multiselect .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 15px);
}
</style>
