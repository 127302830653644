<template>
  <b-modal
    id="apac-sus-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
    no-close-on-backdrop
    no-close-on-esc 
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="localSusGuide?.id">
          Atualizar guia APAC (Autorização de Procedimentos Ambulatoriais) - N° {{ localSusGuide?.guide?.guide_number }}
        </span>
        <span v-else>
          Nova guia APAC (Autorização de Procedimentos Ambulatoriais) - N° {{ sus_information?.current_apac_guide_number + 1 }}
        </span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content">
      <!-- Tipo de APAC -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Tipo de APAC</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="type_apac">Tipo de APAC</label>
            <multiselect
              id="sus_apac_type"
              autocomplete="off"
              v-model="selectedSusApacType"
              @input="changeSelectedSusApacType"
              :options="apacTypeOptions"
              track-by="id"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecione um tipo"
              class="with-border"
              :disabled="readonly"
              ref="sus_apac_type"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div 
              v-if="errors.sus_apac_type ? true : false"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-form-group>
            <label for="previous_apac_number">
              Número da APAC anterior
              <span id="help-icon-ancestry" class="icon-box help-icon" v-b-tooltip.hover title="Quando o campo “Tipo de APAC” for selecionado “Continuidade” o campo “Número da APAC anterior” será obrigatório.">
                <HelpCircle class="icon stroke" />
              </span>
            </label>
            <b-form-input
              id="previous_apac_number"
              v-mask="'#############'"
              placeholder="0000000000000"
              autocomplete="off"
              class="form-control"
              v-model="form.previous_apac_number"
              :disabled="!selectedSusApacType || selectedSusApacType?.code !== 2 || readonly"
              maxlength="13"
              :state="errors?.previous_apac_number ? false : null"
              ref="previous_apac_number"
            />
            <b-form-invalid-feedback v-if="errors?.previous_apac_number">
              {{ errors.previous_apac_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="competency">Competência</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.competency"
              name="competency"
              format="MM/YYYY"
              value-type="date"
              id="competency"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.competency ? false : null"
              :disabled="readonly || !!localSusGuide?.sus_batch_id"
              :default-value="new Date()"
              ref="competency"
            />
            <b-form-invalid-feedback v-if="errors?.competency">
              {{ errors.competency }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      
      <!-- Estabelecimento -->
      <b-row>
          <b-col class="mt-3" cols="12">
            <p class="form-title">Identificação do estabelecimento de saúde (Solicitante)</p>
          </b-col>
          <b-col class="mt-3" cols="10">
            <b-form-group>
              <label for="request_id">Nome do estabelecimento de saúde</label>
              <b-form-input
                placeholder="Descrever"
                autocomplete="off"
                class="form-control"
                v-model="clinic.name"
                :readonly="true"
                :state="errors.name_establishment ? false : null"
                ref="name_establishment"
              />
              <b-form-invalid-feedback v-if="errors.name_establishment">
                {{ errors.name_establishment }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col class="mt-3" cols="2">
            <b-form-group>
              <label for="cnaes_code">CNES</label>
              <b-form-input
                id="cnaes_code"
                v-mask="'#######'"
                placeholder="Descrever"
                autocomplete="off"
                class="form-control"
                v-model="sus_information.cnaes_code"
                :readonly="true"
                :state="errors?.cnaes_code ? false : null"
                ref="cnaes_code"
              />
              <b-form-invalid-feedback v-if="errors?.cnaes_code">
                {{ errors.cnaes_code }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
      </b-row>      

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Paciente -->
      <b-row>
        <b-col cols="12">
          <p class="form-title">Identificação do Paciente</p>
        </b-col>  
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_name">Nome do paciente</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name"
              :readonly="readonly"
              :state="errors.patient_name ? false : null"
              ref="patient_name"
            />
            <b-form-invalid-feedback v-if="errors.patient_name">
              {{ errors.patient_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_cpf">CPF do paciente</label>
            <b-form-input
              placeholder="000.000.000-00"
              v-mask="'###.###.###-##'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cpf"
              :readonly="readonly"
              :state="errors.patient_cpf ? false : null"
              ref="patient_cpf"
            />
            <b-form-invalid-feedback v-if="errors.patient_cpf">
              {{ errors.patient_cpf }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000-0000-0000-0000"
              v-mask="'###-####-####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cns"
              :readonly="readonly"
              ref="patient_cns"
              />
              <b-form-invalid-feedback v-if="errors.patient_cns">
                {{ errors.patient_cns }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col> 
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="request_number">N° da solicitação</label>
            <b-form-input
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.request_number"
              :readonly="readonly"
              :state="errors.request_number ? false : null"
              ref="request_number"
              />
              <b-form-invalid-feedback v-if="errors.request_number">
              {{ errors.request_number }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col> 
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_birthday">Data de nascimento</label>
            <b-form-input
              v-model="form.patient.birthday"
              id="patient_birthday"
              name="patient_birthday"
              class="full"
              type="date"
              :readonly="readonly"
              :state="errors.patient_birthday ? false : null"
              ref="patient_birthday"
            />
            <b-form-invalid-feedback v-if="errors.patient_birthday">
              {{ errors.patient_birthday }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>    
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_nationality">Nacionalidade</label>
              <multiselect
                id="nationality"
                autocomplete="off"
                v-model="selectedNationality"
                @input="changeSelectedNationality"
                :options="nationalities"
                track-by="id"
                label="label"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                placeholder="Selecione um país"
                class="with-border"
                :disabled="readonly"
                ref="patient_nationality"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            <b-form-invalid-feedback v-if="errors?.patient_nationality">
              {{ errors.patient_nationality }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_gender">Sexo</label>
            <b-form-radio-group
              id="patient_gender"
              v-model="form.patient.gender"
              name="patient_gender"
              class="pt-2"
              :disabled="readonly"
              :state="errors.patient_gender ? false : null"
            >
              <b-form-radio value="M">Masc.</b-form-radio>
              <b-form-radio value="F">Fem.</b-form-radio>
            </b-form-radio-group>
            <b-form-invalid-feedback v-if="errors.patient_gender">
              {{ errors.patient_gender }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>        
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_ethnicity">
              Raça/Cor
              <span id="help-icon-ancestry" class="icon-box help-icon" v-b-tooltip.hover title="Quando o campo “Raça/cor” for selecionado “Indígena” o campo “Etnia” será obrigatório.">
                <HelpCircle class="icon stroke" />
              </span>
            </label>
            <multiselect
              id="ethinicity"
              v-model="selectedEthnicity"
              :options="ethnicityOptions"
              @input="changeSelectedEthnicity"
              track-by="id"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="patient_ethnicity"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors.patient_ethnicity ? false : null">
              {{ errors.patient_ethnicity }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_ancestry">
              Etnia
            </label>
            <multiselect
              id="ancestry"
              v-model="selectedAncestry"
              @input="changeSelectedAncestry"
              track-by="id"
              label="label"
              :options="ancestryOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="selectedEthnicity?.code !== '05' || readonly"
              ref="patient_ancestry"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors.patient_ancestry ? false : null">
              {{ errors.patient_ancestry }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_mother_name">Nome da mãe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.mother_name"
              :readonly="readonly"
              :state="errors.patient_mother_name ? false : null"
              ref="patient_mother_name"
            />
            <b-form-invalid-feedback v-if="errors.patient_mother_name">
              {{ errors.patient_mother_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_mother_phone">Telefone de contato</label>
            <b-form-input
              placeholder="(00) 00000-0000"
              v-mask="'(##) ####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.telephone_mother"
              :readonly="readonly"
              :state="errors.patient_mother_phone ? false : null"
              ref="patient_mother_phone"
              />
              <b-form-invalid-feedback v-if="errors.patient_mother_phone">
                {{ errors.patient_mother_phone }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_responsible_name">Nome do responsável</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name_responsible"
              :readonly="readonly"
              :state="errors.patient_responsible_name ? false : null"
              ref="patient_responsible_name"
              />
              <b-form-invalid-feedback v-if="errors.patient_responsible_name">
                {{ errors.patient_responsible_name }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_responsible_phone">Telefone de contato</label>
            <b-form-input
              v-mask="'(##) ####-####'"
              placeholder="(00) 00000-0000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.telephone_responsible"
              :readonly="readonly"
              :state="errors.patient_responsible_phone ? false : null"
              ref="patient_responsible_phone"
              />
            <b-form-invalid-feedback v-if="errors.patient_responsible_phone">
              {{ errors.patient_responsible_phone }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_zipcode">CEP</label>
            <b-form-input
              v-mask="'#####-###'"
              placeholder="00000-000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.zipCode"
              :readonly="readonly"
              :state="errors?.patient_address_zipcode ? false : null"
              @keyup="searchCEP"
              ref="patient_address_zipcode"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_zipcode">
              {{ errors.patient_address_zipcode }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_ibge_code">Cód. IBGE Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.ibge_code"
              :readonly="readonly"
              v-mask="'#######'"
              :state="errors?.patient_address_ibge_code ? false : null"
              ref="patient_address_ibge_code"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_ibge_code">
              {{ errors.patient_address_ibge_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_street_type">Tipo Logradouro</label>
            <multiselect
              id="patient_street_type"
              v-model="selectedStreetType"
              @input="changeSelectedStreetType"
              track-by="code"
              label="label"
              :options="streetTypeOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecione"
              class="with-border"
              :disabled="readonly"
              ref="street_code_bpa"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.street_code_bpa">
              {{ errors.street_code_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address">Logradouro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.address"
              :readonly="readonly"
              :state="errors?.patient_address ? false : null"
              ref="patient_address"
              />
              <b-form-invalid-feedback v-if="errors?.patient_address">
                {{ errors.patient_address }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_number">Número</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.number"
              :readonly="readonly"
              :state="errors?.patient_address_number ? false : null"
              ref="patient_address_number"
              />
              <b-form-invalid-feedback v-if="errors?.patient_address_number">
                {{ errors.patient_address_number }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_complement">Complemento</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.complement"
              :readonly="readonly"
              :state="errors?.patient_address_complement ? false : null"
              ref="patient_address_complement"
            />
            <b-form-invalid-feedback v-if="errors?.patient_address_complement">
              {{ errors.patient_address_complement }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_neighborhood">Bairro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.neighborhood"
              :readonly="readonly"
              :state="errors?.patient_address_neighborhood ? false : null"
              ref="patient_address_neighborhood"
              />
              <b-form-invalid-feedback v-if="errors?.patient_address_neighborhood">
                {{ errors.patient_address_neighborhood }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_city">Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.city"
              :readonly="readonly"
              :state="errors?.patient_address_city ? false : null"
              ref="patient_address_city"
              />
            <b-form-invalid-feedback v-if="errors?.patient_address_city">
              {{ errors.patient_address_city }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_state">Estado</label>
            <multiselect
              id="addressState"
              v-model="selectedAddressState"
              @input="changeSelectedAddressState"
              track-by="id"
              label="label"
              :options="statesOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="UF"
              class="with-border"
              :disabled="readonly"
              ref="patient_address_state"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.patient_address_state">
              {{ errors.patient_address_state }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Justificativa -->
      <b-row>
        <b-col cols="12">
          <p class="form-title">Justificativa do(s) procedimento(s) solicitado(s)</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="request_id">CID 10 Principal</label> 
            {{ errors?.cid_main_apac }}
            <multiselect
              v-model="form.cid_1"
              id="cid1"
              track-by="id"
              label="label"
              :options="cid10"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCid10"
              @select="selectCid_1"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="cid_main_apac"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid_main_apac ? false : null">
              {{ errors.cid_main_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="request_id">CID 10 secundário</label>
            <multiselect
              v-model="form.cid_2"
              id="cid2"
              track-by="id"
              label="label"
              :options="cid10_sec"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              @search-change="searchCid10Sec"
              @select="selectCid_2"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
              ref="cid_secondary_apac"
            >
            <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.cid_secondary_apac ? false : null">
              {{ errors.cid_secondary_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-form-group>
            <label for="type_apac">Motivo de saída/permanência</label>
            <multiselect
              id="exit_stay_reasons_id"
              autocomplete="off"
              v-model="selectedExitStayReasons"
              @input="changeSelectedExitStayReasons"
              :options="exitStayReasonOptions"
              track-by="id"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecione um tipo"
              class="with-border"
              :disabled="readonly"
              :state="false"
              ref="reason_leaving_staying_apac"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div 
              v-if="errors.reason_leaving_staying_apac ? true : false"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="exit_date">
              Data de saída
              <span id="help-icon-ancestry" class="icon-box help-icon" v-b-tooltip.hover title="Quando o campo “Motivo de saída/permanência” for selecionado “Alta, Transferência ou Óbito” o campo “Data de saída” será obrigatório.">
                <HelpCircle class="icon stroke" />
              </span>
            </label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.exit_date"
              name="exit_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="exit_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="readonly"
              ref="exit_date_apac"
            />
            <div 
              v-if="errors.exit_date_apac ? true : false"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col> 
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="character_service_id">Caráter de atendimento</label>
            <multiselect
              id="character_service_id"
              autocomplete="off"
              v-model="selectedCharacterService"
              @input="changeSelectedCharacterService"
              :options="characterServiceOptions"
              track-by="id"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecione"
              class="with-border"
              :disabled="readonly"
              ref="character_service_apac"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.character_service_apac">
              {{ errors.character_service_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="apac_type_service_id">Tipo de atendimento</label>
            <multiselect
              id="apac_type_service_id"
              autocomplete="off"
              v-model="selectedTypeService"
              @input="changeSelectedTypeService"
              :options="typeServiceOptions"
              track-by="id"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecione"
              class="with-border"
              :disabled="readonly"
              ref="apac_type_service_id"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.apac_type_service_id">
              {{ errors.apac_type_service_id }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="request_id">Descrição do diagnóstico</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.description"
              :readonly="readonly"
              :state="errors?.diagnosis_apac ? false : null"
              ref="diagnosis_apac"
            />
            <b-form-invalid-feedback v-if="errors.diagnosis_apac">
              {{ errors.diagnosis_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="12">
          <b-form-group>
            <label for="request_id">Observações</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.observation"
              :readonly="readonly"
              :state="errors?.observations_apac ? false : null"
              ref="observations_apac"
            />
            <b-form-invalid-feedback v-if="errors?.observations_apac">
              {{ errors.observations_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Solicitação -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Solicitação</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="requesting_professional">Nome do profissional</label>
            <ProfessionalFieldModule
              :clinic="clinic"
              :professional="form.requesting_professional"
              :readonly="readonly"
              @update-professional="updateProfessional"
            />
            <b-form-invalid-feedback :state="errors?.professional_name_apac ? false : null">
              {{ errors.professional_name_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="requesting_date">Data de solicitação</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.requesting_date"
              name="requesting_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="requesting_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="readonly"
              ref="request_date_apac"
            />
            <b-form-invalid-feedback :state="errors.request_date_apac ? false : null">
              {{ errors.request_date_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="requesting_professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000-0000-0000-0000"
              v-mask="'###-####-####-####'"
              autocomplete="off"
              class="form-control"
              v-model="form.requesting_professional.cns"
              :readonly="true"
              :state="errors.requesting_professional_cns ? false : null"
              ref="requesting_professional_cns"
            />
            <b-form-invalid-feedback v-if="errors?.requesting_professional_cns">
              {{ errors.requesting_professional_cns }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Autorização -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Autorização</p>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="professional_name">Nome do profissional autorizador</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.authorizing_professional_name"
              :disabled="readonly"
              :state="errors?.authorizing_professional_name ? false : null"
              ref="authorizing_professional_name"
            />
            <b-form-invalid-feedback :state="errors.authorizing_professional_name ? false : null">
              {{ errors.authorizing_professional_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col> 
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorizer_issuing_body_code">Cód. Orgão Emissor</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.issuing_body_code"
              :readonly="readonly"
              :state="errors?.issuer_apac ? false : null"
              ref="issuer_apac"
            />
            <b-form-invalid-feedback v-if="errors?.issuer_apac">
              {{ errors.issuer_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>       
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorizer_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              v-mask="'###-####-####-####'"
              placeholder="000-0000-0000-0000"
              autocomplete="off"
              class="form-control"
              v-model="form.authorizing_professional_number"
              :disabled="readonly"
              :state="errors?.national_authorizer_card_apac ? false : null"
              ref="national_authorizer_card_apac"
            />
            <b-form-invalid-feedback v-if="errors?.national_authorizer_card_apac">
              {{ errors.national_authorizer_card_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="authorization_date">Data de autorização</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.authorization_date"
              name="authorization_date"
              format="DD/MM/YYYY"
              value-type="date"
              id="authorization_date"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="readonly"
              ref="authorization_date_apac"
            />
            <b-form-invalid-feedback :state="errors?.authorization_date_apac ? false : null">
              {{ errors.authorization_date_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="apac_authorization_number">N° da autorização (APAC)</label>
            <b-form-input
              placeholder="000000000000-0"
              v-mask="'############-#'"
              autocomplete="off"
              class="form-control"
              v-model="form.apac_authorization_number"
              :readonly="readonly"
              :state="errors.authorization_apac ? false : null"
              ref="authorization_apac"
            />
            <b-form-invalid-feedback v-if="errors?.authorization_apac">
              {{ errors.authorization_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="validity_period_start">Período de validade da APAC</label>
            <div class="d-flex">
              <date-picker
                placeholder="DE 00/00/0000"
                v-model="form.validity_period_start"
                name="validity_period_start"
                format="DD/MM/YYYY"
                value-type="date"
                id="validity_period_start"
                :lang="langDatePicker"
                :clearable="false"
                class="full mr-2"
                :disabled="readonly"
                ref="validity_period_start"
              />
              <b-form-invalid-feedback v-if="errors?.validity_period_apac" :state="errors?.validity_period_apac ? false : null">
                {{ errors.validity_period_apac }}
              </b-form-invalid-feedback>

              <date-picker
                placeholder="ATÉ 00/00/0000"
                v-model="form.validity_period_end"
                name="validity_period_end"
                format="DD/MM/YYYY"
                value-type="date"
                id="validity_period_end"
                :lang="langDatePicker"
                :clearable="false"
                class="full"
                :disabled="readonly"
                ref="validity_period_end"
              />
            </div>
            <b-form-invalid-feedback v-if="errors?.validity_period_end" :state="errors?.validity_period_end ? false : null">
                {{ errors.validity_period_end }}
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <ProceduresPerformed
        :checkedItems="procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="sus_information?.clinic_health_plan?.id"
        @update-procedures="updateProcedures"
        :requestFields="requiredField"
        :type="'apac'"
      />

      <!-- Executante -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title">Identificação do estabelecimento de saúde (Executante)</p>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="executing_establishment_name">Nome fantasia do estabelecimento de saúde executante</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.executing_establishment_name"
              :readonly="readonly"
              :state="errors.fantasy_name_apac ? false : null"
              ref="fantasy_name_apac"
            />
            <b-form-invalid-feedback v-if="errors.fantasy_name_apac">
              {{ errors.fantasy_name_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="executing_establishment_cnes">CNES</label>
            <b-form-input
              placeholder="Descrever"
              v-mask="'#######'"
              autocomplete="off"
              class="form-control"
              v-model="form.executing_establishment_cnes"
              :readonly="readonly"
              :state="errors.cnes_executant_apac ? false : null"
              ref="executing_establishment_cnes"
            />
            <b-form-invalid-feedback v-if="errors.cnes_executant_apac">
              {{ errors.cnes_executant_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="executing_doctor_name">Nome do Médico responsável</label>
            <ProfessionalFieldModule
              :clinic="clinic"
              :professional="form?.executing_doctor"
              :readonly="readonly"
              @update-professional="updateExecutingProfessional"
            />
            <b-form-invalid-feedback v-if="errors.executing_doctor_name">
              {{ errors.executing_doctor_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cns_executing_doctor">CNS Médico Executante</label>
            <b-form-input
              placeholder="Descrever"
              v-mask="'###############'"
              autocomplete="off"
              class="form-control"
              v-model="form.cns_executing_doctor"
              :readonly="true"
              :state="errors.cns_executing_doctor_apac ? false : null"
              ref="cns_executing_doctor_apac"
            />
            <b-form-invalid-feedback v-if="errors.cns_executing_doctor_apac">
              {{ errors.cns_executing_doctor_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="cbo_executing_doctor">CBO Médico Executante</label>
            <b-form-input
              placeholder="Descrever"
              v-mask="'######'"
              autocomplete="off"
              class="form-control"
              v-model="form.cbo_executing_doctor"
              :readonly="true"
              :state="errors.cbo_executing_doctor_apac ? false : null"
              ref="cbo_executing_doctor_apac"
            />
            <b-form-invalid-feedback v-if="errors.cbo_executing_doctor_apac">
              {{ errors.cbo_executing_doctor_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>  

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="localSusGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updatesusGuide"
          :disabled="localSusGuide.situation === 'Cancelada'"
          v-if="localSusGuide && localSusGuide.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import ProceduresPerformed from './Modules/ProceduresPerformed.vue';
import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
import { saveAs } from 'file-saver'
import api from '@/modules/sus/api'
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProceduresPerformed,
  },
  props: {
    sus_information_setting: Object,
    patient: {
      type: [Object, null],
      required: true,
    },
    clinicHealthPlan: {
      type: Object
    },
    susGuide: {
      type: Object,
      default: () => ({})
    },
    checkedItems: Array,
    appointmentId: String,
    clinicHealthPlanId: String
  },
  data() {
    return {
      isShow: false,
      localPatient: { ...this.patient },
      localSusGuide:  { ...this.susGuide },
      user: getCurrentUser(),
      cid10: [],
      cid10_sec: [],
      isLoading: true,
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      procedures: [],
      readonly: true,
      requiredField: {},
      sus_information: {},
      errors: {},
      nationalities: [],
      statesOptions: [],
      ancestryOptions: [],
      ethnicityOptions: [],
      apacTypeOptions: [],
      exitStayReasonOptions: [],
      typeServiceOptions: [],
      characterServiceOptions: [],
      streetTypeOptions: [
        { code: '008', label: '008 - Avenida (AV)' },
        { code: '081', label: '081 - Rua (R)' },
        { code: '004', label: '004 - Alameda (AL)' },
        { code: '100', label: '100 - Travessa (TR)' },
        { code: '065', label: '065 - Praça (PÇA)' },
        { code: '031', label: '031 - Estrada (EST)' },
        { code: '090', label: '090 - Rodovia (ROD)' },
        { code: '054', label: '054 - Largo (LGO)' },
        { code: '104', label: '104 - Vila (VL)' },
        { code: '011', label: '011 - Beco (BCO)' },
      ],
      selectedAncestry: null,
      selectedEthnicity: null,
      selectedAddressState: null,
      selectedNationality: null,
      selectedSusApacType: null,
      selectedExitStayReasons: null,
      selectedCharacterService: null,
      selectedTypeService: null,
      selectedStreetType: null,
      selectedStreeCode: null,
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
    };
  },
  async mounted() {
    try {
      const { data } = await api.getInformationSusSettingByClinicId(this.clinic.id);
      this.sus_information = data;

      this.getRequiredFields();

      this.getAncestries();
      this.getEthnicities();
      this.getNationalities();
      this.getStates();
      this.getApacTypes();
      this.getExitStayReasons();
      this.getCharacterService();
      this.getTypeService();

    } catch (error) {
      console.error('Erro ao carregar dados do SUS', error);
    }
  },
  methods: {
    isReadonly() {
      this.readonly = false;
      
      if(this.localSusGuide?.id){
        this.readonly = Boolean(
          this.localSusGuide?.id &&
          (this.localSusGuide.sus_batch !== null && ( this.localSusGuide?.sus_batch?.status === SusBatch.BATCH_STATUS_FINISHED) )
        ); 
      }    
    },
    formatCid(cid) {
      return cid 
        ? { label: `${cid.code} - ${cid.description}`, value: cid.id } 
        : null;
    },
    formatDate(date) {
      return date ? moment(date).toDate() : null;
    },
    getCheckedItems(){
      if(this.checkedItems){
        this.procedures = this.checkedItems.map((item, index) => ({
          id: null,
          item: item.item,
          item_id: item.item_id,
          code: item.item.code,
          name: item.item.name,
          attendance_date: moment(item?.appointment?.start_datetime).toDate(),
          quantity: 1,
          appointment_item_id: item.id,
          unitary_value: item.item.health_plan_props.value,
          total_value: item.item.health_plan_props.value,
          is_principal: index === 0
        }));

        this.form.procedures = this.procedures;
      }
    },
    getDefaultForm() {
      return {
        competency: null,
        request_number: '',
        cid_1: null,
        cid_2: null,
        observation: null,
        description: '',
        reason_leaving_staying: '',
        clinic: {
          name: '',
          address: '',
        },
        procedures: [],
        requesting_date: null,
        authorization_date: null,
        apac_authorization_number: '',
        patient: {
          cns: null,
          name: null,
          gender: null,
          birthday: null,
          ethnicity: null,
          ancestry: null,
          cellphone: null,
          email: '',
          address: {
            zipCode: '',
            ibge_code: null,
            street_code: null,
            address: '',
            complement: '',
            neighborhood: ''
          }
        },
        validity_period_start: null,
        validity_period_end: null,
        requesting_professional: {
          name: '',
          cns: '',
        },
        issuing_body_code: '',
        sus_information: {
          cnaes_code: null
        },
        executing_establishment_name: '',
        executing_establishment_cnes: '',
        apac_type_id: '',
        apac_exit_stay_reasons_id: '',
        apac_character_service_id: '',
        apac_type_service_id: '',
        exit_date: '',
        previous_apac_number: '',
        executing_doctor: null
      };
    },
    async getRequiredFields() {
      try {
        const requiredfield = {};
        if (this.clinic.id) {
          const { data } = await api.getSusGuideRequiredFields(this.clinic.id);
          
          if(data.required_fields?.APAC){
            Object.values(data.required_fields?.APAC).map(group => {
              Object.keys(group).map((field)  => {
                requiredfield[`${field}`] = group[field];
              });
            });
          }
        }

        delete requiredfield.patient_ancestry;
        delete requiredfield.exit_date;
        delete requiredfield.previous_apac_number;

        this.requiredField = requiredfield;        
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onShow() {
      this.isShow = true;

      this.isReadonly();

      if(this.localSusGuide && Object.values(this.localSusGuide).length > 1){
        this.updateLocalSusGuide(this.localSusGuide);  
      }

      if(!this.form.executing_establishment_name)
        this.form.executing_establishment_name = this.clinic.name;
      
      if(!this.form.executing_establishment_cnes)
        this.form.executing_establishment_cnes = this.sus_information.cnaes_code;
      
      if(this.patient && Object.values(this.patient).length > 1){
        this.updateLocalPatient(this.patient);
      }

      this.getCheckedItems();      
    },
    clearModal() {
      this.form = this.getDefaultForm();
      this.localSusGuide = {};
      this.procedures = [];
      this.form.procedures = [];
      this.selectedAncestry = null;
      this.selectedEthnicity = null;
      this.selectedAddressState = null;
      this.selectedNationality = null;
      this.selectedSusApacType = null;
      this.selectedExitStayReasons = null;
      this.selectedCharacterService = null;
      this.selectedTypeService = null;
      this.localPatient = null;
      this.localSusGuide = null;
      this.errors = [];
    },
    closeModal() {
      this.isShow = false;
      this.clearModal();
      this.$emit('onHide');
      this.$bvModal.hide('apac-sus-guide-modal');
    },
    async searchCEP() {
      if (this.form.patient?.address?.zipCode.length === 9) {
        try {
          const res = await axios.get(`https://viacep.com.br/ws/${this.form.patient.address.zipCode}/json/`);    

          this.form.patient.address.address = res.data.logradouro;
          this.form.patient.address.neighborhood = res.data.bairro;
          this.form.patient.address.state = this.statesOptions.find(item => item.uf === res.data.uf);
          this.selectedAddressState = this.statesOptions.find(item => item.uf === res.data.uf);
          this.form.patient.address.city = res.data.localidade;
          this.form.patient.address.ibge_code = res.data.ibge;	

          this.$refs.patient_address_number.focus()
        } catch (error) {
          this.$toast.errpr('Erro ao buscar o endereço');
          console.error("Erro ao buscar o endereço:", error);
        }
      }
    },
    isValidForm() {
      const errors = {};

      if(this.form.procedures.length === 0) {   
        errors.procedures = "Campo obrigatório";     
        this.$toast.error('Obrigatório incluir procedimento a guia!');
      }

      if(!this.selectedSusApacType){
        errors.sus_apac_type = "Campo obrigatório";
      }

      if(!this.form.competency) {
        errors.competency = "Campo obrigatório";
      }
      
      if (this.selectedEthnicity?.code === '05' && !this.selectedAncestry) {
        errors.patient_ancestry = "Quando o campo “Raça/cor” for selecionado “Indígena” o campo “Etnia” será obrigatório.";
      }
      
      if(
        ['1.1', '1.2', '1.4', '1.5', '1.6', '1.8', '3.1', '4.1', '4.2', '4.3'].includes(this.selectedExitStayReasons?.code) && 
        !this.form.exit_date
      ){
        errors.exit_date_apac = "Quando o campo “Motivo de saída/permanência” for selecionado “Alta, Transferência ou Óbito” o campo “Data de saída” será obrigatório.";
      }
      
      if(!this.selectedTypeService) {
         errors.apac_type_service_id = "Campo obrigatório";
      }
      
      if(this.selectedSusApacType?.code === 2 && !this.form.previous_apac_number){
        errors.previous_apac_number = 'Quando o campo “Tipo de APAC” for selecionado “Continuidade” o campo “Número da APAC anterior” será obrigatório.';
      }

      if(!this.form.patient.cpf) {
        errors.patient_cpf = "Campo obrigatório";
      }
      
      if(!this.form.cns_executing_doctor) {
        errors.cns_executing_doctor_apac = "Campo obrigatório";
      }

      if(!this.form.cbo_executing_doctor) {
        errors.cbo_executing_doctor_apac = "Campo obrigatório";
      }

      if(!this.selectedNationality){
        errors.nationality_apac = "Campo obrigatório";
      }

      if(!this.selectedNationality && !this.form.patient.nationality?.is_sus){
        errors.nationality_apac = "Nacionalidade não é aceita pelo convênio";        
      }

      if(this.requiredField.character_service_apac && !this.selectedCharacterService){
        errors.character_service_apac = "Campo obrigatório";
      }     
      
      if(!this.form.executing_doctor) {
        errors.executing_doctor_name = "Campo obrigatório";
      }

      if(!this.form.patient.cns) {
        errors.patient_cns = "Campo obrigatório";
      }

      Object.keys(this.requiredField).map(item => {
        if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requiredField[item]){
          errors[item] = "Campo obrigatório";
        }
      });

      if(this.requiredField.patient_gender && !this.form.patient?.gender){
        errors.patient_gender = "Campo obrigatório";
      } else {
        delete errors.patient_gender;
      }

      if(this.requiredField.professional_name_apac && !this.form.requesting_professional?.id){
        errors.professional_name_apac = "Campo obrigatório";
      } else {
        delete errors.professional_name_apac;
      }

      if(this.requiredField.professional_name_apac && !this.form.requesting_professional?.id){
        errors.professional_name_apac = "Campo obrigatório";
      } else {
        delete errors.professional_name_apac;
      }

      this.errors = errors;
      return Object.keys(errors).length;
    },
    getPayload() {
      const payload = {
        competency: this.form.competency,
        sus_information_id: this.sus_information.id,
        guide_number: this.sus_information.current_apac_guide_number,
        clinic_health_plan_id: this.sus_information.clinic_health_plan.id,
        request_number: this.form.request_number,
        cid_1: this.form.cid_1?.id, 
        cid_2: this.form.cid_2?.id, 
        cid_associate: this.form.cid_associate, 
        reason_leaving_staying: this.form.reason_leaving_staying,
        observation: this.form.observation, 
        description: this.form.description, 
        requesting_professional: this.form.requesting_professional,
        authorizing_professional_name: this.form.authorizing_professional_name,
        authorizing_professional_number: this.form.authorizing_professional_number,
        authorization_date: this.form.authorization_date,
        apac_authorization_number: this.form.apac_authorization_number,
        validity_period_start: this.form.validity_period_start, 
        validity_period_end: this.form.validity_period_end, 
        executing_establishment_name: this.form.executing_establishment_name, 
        executing_establishment_cnes: this.form.executing_establishment_cnes,
        mother_phone: this.form.patient.mother_phone,
        clinic_id: this.clinic.id,
        patient_id: this.form.patient.id,
        patient: {
          ...this.form.patient,   
          nationality: this.selectedNationality ? this.selectedNationality.value : null,
          ancestry: this.selectedAncestry ? this.selectedAncestry.value :  null,
          ethnicity: this.selectedEthnicity ? this.selectedEthnicity.value :  null,
        },
        address: {
          ...this.form.patient.address,
          state: this.selectedAddressState ? this.selectedAddressState.acronym : null,
        },
        procedures: this.form.procedures,
        guide_type: "APAC",
        appointment_id: this.appointmentId,
        requesting_professional_id: this.form.requesting_professional.id,
        issuing_body_code: this.form.issuing_body_code,
        requesting_date: this.form.requesting_date,
        apac_type_id: this.form.apac_type_id,
        apac_exit_stay_reasons_id: this.form.apac_exit_stay_reasons_id,
        apac_character_service_id: this.form.apac_character_service_id,
        apac_type_service_id: this.form.apac_type_service_id,
        exit_date: this.form.exit_date,
        previous_apac_number: this.form.previous_apac_number,
        cns_executing_doctor: this.form.cns_executing_doctor,
        cbo_executing_doctor: this.form.cbo_executing_doctor,
        professional_id: this.form.executing_doctor?.id,    
      };
      return payload;
    },
    async createGuide() {
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.createSusGuide(payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia APAC criada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updatesusGuide(){
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.updateSusGuide(this.localSusGuide.id, payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia APAC atualizada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateProfessional(professional) {
      this.form.requesting_professional = professional;
    },
    updateExecutingProfessional(professional) {
      this.form.executing_doctor = professional;
      this.form.cns_executing_doctor = professional.cns;
      this.form.cbo_executing_doctor = professional.cbo;
    },
    updateProcedures(procedures) {
      this.form.procedures = procedures;
    },
    async searchNationality(query) {
      if (query.length > 2) {
        this.nationalities = []
        try {
          const response = await this.api.searchNationality({ term: query })
          this.nationalities = response.data.map(nationality => {
            return {
              label: `${nationality.value}`,
              id: nationality.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async searchCid10(query) {
      if (query.length > 2) {
        this.cid10 = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10 = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              id: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async searchCid10Sec(query) {
      if (query.length > 2) {
        this.cid10_sec = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cid10_sec = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              id: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectCid_1() {
      this.cid_1 = []
    },
    selectCid_2() {
      this.cid_2 = []
    },
    async print() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await api.printSusGuide(this.localSusGuide.id, getCurrentUser()?.name) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, 'Guia APAC')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateLocalSusGuide(susGuide) {    
      const isLoading = this.$loading.show();
      try {

        if (Object.values(susGuide).length > 0) {
          this.form = {
            ...susGuide,
            apac_authorization_number: susGuide?.guide?.apac_authorization_number,
            authorization_date: this.formatDate(susGuide?.guide?.authorization_date),
            cid_1: this.formatCid(susGuide?.guide?.cid1),
            cid_2: this.formatCid(susGuide?.guide?.cid2),
            description: susGuide?.guide?.description,
            executing_establishment_name: susGuide?.guide?.executing_establishment_name,
            executing_establishment_cnes: susGuide?.guide?.executing_establishment_cnes,
            issuing_body_code: susGuide?.guide?.issuing_body_code,
            observation: susGuide?.guide?.observation,
            reason_leaving_staying: susGuide?.guide?.reason_leaving_staying,
            request_number: susGuide?.guide?.request_number,
            requesting_date: this.formatDate(susGuide?.guide?.requesting_date),
            requesting_professional: susGuide?.guide?.requesting_professional || {},
            validity_period_end: this.formatDate(susGuide?.guide?.validity_period_end),
            validity_period_start: this.formatDate(susGuide?.guide?.validity_period_start),
            procedures: { ...susGuide?.guide?.procedures || [] },
            previous_apac_number: susGuide?.guide?.previous_apac_number,
            exit_date: this.formatDate(susGuide?.guide?.exit_date),
            cns_executing_doctor: susGuide?.guide?.cns_executing_doctor,
            cbo_executing_doctor: susGuide?.guide?.cbo_executing_doctor,
            competency: susGuide?.competency ? moment(susGuide.competency.replace('/', '-')).toDate() : null,
            authorizing_professional_name: susGuide.guide?.authorizing_professional_name,
            authorizing_professional_number: susGuide.guide?.authorizing_professional_number,
            executing_doctor: susGuide?.professional || {},
          };

          this.updateLocalPatient(susGuide?.patient);

          if (susGuide?.guide?.type) {
            this.selectedSusApacType = {
              label: `${susGuide?.guide?.type?.code} - ${susGuide?.guide?.type?.description}`,
              code: susGuide?.guide?.type?.code,
              id: susGuide?.guide?.type?.id
            };

            this.changeSelectedSusApacType(this.selectedSusApacType);
          }

          if (susGuide?.guide?.exit_stay_reason) {
            this.selectedExitStayReasons = {
              label: `${susGuide?.guide?.exit_stay_reason?.code} - ${susGuide?.guide?.exit_stay_reason?.description}`,
              code: susGuide?.guide?.exit_stay_reason?.code,
              id: susGuide?.guide?.exit_stay_reason?.id
            };

            this.changeSelectedExitStayReasons(this.selectedExitStayReasons);
          }

          if (susGuide?.guide?.character_service) {
            this.selectedCharacterService = {
              label: `${susGuide?.guide?.character_service?.code} - ${susGuide?.guide?.character_service?.description}`,
              code: susGuide?.guide?.character_service?.code,
              id: susGuide?.guide?.character_service?.id
            };

            this.changeSelectedCharacterService(this.selectedCharacterService);
          }

          if (susGuide?.guide?.apac_type_service) {
            this.selectedTypeService = {
              label: `${susGuide?.guide?.apac_type_service?.code} - ${susGuide?.guide?.apac_type_service?.description}`,
              code: susGuide?.guide?.apac_type_service?.code,
              id: susGuide?.guide?.apac_type_service?.id
            };
            this.changeSelectedTypeService(this.selectedTypeService);
          }

          if (susGuide?.guide?.requesting_professional?.sus_professional_setting) {
            this.form.requesting_professional.cns = susGuide.guide.requesting_professional.sus_professional_setting.cns;
          }

          if (susGuide?.professional) {
            this.form.cns_executing_doctor = susGuide.professional?.sus_professional_setting?.cns;
            this.form.cbo_executing_doctor = susGuide.professional?.sus_professional_setting?.cbo;
          }

          if (susGuide?.guide?.procedures) {
            this.procedures = susGuide.guide?.procedures.map(item => ({
              'id': item.id,
              'item': item.item,
              'item_id': item.item_id,
              'code': item.item.code,
              'name': item.item.name,
              'attendance_date': moment(item.attendance_date).toDate(),
              'quantity': item.quantity,
              'appointment_item_id': item.appointment_id,
              'total_value': item.total_value,
              'unitary_value': item.unitary_value,
              'service': item.service,
              'class': item.class,
              'is_principal': item.is_principal === 1

            }));

            this.form.procedures = this.procedures;
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.error('Erro ao carregar dados da guia');
      } finally {

        isLoading.hide();
      }

    },
    updateLocalPatient(patient) {
      this.form.patient = {
        ...patient,
        address: patient.address || {
          zipCode: '',
          ibge_code: null,
          street_code: null,
          address: '',
          complement: '',
          neighborhood: ''
        }
      };  
      
      const streetType = patient.address?.street_type;
      if (streetType) {
        this.selectedStreetType = this.streetTypeOptions.find(item => item.code === streetType);
      }

      if(patient?.ancestry){
        this.selectedAncestry = {
            label: `${patient.ancestry.code} - ${patient.ancestry.name}`,
            code: patient.ancestry.code,
            id: patient.ancestry.id
        };
      }

      if(patient?.ethnicity){
        this.selectedEthnicity = {
            label: `${patient.ethnicity.code} - ${patient.ethnicity.name}`,
            code: patient.ethnicity.code,
            id: patient.ethnicity.id
        };
      }

      if(patient?.address?.state){
        this.selectedAddressState = {
            label: `${patient.address.state.code_ibge} - ${patient.address.state.name}`,
            code: patient.address.state.code_ibge,
            id: patient.address.state.id
        };
      }
      
      if(patient?.nationality){
        this.selectedNationality = {
          label: `${patient.nationality.code} - ${patient.nationality.value}`,
          code: patient.nationality.code,
          id: patient.nationality.id
        };
        if(!patient.nationality.is_sus && this.isShow){
          this.$toast.error('Nacionalidade não é aceita pelo convênio');
        }
      }
    },
    getAncestries() {
      if (this.ancestryOptions.length === 0) {
        this.api.getAncestries().then(async res => {
          this.ancestryOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.name}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getEthnicities() {
      if (this.ethnicityOptions.length === 0) {
        this.api.getEthnicities().then(async res => {
          this.ethnicityOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.name}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getApacTypes() {
      if (this.apacTypeOptions.length === 0) {
        this.api.getApacTypes().then(async res => {
          this.apacTypeOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.description}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getNationalities() {
      if (this.nationalities.length === 0) {
        this.api.getNationalities({is_sus: true}).then(async res => {
          this.nationalities = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.value}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getStates() {
      if (this.statesOptions.length === 0) {
        this.api.getStates().then(async res => {
          this.statesOptions = res.data.map(type => {
            return {
              label: `${type?.code_ibge} - ${type.name}`,
              code: type.code_ibge,
              uf: type.acronym,
              id: type.id
            }
          })
        })
      }
    },
    getExitStayReasons() {
      if (this.exitStayReasonOptions.length === 0) {
        this.api.getExitStayReasons().then(async res => {
          this.exitStayReasonOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.description}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getCharacterService() {
      if (this.characterServiceOptions.length === 0) {
        this.api.getCharacterService().then(async res => {
          this.characterServiceOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.description}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    getTypeService() {
      if (this.typeServiceOptions.length === 0) {
        this.api.getTypeService().then(async res => {
          this.typeServiceOptions = res.data.map(type => {
            return {
              label: `${type?.code} - ${type.description}`,
              code: type.code,
              id: type.id
            }
          })
        })
      }
    },
    changeSelectedAncestry(value) {
      this.form.patient.ancestry_id = value.id;
		},
    changeSelectedEthnicity(value) {
      if(value.code !== "05"){
        this.form.patient.ancestry = null;
        this.form.patient.ancestry_id = null;
        this.selectedAncestry = null;
      }
      this.form.patient.ethnicity_id = value.id;
		},
    changeSelectedAddressState(value) {
      this.form.patient.address.state_id = value.id;
		},
    changeSelectedNationality(value) {
      this.form.patient.nationality_id = value.id;
		},
    changeSelectedSusApacType(value){
      if(value.code !== 2){
        this.form.previous_apac_number = null;
      }
      this.form.apac_type_id = value.id;      
    },
    changeSelectedExitStayReasons(value){
      this.form.apac_exit_stay_reasons_id = value.id;            
    },
    changeSelectedCharacterService(value){
      this.form.apac_character_service_id = value.id;            
    },
    changeSelectedTypeService(value){
      this.form.apac_type_service_id = value.id;            
    },
    changeSelectedStreetType(value) {
      this.form.patient.address.street_type = value.code;
    },
    isValidCPF(cpf) {
      if (!cpf || typeof cpf !== 'string') return false;
      
      const numbers = cpf.replace(/[\D]/g, '');
      if (/^(.)\1+$/.test(numbers)) return false;
      
      let sum = 0;
      for (let i = 0; i < 9; i++) sum += parseInt(numbers[i]) * (10 - i);
      
      let digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      if (parseInt(numbers[9]) !== digit) return false;
      
      sum = 0;
      for (let i = 0; i < 10; i++) sum += parseInt(numbers[i]) * (11 - i);
      
      digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      return parseInt(numbers[10]) === digit;
    },
    isValidCNPJ(cnpj) {
      if (!cnpj || typeof cnpj !== 'string') return false;
      
      const numbers = cnpj.replace(/[\D]/g, '');
      if (/^(.)\1+$/.test(numbers)) return false;
      
      let sum = 0;
      let factor = 5;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(numbers[i]) * factor--;
        if (factor < 2) factor = 9;
      }
      
      let digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      if (parseInt(numbers[12]) !== digit) return false;
      
      sum = 0;
      factor = 6;
      for (let i = 0; i < 13; i++) {
        sum += parseInt(numbers[i]) * factor--;
        if (factor < 2) factor = 9;
      }
      
      digit = 11 - (sum % 11);
      if (digit > 9) digit = 0;
      
      return parseInt(numbers[13]) === digit;
    },
  },
  watch: {
    susGuide: {
      handler(susGuide) {
          this.localSusGuide = { ... susGuide }
          this.updateLocalSusGuide(this.localSusGuide);
          this.isReadonly();
        },
        immediate: true,
    },
    patient: {
      handler(patient) {
        if (patient) {
          this.localPatient = { ...patient }
          this.updateLocalPatient(this.localPatient);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
#apac-sus-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        color: var(--type-active) !important;
      }

      .help-icon {
        svg {
          height: 20px;
          padding-bottom: 3px;
        }
      }

    }

    .wh-button {
      color: var(--blue-500);
    }

    .invalid-feedback {
      display: block;
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .form-title{
    background: #F4F5FB;
    color: var(--surface-brand, #305BF2);
    font-size: 18px;
    font-weight: bolder;
    padding: 12px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }
}

:root .multiselect .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 15px);
}
</style>
