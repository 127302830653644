<template>
    <b-modal
      id="item-health-plan-props-modal"
      size="xl"
      hide-header
      hide-footer
      centered
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p v-if="!form?.id" class="title">Novo Item</p>
            <p v-else class="title">Editar Item</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label>Item</label>
                        <ItemSelect 
                            v-model="form.item"
                            :types="procedureTypes"
                            placeholder="Digite um identificador, código ou nome para buscar itens"
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="!validated ? null : !!form.item">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <hr/>
            <div class="module-title">Dados do faturamento</div>
            <b-row>
                <b-col cols="6">
                    <b-form-group>
                        <label>
                            Serviço
                            <span class="help"> (Opcional)</span>
                            <span v-b-tooltip.hover title="Essa informação só será utilizada nos casos de faturamento SUS."><Info /></span>
                        </label>
                        <b-form-input 
                            id="service_code"
                            autocomplete="off"
                            v-model="form.service_code"
                            :disabled="!editable"
                            v-mask="'###'"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>
                            Classificação
                            <span class="help"> (Opcional)</span>
                            <span v-b-tooltip.hover title="Essa informação só será utilizada nos casos de faturamento SUS."><Info /></span>
                        </label>
                        <b-form-input 
                            id="class_code"
                            autocomplete="off"
                            v-model="form.class_code"
                            :disabled="!editable"
                            v-mask="'###'"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>
                            Código alternativo 
                            <span class="help"> (Opcional)</span>
                            <span v-b-tooltip.hover title="Utilize esse campo para enviar ao convênio um código diferente do padrão"><Info /></span>
                        </label>
                        <b-form-input 
                            id="alias_code"
                            autocomplete="off"
                            v-model="form.alias_code"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>
                            Nome alternativo 
                            <span class="help"> (Opcional)</span>
                            <span v-b-tooltip.hover title="Utilize esse campo para enviar ao convênio um nome diferente do padrão"><Info /></span>
                        </label>
                        <b-form-input 
                            id="alias_name"
                            autocomplete="off"
                            v-model="form.alias_name"
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="9">
                    <b-form-group>
                        <label>Tabela <span class="help"> (Opcional)</span></label>
                        <multiselect
                            id="table_number"
                            label="label"
                            track-by="value"
                            v-model="form.table_number"
                            :options="tableOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="!editable"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group>
                        <label>
                            Monocular
                            <span v-b-tooltip.hover title="Em um procedimento oftalmológico monocular realizado bilateralmente, a remuneração do custo operacional será em 100% do valor previsto nesta Classificação para um lado, e em 70% para o outro."><Info /></span>
                        </label>
                        <b-form-radio-group
                            class="radio-group"
                            id="monocular"
                            name="monocular"
                            v-model="form.monocular"
                            :options="[ { text: 'Não', value: false }, { text: 'Sim', value: true }]"
                            :disabled="!editable"
                        ></b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Honorário médico <span class="help"> (Opcional)</span></label>
                        <money 
                            class="form-control" 
                            v-model="form.medical_fee" 
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Custo operacional <span class="help"> (Opcional)</span></label>
                        <money 
                            class="form-control" 
                            v-model="form.operational_cost" 
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>M2 filme<span class="help"> (Opcional)</span></label>
                        <money 
                            class="form-control" 
                            v-model="form.m2_filme" 
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Porte anestésico<span class="help"> (Opcional)</span></label>
                        <money 
                            class="form-control" 
                            v-model="form.anesthetic_port" 
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>
                            Valor único
                            <span v-b-tooltip.hover title="Utilize esse campo caso o item possua um valor fixo, o valor único será aplicado ao utilizar convênio particular"><Info /></span>
                            <span class="help"> (Opcional)</span>
                        </label>
                        <money 
                            class="form-control" 
                            v-model="form.value" 
                            :disabled="!editable"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            
            <b-row v-if="editable">
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@itemHealthPlanProps/api'
import * as ITEM from '@items/utils/constants'
import { TISS_TABLE_OPTIONS } from '@items/utils/constants'
import { isValidForm, getPayload } from '@itemHealthPlanProps/utils/validations'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        Info: () => import('@/assets/icons/info.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        ItemSelect: () => import('@items/components/ItemSelect'),
    },
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        groupType: String,
        tableId: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: { monocular: false },
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
            procedureTypes: ITEM.ITEMS_WITH_HEALTH_PLAN_PROPS,
            tableOptions: TISS_TABLE_OPTIONS,
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('item-health-plan-props-modal')
        },
        onHidden() {
            this.form = {}
            this.$emit('hidden')
        },
        isValidProp(prop) {
            if (!this.validated) return null
            return !!this.form[prop]
        },
        async save() {
            this.validated = true 
            if (!isValidForm(this.form)) return
            const payload = {
                ...getPayload(this.form),
                table_id: this.tableId,
                clinic_id: this.clinic.id,
            }

            this.loading = true
            try {
                const { data } = !this.form.id ? await api.createItemHealthPlanProp(payload) : await api.updateItemHealthPlanProp(this.form.id, payload)
                this.$toast.success(`Item salvo com sucesso!`)
                this.$emit('saved', data)
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
        changeHealthPlanProps(healthPlanProps) {
            this.form = {
                ...this.form,
                health_plan_props: { ...healthPlanProps, table_id: this.tableId }
            }
        },
    },
    watch: {
        item(data) {
            if (!data) {
                this.form = { monocular: false }
                return
            }
            
            this.form = {
                ...data,
                table_number: this.tableOptions.find(({ value }) => value === data?.table_number) 
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .module-title {
        font-size: 16px;
        font-weight: 700;
        color: var(--blue-500);
        margin-bottom: 1rem;
    }
    .radio-group {
        margin-top: 7px;
    }
</style>
<style lang="scss">
#item-health-plan-props-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 24px;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>