<template>
    <div class="forwarding-email-module-container">


    <!-- <div class="email-wrapper" v-if="this.attendance?.type === 'BV_EXAM'">
      <Check v-model="sentEmailToBv" :disabled="!canEdit" />
      <h5 class="label">
        Encaminhar paciente para telemedicina obrigatória
      </h5>
    </div> -->
    <div class="email-wrapper">
      <Check v-model="sentEmail" :disabled="!canEdit" />
      <h5 class="label">
        Encaminhar documentos para o paciente, via e-mail
      </h5>
    </div>
    <b-input
      class="mt-1"
      type="email"
      :disabled="!canEdit || !sentEmail"
      placeholder="E-mail para encaminhamento dos documentos da consulta"
      v-model="form.forwarding_email"
      @input="value => debounceChangeValue('maisInformacoes', value)"
      :state="emailState"
      :loading="loading"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getCurrentClinic } from '@/utils/localStorageManager'
import { debounce } from 'lodash'

export default {
  components: {
    Check: () => import('@/components/General/Check')
  },
  props:{
    attendance: Object
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      loading: false,
      sentEmail: false,
      // sentEmailToBv: false,
    }
  },
  created(){
    this.debounceChangeValue = debounce(this.updateAttendance, 500)
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
    }),
    emailState() {
      return !this.form.forwarding_email ? null : this.validateEmail()
    }
  },
  methods: {
    getDefaultForm(){
      return {
        forwarding_email: this.attendance?.forwarding_email ?? null,
      }
    },

    updateAttendance(){
      if(!this.validateEmail()) return
      this.loading = true
      this.bvAttendance = null
      this.api.updateAttendanceApi(this.attendance.id, { forwarding_email: this.form.forwarding_email })
      .then(() => {
        console.log('Email para encaminhamento adicionado com sucesso!')
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => (this.loading = false));
    },

    validateEmail(){
      return !this.form.forwarding_email || Boolean(String(this.form.forwarding_email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
    }
  },
  watch: {
    sentEmail(checked) {
        this.form.forwarding_email =  checked ? (this.attendance?.patient?.email || this.attendance.forwarding_email || null) : null
        this.updateAttendance()
    }
  }
}
</script>
<style lang="scss" scoped>
.forwarding-email-module-container {
  margin-top: 38px;
  padding: 16px;
}
.label{
  font-weight: 600;
  font-size: 16px;
  color: var(--dark-blue);
  margin-left: -8px;
  margin-bottom: 2px;
}
.email-wrapper {
  display: flex;
  align-items: center;
}
</style>
