<template>
  <div id="bank-account-table">
    <main class="contas-bancarias">
      <div class="subtitle">
        <p>Contas bancárias</p>
        <HelpCircle class="icon" id="subtitle-tooltip-contas-bancarias" />
      </div>

      <b-tooltip
        target="subtitle-tooltip-contas-bancarias"
        triggers="hover"
        placement="bottom"
      >
        <p class="order-body">
          Adicione e gerencie as contas bancárias da sua clínica, você pode
          editar o saldo inicial e o saldo atual das suas contas.
        </p>
      </b-tooltip>

      <div class="outages">
        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <div class="order-body">
                <p>Conta bancária padrão para entradas</p>
                <multiselect
                  v-model="defaultBankAccountEntry"
                  :options="bankAccountCombo"
                  track-by="value"
                  label="name"
                  :showLabels="false"
                  :searchable="false"
                  :allowEmpty="false"
                  placeholder="Selecione"
                  class="with-border"
                  @input="updateDefaultBankAccountEntries"
                  :disabled="!canEdit"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown v-if="!defaultBankAccountEntry"/>
                    </div>
                  </template>

                  <template slot="noOptions"> Nenhuma opção </template>

                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <Close 
                  v-if="defaultBankAccountEntry"
                  class="close-item-icon mt-1"
                  @click="cleanFormDefaultBankEntry" 
                />
              </div>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <div class="order-body">
                <p>Conta bancária padrão para saídas</p>
                <multiselect
                  v-model="defaultBankAccountOutband"
                  :options="bankAccountCombo"
                  track-by="value"
                  label="name"
                  :showLabels="false"
                  :searchable="false"
                  :allowEmpty="false"
                  placeholder="Selecione"
                  class="with-border"
                  @input="updateDefaultBankAccountOutband"
                  :disabled="!canEdit"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown v-if="!defaultBankAccountOutband"/>
                    </div>
                  </template>

                  <template slot="noOptions"> Nenhuma opção </template>

                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <Close 
                  v-if="defaultBankAccountOutband"
                  class="close-item-icon mt-1"
                  @click="cleanFormDefaultBankOutband" 
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <table>
          <thead class="data-head">
            <tr>
              <th class="head-cell"><p>Nome</p></th>
              <th class="head-cell"><p>Descrição</p></th>
              <th class="head-cell"><p>Saldo em conta</p></th>
              <th class="head-cell"></th>
            </tr>
          </thead>
          <tbody class="data-body">
            <tr class="items">
              <div
                class="item"
                v-for="account in accountBank"
                :key="account.id"
              >
                <td class="cell">
                  <p>{{ account.name }}</p>
                </td>
                <td class="cell reason">
                  <p>{{ account.description }}</p>
                </td>
                <td class="cell amount">
                  <p
                    class="value-tag"
                    v-if="account.account_balance < 0"
                    v-bind:class="{ negative: account.account_balance }"
                  >
                    -R${{
                      parseNumberToMoney(account.account_balance)
                        .trim()
                        .split('$')[1]
                    }}
                  </p>

                  <p class="value-tag" v-else>
                    R${{
                      parseNumberToMoney(account.account_balance)
                        .trim()
                        .split('$')[1]
                    }}
                  </p>
                </td>
                <td class="cell account-options margin-left-auto">
                  <div class="optionsDiv">
                      <p class="status" v-if="account.status">Ativo</p>
                      <p class="status" v-else>Inativo</p>
                      <toggle-button
                        :sync="true"
                        :height="32"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                        v-model="account.status"
                        @input="
                          updateStatusBankAccount(account.id, account.status)
                        "
                        :disabled="!userHasPermission('FiConCoBan3')"
                      />
                  </div>
                  <div class="receive d-flex">
                    <div
                      v-if="
                        userHasPermission('FiConCoBan4') ||
                        userHasPermission('FiConCoBan5')
                      "
                      class="actions"
                    >
                      <Icon tooltip="Editar conta bancária">
                        <Edit
                          class="icon"
                          @click="updateBankAccount(account)"
                        />
                      </Icon>
                      <Icon :tooltip="account?.payments?.length ? 'A conta não pode ser excluída devido à existência de itens pendentes.' : 'Remover conta bancária'">
                        <Delete
                          class="icon trash"
                          :class="{'unactive-icon': account.payments?.length }"
                          @click="!account?.payments?.length ? openDeleteModal(account) : null"
                        />
                      </Icon>

                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="add-btn">
          <button
            id="add-card-flag"
            v-can="'FiConCat2'"
            @click="$bvModal.show('bank-account-modal')"
          >
            Adicionar
          </button>
        </div>
      </div>
    </main>

    <BankAccountModal
      :bankAccount="bankAccount"
      @hidden="hiddenModal"
      :defaultEntry="defaultBankAccountEntry"
      :defaultOutband="defaultBankAccountOutband"
    />
  </div>
</template>

<script>
import {
  userHasPermission,
  getCurrentClinic
} from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'bank-account-table',

  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Icon: () => import('@/components/General/Icon'),
    Delete: () => import('@/assets/icons/trash.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    BankAccountModal: () => import('@/modules/financial/modals/BankAccountModal.vue'),
    Close: () => import('@/assets/icons/close.svg'),
  },
  created() {
    this.getBankAccount()
    this.getbankAccountCombo()
  },
  data() {
    const clinic = getCurrentClinic()

    return {
      validated: false,
      defaultBankAccountEntry: null,
      defaultBankAccountOutband: null,
      clinic_id: clinic.id,

      accountBank: [],
      bankAccountCombo: [],
      bankAccountForm: {
        id: null,
        creation_date: new Date(),
        name: null,
        description: null,
        account_balance: 0,
        status: true,
        default_bank_account_entries: false,
        default_bank_account_outband: false
      },

      bankAccount: null,
      canEdit: userHasPermission('FiConCoBan4'),
    }
  },
  mounted() {
    this.getBankAccount()
  },
  methods: {
    userHasPermission,
    parseNumberToMoney,
    updateBankAccount(account) {
      this.bankAccount = account
      this.$bvModal.show('bank-account-modal')
    },
    hiddenModal() {
      this.bankAccount = null
      this.getBankAccount()
      this.getbankAccountCombo()
    },
    openDeleteModal(bankAccount) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover a conta bancária <span>${bankAccount?.name}</span>?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.deleteBankAccount(bankAccount.id)
        }
      })
    },
    getBankAccount() {
      const isLoading = this.$loading.show()

      this.api
        .getBankAccount(this.clinic_id)
        .then(res => {
          this.accountBank = res.data
          this.accountBank.map(account => {
            account.status === 1
              ? (account.status = true)
              : (account.status = false)
          })
        })
        .catch(err => {
          console.log('Err', err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    updateStatusBankAccount(id, value) {
      const isLoading = this.$loading.show()

      this.api
        .updateStatusBankAccount({ id: id, status: value })
        .then(res => {
          this.getBankAccount()
          this.$toast.success('Conta bancária atualizada com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    deleteBankAccount(id) {
      const isLoading = this.$loading.show()

      this.api
        .deleteBankAccount({ id: id })
        .then(res => {
          this.getBankAccount()
          this.getbankAccountCombo()
          this.$toast.success('Conta bancária removida com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    getbankAccountCombo() {
      this.api.getBankAccountBillToReceive(this.clinic_id).then(res => {
        this.bankAccountCombo = res.data.map(el => ({
          ...el,
          label: `${el.name}`,
          value: `${el.id}`
        }))
        this.defaultBankAccountEntry = res.data.find(
          el => el.default_bank_account_entries
        )
        this.defaultBankAccountOutband = res.data.find(
          el => el.default_bank_account_outband
        )
      })
    },
    updateDefaultBankAccountEntries(bankAccount) {
      const isLoading = this.$loading.show()

      this.api
        .updateDefaultBankAccountEntries({
          clinic_id: this.clinic_id,
          id: bankAccount.id
        })
        .then(res => {
          this.getBankAccount()
          this.getbankAccountCombo()
          this.$toast.success(
            'Conta bancária padrão para entrada atualizada com sucesso!'
          )
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    updateDefaultBankAccountOutband(bankAccount) {
      const isLoading = this.$loading.show()

      this.api
        .updateDefaultBankAccountOutband({
          clinic_id: this.clinic_id,
          id: bankAccount.id
        })
        .then(res => {
          this.getBankAccount()
          this.getbankAccountCombo()
          this.$toast.success(
            'Conta bancária padrão para saída atualizada com sucesso!'
          )
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },

    updateStatusBankEntryAccount(id, value) {
      const isLoading = this.$loading.show()

      this.api
        .updateStatusBankAccount({ id: id, default_bank_account_entries: value })
        .then(res => {
          this.getBankAccount()
          this.getbankAccountCombo()
          this.$toast.success('Conta bancária atualizada com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    cleanFormDefaultBankEntry(){
      this.updateStatusBankEntryAccount(this.defaultBankAccountEntry.id ,false)
    },

    updateStatusBankOutbandAccount(id, value) {
      const isLoading = this.$loading.show()

      this.api
        .updateStatusBankAccount({ id: id, default_bank_account_outband: value })
        .then(res => {
          this.getBankAccount()
          this.getbankAccountCombo()
          this.$toast.success('Conta bancária atualizada com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },

    cleanFormDefaultBankOutband(){
      this.updateStatusBankOutbandAccount(this.defaultBankAccountOutband.id ,false)
    }

  },
  watch: {
    bankAccount() {
      this.getBankAccount()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/ax-datepicker.scss';

#bank-account-table {
  .contas-bancarias {
    padding: 40px 10px 0px 10px;
    width: 100%;

    .doctor-selected-title {
      font-family: 'Red Hat Display';
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 30px;
      color: #525c7a;
    }

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 27px;
    }

    .subtitle {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: var(--type-active);
      padding-bottom: 24px;
      text-align: left;
      flex-direction: row;
      gap: 8px;
      
      p {
        margin-bottom: 0;
      }

      .icon {
        height: 24px;
        width: 24px;
        stroke: var(--neutral-500);
      }
    }

    .outages {
      /* border: 1px solid #D9DFF2; */
      box-sizing: border-box;
      border-radius: 8px;
      /* padding: 24px; */

      .order-body {
        border-radius: 8px;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: var(--dark-blue);
          text-align: left;
        }
      }

      .add-btn {
        text-align: center;
        padding: 36px 0;
        border-left: 1px solid #d9dff2;
        border-right: 1px solid #d9dff2;
        border-bottom: 1px solid #d9dff2;
        border-radius: 0 0 7px 7px;

        button {
          transition: 0.3s;
          border: 2px solid var(--blue-500);
          padding: 8px 68px;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: var(--blue-500);
          border-radius: 8px;

          &.saveBtn {
            color: var(--blue-500);
            border: 2px solid var(--blue-500);
          }

          &.cancel {
            color: #ffebcc;
            border: 2px solid#ffdccc;
          }
        }
        button:hover {
          transition: 0.4s;
          color: white;
          background-color: #305bf2;
        }
      }

      .head {
        height: 70px;
        padding: 0px 20px;
        justify-content: space-around;
        align-items: center;
        color: #525c7a;

        .title {
          margin: 0px;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
        }

        .date {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;

          svg {
            width: 35px;
            height: 25px;
            stroke: #305bf2;
            cursor: pointer;
          }
        }
      }

      table {
        table-layout: fixed !important;
        width: 100% !important;
        border-collapse: collapse !important;
      }

      .head-cell {
        text-align: left !important;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: var(--type-active);
          text-align: left;
          margin-bottom: 0;
        }
      }

      .cell {
        white-space: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: var(--type-active);
          text-align: left;
          margin-bottom: 0;
        }

        &.account-options {
          align-items: center;
          justify-content: flex-end;
          column-gap: 16px;
          flex-direction: row;
          width: 100%;
          .optionsDiv {
            display: flex;
            column-gap: 16px;
            align-items: center;
          }
        }
      }

      .flexComponent {
        display: flex;
      }

      .flexRow {
        flex-direction: row;
        justify-content: space-around;
      }

      .status-toggle-create {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .account-options .optionsDiv {
        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: right;
          color: var(--type-active);
        }

        svg {
          fill: var(--neutral-500);
        }
      }

      .value-tag {
        max-width: 160px;
        padding: 8px 16px;
        border-radius: 50px;
        background-color: #d9dff2;
        color: #000;
        text-align: center !important;
        overflow: hidden;

        &.active {
          background-color: #ffebcc;
        }

        &.negative {
          background-color: #fedad7;
        }
      }

      .data-head {
        tr {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          padding: 12px 24px;
          background: #f4f5fb;
          color: #525c7a;
          flex-direction: row;
          border-radius: 7px 7px 0px 0px !important;
          border: 1px solid #d9dff2;
        }
      }

      .data-body {
        .items {
          display: flex;
          flex-direction: column;
          position: relative;

          .item {
            list-style: none;
            padding: 24px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            border-bottom: 1px solid #d9dff2;
            border-left: 1px solid #d9dff2;
            border-right: 1px solid #d9dff2;

            .times-tag {
              margin: 0 auto;
              border-radius: 50px;
              background-color: #d9dff2;
              color: #000;
              text-align: center;

              &.active {
                background: #ffebcc;
              }

              &.negative {
                background: #fedad7;
              }
            }

            .receive {
              display: flex;
              flex-direction: row;
              align-items: center;
              border: none !important;
              text-align: left;
              align-items: center;
              gap: 8px;

              .actions {
                .icon-container {
                  padding: 4px 0 4px 4px;
                }
              }
              .options {
                cursor: pointer;
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 160px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
                    0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }

          .create {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            list-style: none;
            padding: 24px;
            border-left: 1px solid #d9dff2;
            border-right: 1px solid #d9dff2;
            border-bottom: 1px solid #d9dff2;
            gap: 16px;

            .date-cell {
              max-height: 48px !important;
              text-align: left;
            }

            .bankEditCell {
              text-align: left;
            }

            .date-inputs {
              .mx-datepicker {
                width: unset !important;
              }
            }

            .reason {
              .multiselect {
                border: 1px solid #c6ceeb;
                box-sizing: border-box;
                border-radius: 8px;

                .multiselect__tags {
                  padding-right: 0px;
                }

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }
            }

            .multiselect {
              border: 1px solid #c6ceeb;
              box-sizing: border-box;
              border-radius: 8px;

              .multiselect__tags {
                padding-right: 0px;
              }

              .multiselect__single {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: var(--type-active);
              }
            }

            .custom-invalid-feedback {
              text-align: left;
            }

            .receive {
              display: flex !important;
              flex-direction: row;
              gap: 8px;
              align-items: center;
              border: none !important;

              .status {
                height: 20px;
                margin-top: 4px;
              }

              .multiselect {
                border: 1px solid #c6ceeb;
                box-sizing: border-box;
                border-radius: 8px;

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }
            }
          }

          .validate {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            list-style: none;
            gap: 16px;
            padding: 0 24px;
            position: absolute;
            width: 100%;
            bottom: 4px;

            td {
              text-align: left;
              padding: 0 4px;
            }
          }
        }
      }
    }
  }

  .margin-left-auto {
    margin-left: auto;
  }
}
.actions {
  justify-content: right;
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
  .trash{
    stroke: var(--states-red-soft);
  }
  .unactive-icon {
    fill: none;
    stroke: var(--neutral-300);
    cursor: not-allowed !important;
  }
}
</style>
