/* Inicio da importação das views e bibliotecas do projeto
 *
 * Desenvolvedores:
 * Richard Moraes
 * Carlos Oliveira
 *
 * */

import Vue from 'vue'
import VueRouter from 'vue-router'

// No VUE as rotas chamam diretamente as views e seus códigos HTML
import Api from '../utils/api'
import {userHasPermission} from '@/utils/localStorageManager'

import NoAccess from '../views/NoAccess.vue'
import Login from '../views/Login'
import Register from '../views/Register.vue'
import ProductionSheet from '../views/ProductionSheet.vue'
import SecretaryRegister from '../views/SecretaryRegister.vue'
import ManagerRegister from '../views/ManagerRegister.vue'
import NurseRegister from '../views/NurseRegister.vue'
import TechRegister from '../views/TechRegister.vue'
import DoctorRegister from '../views/DoctorRegister'
import Professional from '../views/Professional.vue'
import Secretary from '../views/Secretary.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import RequestDemo from '../views/RequestDemo.vue'
import ConsentForm from '../views/ConsentForm.vue'
import PublicScheduleView from '../views/Configs/PublicScheduleView'
import Home from '../views/Home.vue'
import OverviewView from '../views/Overview/OverviewView'
import PreConsultation from '../views/PreConsultation'
import PreConsultationForm from '../views/PreConsultation/Form'
import ChatView from '../views/Chat/ChatView'
// import PatientsList from '../views/Patients/PatientsList'
import PatientForm from '../views/Patients/PatientForm'
// import PatientDashboard from '../views/Patients/PatientDashboard'
import NewAttendance from '../views/Patients/NewAttendance'
import NewAnnotation from '../views/Patients/NewAnnotation'
import PrintAttendance from '../views/Patients/PrintAttendance'
import AnalyticsView from '../views/Analytics/AnalyticsView'
import PersonalDataView from '../views/Configs/PersonalDataView'
import ClinicSettingsView from '../views/Configs/ClinicSettingsView'
import HealthPlans from '../views/Configs/HealthPlans'
import Warehouse from '../views/Warehouse/Warehouse'
import PrepareFiles from '../views/PrepareFiles/PrepareFiles'
import Protocols from '../views/Protocols/Protocols'
import CallPanel from '../views/CallPanel'
import CallPanelTelevisor from '../views/CallPanel/Televisor'
import StandardRecordView from '../views/StandardRecords/StandardRecordView.vue'
import StandardRecordGlassesView from '../views/StandardRecords/StandardRecordGlassesView.vue'
import BiometriaRecordView from '../views/StandardRecords/BiometriaRecordView.vue'
import ManagementReports from '../views/ManagementReports'
import MedicalReports from '../views/MedicalReports'
import MedicalReport from '../views/MedicalReports/MedicalReport.vue'
import Academy from '../views/Academy'
import CourseView from '../views/Academy/CourseView'
import RootManager from '../views/RootManager'
import Attendance from '../layouts/Attendance'
import CourseDashboard from '../layouts/Academy/CourseDashboard'
import SignLogs from '../layouts/SignLogs'
import PatientPrescription from '../views/Prescriptions/PatientPrescription.vue'
import DocByCode from '../views/Prescriptions/DocByCode.vue'
import PharmacyPrescription from '../views/Prescriptions/PharmacyPrescription.vue'
import OculosPrescription from '../views/Prescriptions/OculosPrescription.vue'
import SellingPanelHome from "@/views/SellingPanel/SellingPanelHome.vue";
/* Fim da importação das views e bibliotecas do projeto
 *
 * */


/***
 * Classe para roteamento do VUE framework, para os componentes das views
 */
Vue.use(VueRouter)

// Constante router com as lista de rotas.
const routes = [
	{path: '/acesso-negado', component: NoAccess, name: 'noAccess'},
	{path: '/login/:confirmationCode?', component: Login},
	{
		path: '/convite/:invitationCode/secretaria',
		component: SecretaryRegister
	},
	{
		path: '/convite/:invitationCode/GESTOR',
		component: ManagerRegister
	},
	{path: '/convite/:invitationCode/enfermeiro', component: NurseRegister},
	{path: '/convite/:invitationCode/tecnico', component: TechRegister},
	{path: '/convite/:invitationCode/medico', component: DoctorRegister},
	{path: '/convite/:invitationCode/anestesista', component: DoctorRegister},
	{path: '/profissionais/:invitationCode', component: Professional},
	{path: '/secretarias/:invitationCode', component: Secretary},
	{path: '/esqueci-minha-senha', component: ForgotPassword},
	{path: '/esqueci-minha-senha/:token', component: ResetPassword},
	{path: '/solicitar-demonstracao', component: RequestDemo},
	{path: '/cadastre-se', redirect: '/solicitar-demonstracao'},
	{path: '/cadastre-se/:registerKey', component: Register}, // redirect: '/login' },
	{path: '/folha-de-producao', component: ProductionSheet},
	{path: '/termo-de-consentimento', component: ConsentForm},
	{path: '/receita-paciente/:attendanceid', component: PatientPrescription},
	{path: '/doc/:code', component: DocByCode},
	{path: '/receita-farmacia/:attendanceid', component: PharmacyPrescription},
	{path: '/receita-oculos/:attendanceid', component: OculosPrescription},
	{
		path: '/pacientes/:id/consulta/:idConsulta/imprimir',
		component: PrintAttendance,
		meta: {requiresAuth: true}
	},
	{
		path: '/preparando-documentos',
		component: PrepareFiles,
		meta: {requiresAuth: true}
	},
	{
		path: '/admin',
		component: RootManager,
		meta: {requiresAuth: true}
	},
	{
		path: '/admin/academy/course-dashboard/:courseId',
		component: CourseDashboard,
		meta: {requiresAuth: true}
	},
	{
		path: '/chamadas',
		name: 'CallPanelTelevisor',
		component: CallPanelTelevisor,
		props: true,
		meta: {requiresAuth: true},
	},
	{
		path: '/',
		component: Home,
		children: [
			{
				path: '',
				redirect: () => {
					const clinic = JSON.parse(localStorage.getItem('clinic'))
					return clinic
						? ((clinic.role === 'DOCTOR' || clinic.role === 'DOCTOR_MANAGER' || !userHasPermission('FpAgen1')) ? '/visao-geral' : '/agenda')
						: '/login'
				}
			},
			{
				path: 'pre-consulta',
				component: PreConsultation,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPreCon1', to, from, next)
			},
			{
				path: 'pre-consulta/:id',
				component: PreConsultationForm,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPreCon2', to, from, next)
			},
			{
				path: 'exames',
				component: () => import('@/views/Exam/Exams'),
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpExam1', to, from, next)
			},
			{
				path: 'pacientes/:patientId/exame/:attendanceId',
				component: Attendance,
				meta: { requiresAuth: true },
				beforeEnter: (to, from, next) => customBeforeEnter('FpExam2', to, from, next)
			},
			{
				path: 'estoque/:tab?',
				component: Warehouse,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('Estoque', to, from, next)
			},
			{
				path: 'painel-vendas/:tab?',
				component: SellingPanelHome,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPaiVen1', to, from, next)
			},
			// {
			// 	path: 'faturamento-tiss/:tab',
			// 	component: InvoicingTiss,
			// 	meta: {requiresAuth: true},
			// 	// beforeEnter: (to, from, next) => customBeforeEnter('Tiss', to, from, next)
			// },
			{
				path: 'laudos-medicos/:tab',
				component: MedicalReports,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('Laudo', to, from, next)
			},
			{
				path: 'laudo-medico/:patientId/:attendanceId/:medicalReportId',
				component: MedicalReport,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('Laudo', to, from, next)
			},
			{path: 'chat', component: ChatView, meta: {requiresAuth: true}},
			// {
			// 	path: 'pacientes',
			// 	component: PatientsList,
			// 	meta: {requiresAuth: true},
			// 	beforeEnter: (to, from, next) => customBeforeEnter('FpPac1', to, from, next)
			// },
			{
				path: 'pacientes/novo',
				component: PatientForm,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPac2', to, from, next)
			},
			// {
			// 	path: 'pacientes/:id',
			// 	component: PatientForm,
			// 	meta: {requiresAuth: true},
			// 	beforeEnter: (to, from, next) => customBeforeEnter('FpPac3', to, from, next)
			// },
			// {
			// 	path: 'pacientes/:id/dashboard/:appointmentId?',
			// 	component: PatientDashboard,
			// 	meta: {requiresAuth: true},
			// 	beforeEnter: (to, from, next) => customBeforeEnter('FpPron1', to, from, next)
			// },
			{
				path: 'old/:id/consulta/:idConsulta',
				component: NewAttendance,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPron2', to, from, next)
			},
			{
				path: 'pacientes/:id/anotacoes/:professionalId/:action',
				component: NewAnnotation,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpAno1', to, from, next)
			},
			{
				path: 'visao-geral',
				component: OverviewView,
				meta: {requiresAuth: true}
			},
			{
				path: 'analytics',
				component: AnalyticsView,
				meta: {requiresAuth: true}
			},
			{
				path: 'dados-pessoais',
				name: 'personalData',
				props: true,
				component: PersonalDataView,
				meta: {requiresAuth: true}
			},
			{
				path: 'meus-dados-pessoais',
				name: 'myPersonalData',
				props: true,
				component: PersonalDataView,
				meta: {requiresAuth: true}
			},
			{
				path: 'configuracoes-da-clinica/:tab?',
				component: ClinicSettingsView,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('Configurações da clínica', to, from, next)
			},
			{
			  path: '/convenio/:id?/:tab?',
				component: HealthPlans,
				meta: {requiresAuth: true}
			},
			{
				path: 'agendamento-online',
				component: PublicScheduleView,
				// meta: { requiresAuth: true },
				// beforeEnter: (to, from, next) => customBeforeEnter('Configurações agendamento online', to, from, next)
			},
			{
				path: 'configuracoes-da-consulta',
				component: () => import('@/views/Configs/Appointment/CustomAppointmentsConfig'),
				meta: {requiresAuth: true}
			},
			{
				path: 'configuracoes-da-consulta/:id',
				component: () => import('@/views/Configs/Appointment/CustomAppointmentView2'),
				meta: { requiresAuth: true }
			},
			{
				path: 'configuracoes-do-exame/:id',
				component: () => import('@/views/Configs/Appointment/CustomAppointmentView2'),
				meta: { requiresAuth: true }
			},
			{
				path: 'painel-de-chamadas/:tab?',
				component: CallPanel,
				meta: {requiresAuth: true},
			},
			{
				path: 'registros-padrao/:tab?',
				component: () => import('@/views/Configs/Appointment/CustomAppointmentsConfig'),
				meta: { requiresAuth: true }
			},
			{
				path: 'registro-padrao/:id',
				component: StandardRecordView,
				meta: {requiresAuth: true}
			},
			{
				path: 'registro-padrao/oculos/:id',
				component: StandardRecordGlassesView,
				meta: {requiresAuth: true}
			},
			{
				path: 'registro-padrao/biometria/:id',
				component: BiometriaRecordView,
				meta: {requiresAuth: true}
			},
			{
				path: 'relatorios-gerenciais/:tab?',
				component: ManagementReports,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('Relatórios gerenciais', to, from, next)
			},
			{
				path: 'protocolos/:tab?',
				component: Protocols,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpProto1', to, from, next)
			},
			{
				path: 'pacientes/:patientId/consulta/:attendanceId',
				component: Attendance,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPron2', to, from, next)
			},
			{
				path: 'pacientes/:patientId/pre-consulta/:attendanceId',
				component: Attendance,
				meta: {requiresAuth: true},
				beforeEnter: (to, from, next) => customBeforeEnter('FpPreCon1', to, from, next)
			},
			{
				path: 'academy',
				component: Academy,
				meta: {requiresAuth: true},
			},
			{
				path: 'academy/curso/:courseId',
				component: CourseView,
				meta: {requiresAuth: true},
			},
			{
				path: 'glauco/dashboard',
				component: () => import('@/views/Glauco'),
				meta:  {requiresAuth: true },
			},
			{
				path: 'glauco/dashboard/paciente/:patientId',
				component: () => import('@/views/Glauco/PatientDashboard'),
				meta: { requiresAuth: true },
			},

			{
				path: '/log-documentos',
				component: SignLogs,
				meta: {requiresAuth: true}
			},
      {
        path: '/configuracoes-do-centro-cirurgico/:tab?',
        component: () => import('@/views/SurgeryCenter/Configuration'),
        meta: {requiresAuth: true}
      },
      {
        path: '/anotacao-cirurgica/:id?',
        component: () => import('@/views/SurgeryCenter/Configuration/SurgicalNote'),
        meta: {requiresAuth: true}
      },
      {
        path: '/centro-cirurgico/mapa-cirurgico',
        component: () => import('@/views/SurgeryCenter/SurgicalMap'),
        meta: {requiresAuth: true}
      },
			{
        path: '/centro-cirurgico/lista-pacientes',
        component: () => import('@/views/SurgeryCenter/Surgeries'),
        meta: {requiresAuth: true}
      },
			{
        path: '/centro-cirurgico/dashboard/:surgeryId',
        component: () => import('@/views/SurgeryCenter/Dashboard'),
        meta: {requiresAuth: true}
      },
			{
        path: '/centro-cirurgico/dashboard/:surgeryId',
        component: () => import('@/views/SurgeryCenter/Dashboard'),
        meta: {requiresAuth: true}
      },
			{
				path: 'centro-cirurgico/dashboard/produtos/:surgeryId',
				component: () => import('@/views/SurgeryCenter/MatMed/SurgeryItemsManagement'),
				meta: {requiresAuth: true},
			},
			{
				path: 'centro-cirurgico/matmed/:surgeryId',
				component: () => import('@/views/SurgeryCenter/MatMed/MatMedManagement'),
				meta: {requiresAuth: true},
			},
			{
				path: 'pacientes/:patientId/cirurgia/:attendanceId/:surgeryId?',
				component: () => import('../layouts/Attendance'),
				meta: {requiresAuth: true},
			},
			{
				path: 'pacientes/:patientId/evolucao-anestesista/:attendanceId/:surgeryId',
				component: () => import('../layouts/Attendance'),
				meta: {requiresAuth: true},
			},
			{
				path: 'pacientes/:patientId/evolucao-equipe/:attendanceId/:surgeryId',
				component: () => import('../layouts/Attendance'),
				meta: {requiresAuth: true},
			},
			{
				path: 'pacientes/:patientId/anotacoes-equipe/:attendanceId/:surgeryId',
				component: () => import('../layouts/Attendance'),
				meta: {requiresAuth: true},
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

/***
 * Método para checar autenticação
 */
router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth) && to.path !== '/preparando-documentos') {
		if (!Api.isLogged()) {
			next({path: '/login'})
		} else {
			next()
		}
	} else {
		next()
	}
})

export const customBeforeEnter = (key, to, from, next) => {
	userHasPermission(key) ? next() : next({name: 'noAccess'})
}

/***
 * Fim da classe para roteamento do VUE framework, para os componentes das views
 */

export default router
