import { render, staticRenderFns } from "./BudgetTab.vue?vue&type=template&id=6aa15408&scoped=true"
import script from "./BudgetTab.vue?vue&type=script&lang=js"
export * from "./BudgetTab.vue?vue&type=script&lang=js"
import style0 from "./BudgetTab.vue?vue&type=style&index=0&id=6aa15408&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa15408",
  null
  
)

export default component.exports